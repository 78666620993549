import { Box, Stack, css, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Route, useLocation, useMatch } from 'react-router-dom';

import DtmLanesLogo from '@/assets/logos/dtm-lanes.svg?react';
import MonitoringIcon from '@/assets/icons/monitoring.svg?react';
import AnalysisIcon from '@/assets/icons/analysis.svg?react';
import HotspotIcon from '@/assets/icons/hotspot.svg?react';
import DetectorIcon from '@/assets/icons/detector.svg?react';
import { SidebarLink } from '@/components/sidebar/SidebarLink';
import { SentryRoutes } from '@/setup/sentry';
import { ProfileSidebar } from '@/profile/components/ProfileSidebar';
import { FcdAnalysisSidebar } from '@/fcd-analysis/components/FcdAnalysisSidebar';
import { NlpSidebar } from '@/nlp/NlpSidebar';
import { DetectorSidebar } from '@/detector-analysis/components/DetectorSidebar';

const SIDEBAR_ITEMS = [
  { to: '/karte/monitoring', Icon: MonitoringIcon, title: 'Monitoring' },
  { to: '/karte/fcd-verkehrsanalyse', Icon: AnalysisIcon, title: 'FCD Verkehrsanalyse' },
  { to: '/karte/hotspots', Icon: HotspotIcon, title: 'Hotspots' },
  { to: '/karte/detektoranalyse', Icon: DetectorIcon, title: 'Detektoranalyse' },
];

const StyledSidebar = styled(Box)(
  ({ theme }) => css`
    width: ${theme.spacing(6)};
    flex-shrink: 0;
    display: grid;
    grid-template-rows: ${theme.spacing(11)} 1fr ${theme.spacing(11)};

    ${theme.breakpoints.up('md')} {
      width: ${theme.spacing(8)};
    }
  `,
);

const StyledStack = styled(Stack)(
  () => css`
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  `,
);

const SidebarDetail = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
    width: ${theme.spacing(51)};
    max-width: calc(100vw - ${theme.spacing(6)});
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('md')} {
      max-width: calc(100vw - ${theme.spacing(10)});
    }
  `,
);

export function Sidebar() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((prevState) => !prevState);

  const location = useLocation();

  useEffect(() => {
    setOpen(true);
  }, [location.pathname]);

  const matchProfile = useMatch('/profil/*');
  const matchMonitoring = useMatch('/karte/monitoring');
  const matchHotspots = useMatch('/karte/hotspots');

  return (
    <>
      <StyledSidebar>
        <StyledStack spacing={{ xs: 1, md: 2 }}>
          <SidebarLink circle to="/profil" title="Profil" toggleOpen={toggleOpen}>
            <DtmLanesLogo />
          </SidebarLink>
        </StyledStack>

        <StyledStack spacing={{ xs: 1, md: 2 }}>
          {SIDEBAR_ITEMS.map(({ to, title, Icon }) => (
            <SidebarLink key={to} to={to} title={title} toggleOpen={toggleOpen}>
              <Icon />
            </SidebarLink>
          ))}
        </StyledStack>
      </StyledSidebar>

      {(matchProfile || (open && !matchMonitoring && !matchHotspots)) && (
        <SidebarDetail>
          <SentryRoutes>
            <Route path="profil/*" element={<ProfileSidebar />} />

            <Route path="karte/*">
              <Route path="monitoring" element={null} />
              <Route path="fcd-verkehrsanalyse" element={<FcdAnalysisSidebar />} />
              <Route path="detektoranalyse" element={<DetectorSidebar />} />
              <Route path="hotspots" element={null} />
              <Route path="nlp/*" element={<NlpSidebar />} />
            </Route>
          </SentryRoutes>
        </SidebarDetail>
      )}
    </>
  );
}
