import { Layout, PlotData, Shape } from 'plotly.js';

import { FcdParameterType } from '@/types/FcdParameterType';
import { FCD_LEGEND } from '@/map/modules/FcdLegend';
import { FcdLayerType } from '@/types/FcdLayerType';
import { DetectorParameterType } from '@/types/DetectorParameterType';

export class TimeRangePlot {
  public static getLevelOfServiceLayout({
    parameterType,
    data,
  }: {
    parameterType: FcdParameterType | DetectorParameterType;
    data: Partial<PlotData>[] | undefined;
  }): Partial<Layout> | undefined {
    if (parameterType !== FcdParameterType.LOS || !data || data.length === 0) {
      return undefined;
    }

    const yMin = Math.min(
      0.8,
      data
        .map((d) => d.y as number[])
        .flat()
        .reduce((a, b) => Math.min(a, b)),
    );

    const yMax = Math.max(
      2.8,
      data
        ?.map((d) => d.y as number[])
        .flat()
        .reduce((a, b) => Math.max(a, b)),
    );

    const range = FCD_LEGEND[FcdLayerType.LEVEL_OF_SERVICE].map((los, index, array) => ({
      ...los,
      label: los.label || '',
      min: index === 0 ? yMin : array[index - 1].value,
      ...(index === array.length - 1 && { value: yMax }),
    }));

    const shapes: Partial<Shape>[] = range.map((item) => ({
      type: 'line',
      xref: 'paper',
      x0: 0,
      x1: 0,
      y0: item.min,
      y1: item.value,
      line: {
        width: 4,
        color: item.color,
      },
    }));

    return {
      yaxis: {
        tickvals: range.map((item) => item.min),
        ticktext: range.map((item) => item.label),
      },
      shapes,
    };
  }
}
