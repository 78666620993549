import { Button, css, Stack, styled } from '@mui/material';

import { transientOptions } from '@/utils/transient-options';
import { useMonitoringStore } from '@/stores/useMonitoringStore';
import { MonitoringMode } from '@/types/MonitoringMode';

const StyledButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacing(0.5)} ${theme.spacing(2)};
    text-transform: uppercase;
    color: ${theme.palette.text.primary};
  `,
);

const StyledButtonLive = styled(
  StyledButton,
  transientOptions,
)<{ $active?: boolean }>(
  ({ theme, $active }) => css`
    background-color: ${$active ? theme.palette.live.main : theme.palette.common.white};
    border: 1px solid ${theme.palette.secondary.main};

    &:hover {
      background-color: ${$active ? theme.palette.live.main : theme.palette.secondary.main};
    }

    ${$active &&
    css`
      color: ${theme.palette.common.white};
      border-color: ${theme.palette.live.main};
    `}
  `,
);

const StyledButtonHistory = styled(
  StyledButton,
  transientOptions,
)<{ $active?: boolean }>(
  ({ theme, $active }) => css`
    background-color: ${!$active ? theme.palette.common.white : theme.palette.primary.main};
    border: 1px solid ${theme.palette.secondary.main};
    flex-shrink: 0.75;

    &:hover {
      background-color: ${!$active ? theme.palette.secondary.main : theme.palette.primary.main};
    }

    ${$active &&
    css`
      border-color: ${theme.palette.primary.main};
    `}
  `,
);

export function HistoricalControl() {
  const mode = useMonitoringStore((state) => state.mode);
  const { setMode } = useMonitoringStore((state) => state.actions);
  const { showTooltip } = useMonitoringStore((state) => state.actions);

  return (
    <Stack direction="row" spacing={1} mb={2} justifyContent="space-between" useFlexGap>
      <StyledButtonLive
        size="small"
        variant="contained"
        fullWidth
        onClick={() => setMode(MonitoringMode.LIVE)}
        $active={mode === MonitoringMode.LIVE}
      >
        Live
      </StyledButtonLive>
      <StyledButtonHistory
        size="small"
        variant="contained"
        fullWidth
        onClick={() => {
          setMode(MonitoringMode.HISTORICAL);
          showTooltip();
        }}
        $active={mode === MonitoringMode.HISTORICAL}
      >
        Historisch
      </StyledButtonHistory>
    </Stack>
  );
}
