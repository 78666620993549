import { DrawCreateEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import { Polygon } from 'geojson';

import { DrawControl } from '@/detector-analysis/components/DrawControl';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';

export function DetectorDrawControl() {
  const mapboxDraw = useDetectorAnalysisStore((state) => state.mapboxDraw);
  const { setIsDrawing, setDrawnPolygon } = useDetectorAnalysisStore((state) => state.actions);

  return (
    <DrawControl
      ref={mapboxDraw}
      position="top-right"
      displayControlsDefault={false}
      controls={{
        polygon: true,
      }}
      onCreate={(event: DrawCreateEvent) => {
        setDrawnPolygon(event.features[0].geometry as Polygon);
      }}
      onUpdate={(event: DrawUpdateEvent) => {
        setDrawnPolygon(event.features[0].geometry as Polygon);
      }}
      onDelete={() => {
        setDrawnPolygon(undefined);
      }}
      onModeChange={({ mode }) => {
        if (mode === 'draw_polygon') {
          const previousPolygons = mapboxDraw.current?.getAll().features.slice(0, -1) || [];
          if (previousPolygons.length > 0) {
            mapboxDraw.current?.delete(previousPolygons.map(({ id }) => id as string));
          }

          setDrawnPolygon(undefined);
          setIsDrawing(true);
        } else if (
          mode === 'simple_select' &&
          mapboxDraw.current?.getAll().features &&
          mapboxDraw.current.getAll().features.length < 1
        ) {
          setIsDrawing(false);
        }
      }}
    />
  );
}
