class GeoServer {
  private serverUrl: string;

  constructor(serverUrl: string) {
    this.serverUrl = serverUrl;
  }

  private makeUrl(path?: string) {
    return decodeURI(new URL(`${path}`, this.serverUrl).toString());
  }

  public wms({ layers }: { layers: string }) {
    const urlSearchParams = new URLSearchParams({
      service: 'WMS',
      version: '1.3.0',
      request: 'GetMap',
      layers,
      format: 'image/png',
      transparent: 'true',
      width: '256',
      height: '256',
      srs: 'EPSG:3857',
      bbox: '{bbox-epsg-3857}',
    });

    return this.makeUrl(`wms?${urlSearchParams}`);
  }

  public wmts({ layer }: { layer: string }) {
    const urlSearchParams = new URLSearchParams({
      service: 'WMTS',
      version: '1.0.0',
      request: 'GetTile',
      layer,
      format: 'application/vnd.mapbox-vector-tile',
      tilematrixset: 'EPSG:900913',
      tilecol: '{x}',
      tilerow: '{y}',
      tilematrix: 'EPSG:900913:{z}',
    });

    return this.makeUrl(`gwc/service/wmts?${urlSearchParams}`);
  }

  public wfs({ typeName }: { typeName: string }) {
    const urlSearchParams = new URLSearchParams({
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName,
      outputFormat: 'application/json',
    });

    return this.makeUrl(`ows?${urlSearchParams}`);
  }
}

export const GEOSERVER_MUNICH = new GeoServer(import.meta.env.VITE_GEOSERVER_MUNICH_URL);
