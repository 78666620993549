import { Button, Stack, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { SidebarContent } from '@/components/sidebar/SidebarContent';
import { NlpResponse } from '@/nlp/types/NlpResponse';
import { NlpRequestType } from '@/nlp/types/NlpRequestType';
import { useMonitoringStore } from '@/stores/useMonitoringStore';
import { MonitoringMode } from '@/types/MonitoringMode';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { RouteLocationType } from '@/types/RouteLocationType';
import { NLP_API_URL } from '@/setup/nlp-api';

const transformUtcToUserTimeZone = (timeStamp: string) => dayjs(timeStamp.replace('Z', '')).toISOString();
const transformUtcTimeToUserTimeZone = (timeStamp: string) => dayjs(timeStamp, 'HH:mm:ss').tz('UTC').format('HH:mm');

export function NlpSidebar() {
  const navigate = useNavigate();

  const { setRoutes, setNlpFcdRequest } = useFcdAnalysisStore((state) => state.actions);
  const { setMode, setDateTime } = useMonitoringStore((state) => state.actions);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ prompt: string }>();

  const { mutate, error } = useMutation<NlpResponse, any, string>({
    mutationFn: async (request: string) => {
      const result = await fetch(`${NLP_API_URL}/input`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ request }),
      });

      if (result.ok) {
        return (await result.json()) as NlpResponse;
      }

      return Promise.reject((await result.json()).response);
    },

    onSuccess: ({ response }) => {
      if (response.requestType === NlpRequestType.HISTORICAL) {
        const time = dayjs(response.timeParameters[0].timeRanges?.[0].from, 'HH:mm:ss');

        const dateTime = dayjs(response.timeParameters[0].start)
          .startOf('day')
          .set('hours', time.get('hours'))
          .set('minutes', time.get('minutes'));

        setDateTime(dateTime);

        setNlpFcdRequest(undefined);
        setMode(MonitoringMode.HISTORICAL);
        navigate('monitoring-historisch');
      } else if (response.requestType === NlpRequestType.LIVE) {
        setNlpFcdRequest(undefined);
        setMode(MonitoringMode.LIVE);
        navigate('monitoring-live');
      } else if (response.requestType === NlpRequestType.ANALYSIS) {
        setRoutes({
          type: 'FeatureCollection',
          features: response.routesRequest.map(({ routingPoints }, index) => {
            const id = uuidv4();

            return {
              id,
              type: 'Feature',
              geometry: null,
              properties: {
                id,
                name: `Strecke ${index + 1}`,
                routeLocations: {
                  type: 'FeatureCollection',
                  features: routingPoints.map((routingCoordinates, routeLocationIndex, array) => {
                    const routeLocationId = uuidv4();
                    let routeLocationType = RouteLocationType.VIA;

                    if (routeLocationIndex === 0) {
                      routeLocationType = RouteLocationType.ORIGIN;
                    } else if (routeLocationIndex === array.length - 1) {
                      routeLocationType = RouteLocationType.DESTINATION;
                    }

                    return {
                      id: routeLocationId,
                      type: 'Feature',
                      geometry: { type: 'Point', coordinates: [routingCoordinates.lng, routingCoordinates.lat] },
                      properties: {
                        id: routeLocationId,
                        routeLocationType,
                      },
                    };
                  }),
                },
                lineStringProperties: null,
                length: null,
              },
            };
          }),
        });

        setNlpFcdRequest({
          routesLength: response.analysisRequest.routes.length,
          ...response.analysisRequest,
          timeParameters: response.analysisRequest.timeParameters?.map((timeParameter) => ({
            ...timeParameter,
            start: transformUtcToUserTimeZone(timeParameter.start),
            end: transformUtcToUserTimeZone(timeParameter.end),
            timeRanges: timeParameter.timeRanges?.map((timeRange) => ({
              ...timeRange,
              from: timeRange.from && transformUtcTimeToUserTimeZone(timeRange.from),
              to: timeRange.to && transformUtcTimeToUserTimeZone(timeRange.to),
            })),
          })),
        });
        navigate('fcd-verkehrsanalyse');
      } else {
        navigate('/karte/nlp');
      }
    },

    onError: () => {
      navigate('/karte/nlp');
    },
  });

  const handleSubmitMutate = handleSubmit(({ prompt }) => {
    mutate(prompt);
  });

  return (
    <SidebarContent
      actions={
        <Stack justifyContent="flex-end" flexDirection="row">
          <Button form="nlp-form" type="submit" variant="contained" color="primary">
            Start
          </Button>
        </Stack>
      }
    >
      <form id="nlp-form" onSubmit={handleSubmitMutate}>
        <TextField
          multiline
          minRows={3}
          label="Prompt"
          fullWidth
          error={!!errors.prompt || !!error}
          helperText={error && (typeof error === 'string' ? error : 'Ein unbekannter Fehler ist aufgetreten')}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSubmitMutate();
            }
          }}
          {...register('prompt', { required: true })}
        />
      </form>
    </SidebarContent>
  );
}
