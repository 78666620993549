import { ReactNode } from 'react';
import { css, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

type LoadDialogTableProps = { columns: string[]; children: ReactNode };

const StyledTableCellHeader = styled(TableCell)(
  () => css`
    text-align: center;
    font-weight: bold;
  `,
);

export function LoadDialogTable({ columns, children }: LoadDialogTableProps) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 750, marginTop: 2 }} size="small">
        <TableHead>
          <TableRow
            sx={{
              borderBottom: (theme) => `2px solid ${theme.palette.secondary.main}`,
              '.MuiStyledTableCell-root': { fontWeight: 'bold' },
            }}
          >
            {columns.map((col) => (
              <StyledTableCellHeader key={col}>{col}</StyledTableCellHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
