import { Route, useMatch } from 'react-router-dom';
import { useEffect } from 'react';

import { FcdAnalysisMapContent } from '@/fcd-analysis/FcdAnalysisMapContent';
import { SentryRoutes } from '@/setup/sentry';
import { MonitoringMapContent } from '@/monitoring/MonitoringMapContent';
import { useMapContext } from '@/stores/useMapContext';
import { FcdLayerType } from '@/types/FcdLayerType';
import { BottomControls } from '@/monitoring/components/BottomControls';
import { FcdLegend } from '@/map/components/fcd/FcdLegend';

export function NlpMapContent() {
  const isRoot = useMatch('/karte/nlp');
  const { setLayers } = useMapContext((state) => state.actions);

  useEffect(() => {
    if (isRoot) {
      setLayers({ fcd: FcdLayerType.NONE });
    }
  }, [isRoot, setLayers]);

  return (
    <>
      <BottomControls>
        <FcdLegend />
      </BottomControls>

      <SentryRoutes>
        <Route path="monitoring-historisch" element={<MonitoringMapContent />} />
        <Route path="monitoring-live" element={<MonitoringMapContent />} />
        <Route path="fcd-verkehrsanalyse" element={<FcdAnalysisMapContent />} />
      </SentryRoutes>
    </>
  );
}
