import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { de } from '@/i18n/de';

i18n.use(initReactI18next).init({
  resources: {
    de: {
      translation: de,
    },
  },
  lng: 'de',
  fallbackLng: 'de',

  interpolation: {
    escapeValue: false,
  },

  react: {
    unescape: (text) => text.replace('&shy;', '\u00AD').replace('&nbsp;', '\u00A0'),
  },
});
