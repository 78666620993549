import { useContext } from 'react';
import { useStore } from 'zustand';

import { MapContextState } from '@/types/MapContextState';
import { MapContext } from '@/stores/MapContext';

export const useMapContext = <T>(
  selector: (state: MapContextState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => {
  const store = useContext(MapContext);

  if (!store) {
    throw new Error('Missing MapContext.Provider in the tree');
  }

  return useStore(store, selector, equalityFn);
};
