import { Typography } from '@mui/material';

import { TooltipList } from '@/monitoring/components/TooltipList';
import { TooltipListItem } from '@/monitoring/components/TooltipListItem';

export function HistoricalTooltip() {
  return (
    <>
      <Typography>Interaktion mit der Zeitleiste</Typography>
      <TooltipList>
        <TooltipListItem>Mit der Maus den Cursor auf die gewünschte Uhrzeit verschieben </TooltipListItem>
        <TooltipListItem>
          Die Pfeilbuttons links und rechts neben der Zeitleiste springen jeweils 15 Minuten vor oder zurück. Hierfür
          können auch die Pfeiltasten auf der Tastatur verwendet werden.
        </TooltipListItem>
      </TooltipList>
    </>
  );
}
