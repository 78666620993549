import { RouteFeature } from '@/types/RouteFeature';

export class RouteName {
  public static get(route: RouteFeature) {
    return `${RouteName.start(route)} bis ${RouteName.end(route)}`;
  }

  public static start(route: RouteFeature) {
    return route.properties.routeLocations.features[0].properties.name?.full;
  }

  public static end(route: RouteFeature) {
    return route.properties.routeLocations.features[route.properties.routeLocations.features.length - 1].properties.name
      ?.full;
  }
}
