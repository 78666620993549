import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

import EditIcon from '@/assets/icons/analysis/edit.svg?react';

type EditNameProps = { name: string; updateName?: (name: string) => void };

export function EditName({ name, updateName }: EditNameProps) {
  const [editing, setEditing] = useState(false);
  const [updatedName, setUpdatedName] = useState(name);

  const handleUpdateName = (newName: string) => {
    if (updateName) {
      updateName(newName);
    }

    setEditing(false);
  };

  return (
    <Stack direction="row" alignItems="center">
      {editing ? (
        <TextField
          type="text"
          variant="standard"
          margin="none"
          autoFocus
          value={updatedName}
          onChange={(event) => setUpdatedName(event.target.value)}
          onBlur={() => handleUpdateName(updatedName)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleUpdateName(updatedName);
            }
          }}
        />
      ) : (
        <Typography onClick={() => setEditing(true)}>{name}</Typography>
      )}
      {editing ? null : (
        <IconButton onClick={() => setEditing(true)} sx={{ marginLeft: 1, padding: 0.5 }}>
          <EditIcon />
        </IconButton>
      )}
    </Stack>
  );
}
