import { Layer, Source } from 'react-map-gl';

import { FeatureLayerType } from '@/types/FeatureLayerType';
import { PG_FEATURESERV } from '@/setup/pg-featureserv';
import { useMapImages } from '@/map/hooks/useMapImages';
import TrafficLightIcon from '@/map/assets/traffic-light.svg?url';

export function TrafficLightLayer() {
  useMapImages({
    images: [
      {
        name: FeatureLayerType.TRAFFIC_LIGHT,
        url: TrafficLightIcon,
        width: 16,
        height: 32,
      },
    ],
  });

  return (
    <Source
      type="geojson"
      id={FeatureLayerType.TRAFFIC_LIGHT}
      data={`${PG_FEATURESERV}/collections/frontend.traffic_light/items.json`}
      promoteId="lsa_number"
    >
      <Layer
        id={FeatureLayerType.TRAFFIC_LIGHT}
        type="symbol"
        layout={{
          'icon-image': FeatureLayerType.TRAFFIC_LIGHT,
          'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0, 10, 0.125, 15, 1],
          'icon-allow-overlap': true,
        }}
      />
    </Source>
  );
}
