import { Link, useMatch } from 'react-router-dom';
import { alpha, css, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { transientOptions } from '@/utils/transient-options';

const StyledIconButton = styled(
  IconButton,
  transientOptions,
)<{ $selected?: boolean; $circle?: boolean }>(
  ({ theme, $selected, $circle }) => css`
    color: ${theme.palette.common.white};
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};

    ${theme.breakpoints.up('md')} {
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
    }

    ${$circle &&
    css`
      border-radius: 50%;
    `}

    ${$selected
      ? css`
          &,
          &:hover {
            background-color: #7c8586;
          }
        `
      : css`
          &:hover {
            background-color: ${alpha('#7c8586', 0.25)};
          }
        `}
  `,
) as typeof IconButton;

type SidebarLinkProps = { to: string; title: string; toggleOpen: () => void; children: ReactNode; circle?: boolean };

export function SidebarLink({ to, title, toggleOpen, children, circle }: SidebarLinkProps) {
  const matched = useMatch(to);

  return (
    <Tooltip
      key={to}
      arrow
      title={
        <Typography textTransform="uppercase" sx={{ fontFamily: (theme) => `${theme.typography.fontFamily}` }}>
          {title}
        </Typography>
      }
      placement="right"
    >
      <StyledIconButton
        // @ts-ignore
        $selected={!!matched}
        $circle={circle}
        onClick={() => {
          if (matched) {
            toggleOpen();
          }
        }}
        component={Link}
        to={to}
      >
        {children}
      </StyledIconButton>
    </Tooltip>
  );
}
