import { Layer, Source } from 'react-map-gl';
import { Feature, MultiLineString } from 'geojson';

import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { AnalysisRoute } from '@/fcd-analysis/components/AnalysisRoute';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { ROUTING_NET_LINE_OFFSET, ROUTING_NET_SOURCE_LAYER } from '@/map/components/fcd/FcdLayers';
import { isNonNullable } from '@/utils/isNonNullable';
import { RouteFeatureProperties } from '@/types/RouteFeature';
import { LineHighlightLayers } from '@/map/components/utils/LineHighlightLayers';
import { FCD_LAYER } from '@/types/FcdLayer';
import { useMapStore } from '@/stores/useMapStore';

export const ANALYSIS_ROUTES_LAYER = 'ANALYSIS_ROUTES_LAYER';
export const ANALYSIS_ROUTES_LINE_WIDTH = 8;

export function AnalysisRoutesLayer() {
  const fcdHighlightId = useMapStore((state) => state.fcdHighlightId);
  const routes = useFcdAnalysisStore((state) => state.routes);

  return (
    <>
      {routes.features.map((route) => (
        <AnalysisRoute key={route.id} route={route} />
      ))}

      <LineHighlightLayers
        source={FCD_LAYER}
        sourceLayer={ROUTING_NET_SOURCE_LAYER}
        beforeId={ANALYSIS_ROUTES_LAYER}
        highlightId={fcdHighlightId}
        lineWidthBorder={ANALYSIS_ROUTES_LINE_WIDTH + 8}
        lineWidthWhite={ANALYSIS_ROUTES_LINE_WIDTH + 6}
      />

      <Source
        id={ANALYSIS_ROUTES_LAYER}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: routes.features.filter(
            (feature): feature is Feature<MultiLineString, RouteFeatureProperties> & { id: string } =>
              isNonNullable(feature.geometry),
          ),
        }}
        promoteId="id"
      >
        <Layer
          id={ANALYSIS_ROUTES_LAYER}
          type="line"
          paint={{
            'line-width': ANALYSIS_ROUTES_LINE_WIDTH,
            'line-color': [
              'match',
              ['get', 'id'],
              ...routes.features.map(({ id }, index) => [id, ROUTE_COLOR[index]]).flat(),
              '#000000',
            ],
            'line-offset': ROUTING_NET_LINE_OFFSET,
          }}
          layout={{ 'line-cap': 'round', 'line-join': 'round' }}
        />
      </Source>
    </>
  );
}
