import { ForwardedRef, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import { Datum, PlotData } from 'plotly.js';

import { PlotRef } from '@/types/PlotRef';

type ExportDataParams = {
  ref: ForwardedRef<PlotRef>;
  data: Partial<PlotData>[] | undefined;
};

export const useExportData = ({ ref, data }: ExportDataParams) => {
  useImperativeHandle(ref, () => ({
    exportData() {
      if (!data) return [];

      const xValues = (data[0].x as Datum[]) || [];

      return xValues.map((x, i) => {
        const dataObj: Record<string, Datum | Datum[] | undefined> = {
          Tag: Math.ceil((x as number) / 1440) + ((x as number) % 1440 === 0 ? 1 : 0),
          Uhrzeit: dayjs()
            .startOf('day')
            .add(x as number, 'minutes')
            .format('HH:mm'),
        };

        data.forEach((y) => {
          if (y.name) {
            dataObj[y.name] = y.y?.[i];
          }
        });

        return dataObj;
      });
    },
  }));
};
