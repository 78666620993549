import { memo, useEffect, useState } from 'react';
import { NavigationControl } from 'react-map-gl';
import { Outlet } from 'react-router-dom';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { LayersControl } from '@/monitoring/components/LayersControl';
import { BuildingExtrusionLayer } from '@/map/components/background/BuildingExtrusionLayer';
import { SideBySideCursor } from '@/map/components/background/SideBySideCursor';
import { FcdLayers } from '@/map/components/fcd/FcdLayers';
import { ObstructionPopup } from '@/map/components/feature-layers/ObstructionPopup';
import { TrafficLightPopup } from '@/map/components/feature-layers/TrafficLightPopup';
import { RouteLocationPopup } from '@/map/components/route/RouteLocationPopup';
import { BackgroundLayer } from '@/map/components/background/BackgroundLayer';
import { RouteLengthPopup } from '@/map/components/route/RouteLengthPopup';
import { FcdDetailCard } from '@/map/components/fcd/FcdDetailCard';
import { StrategyDetailCard } from '@/map/components/feature-layers/StrategyDetailCard';
import { DetectorPopup } from '@/map/components/detectors/DetectorPopup';
import { DetectorClusterPopup } from '@/map/components/detectors/DetectorClusterPopup';
import { DetectorDetailCard } from '@/map/components/detectors/DetectorDeteailCard';

export const MapChildren = memo(() => {
  const [layersInitialized, setLayersInitialized] = useState(false);

  useEffect(() => {
    setLayersInitialized(true);
  }, []);

  return (
    <>
      <BackgroundLayer />

      <NavigationControl showZoom={false} />
      <LayersControl />

      {layersInitialized && (
        <>
          <BuildingExtrusionLayer />
          <SideBySideCursor />

          <FcdLayers />

          <ObstructionPopup />
          <TrafficLightPopup />
          <FcdDetailCard />
          <DetectorDetailCard />
          <StrategyDetailCard />

          <RouteLocationPopup />
          <RouteLengthPopup />

          <DetectorPopup />
          <DetectorClusterPopup />
        </>
      )}

      <Outlet />
    </>
  );
});
