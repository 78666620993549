import { Button, Dialog, DialogContent, Stack, TablePagination } from '@mui/material';
import { ReactNode } from 'react';

import { DialogTitleWithClose } from '@/components/dialog/DialogTitleWithClose';
import LoadIcon from '@/assets/icons/analysis/load.svg?react';
import { Database } from '@/types/Supabase';
import { TrashBinIcon } from '@/components/TrashBinIcon';

type LoadDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  page: number;
  dataCount: number | null | undefined;
  rowsPerPage: number;
  children: ReactNode;
  selectedData:
    | Database['public']['Tables']['user_query_route']['Row']
    | Database['public']['Tables']['user_query_detector_group']['Row'];
  data:
    | Database['public']['Tables']['user_query_route']['Row'][]
    | Database['public']['Tables']['user_query_detector_group']['Row'][];
  onDeleteDialog: (deleteDialog: boolean) => void;
  onPage: (value: number) => void;
  onRowsPerPage: (value: number) => void;
  onLoad: () => void;
};

export function LoadDialog({
  open,
  data,
  onClose,
  title,
  page,
  dataCount,
  rowsPerPage,
  children,
  selectedData,
  onDeleteDialog,
  onPage,
  onRowsPerPage,
  onLoad,
}: LoadDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitleWithClose onClose={onClose}>{`${title}auswahl`}</DialogTitleWithClose>
      <DialogContent sx={{ padding: 3 }}>
        {children}
        <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          {typeof data?.length === 'number' && data.length > 0 && (
            <Button
              onClick={() => onDeleteDialog(true)}
              variant="contained"
              color="error"
              size="small"
              startIcon={<TrashBinIcon />}
              disabled={!selectedData}
            >
              Löschen
            </Button>
          )}

          <TablePagination
            component="div"
            page={page}
            count={dataCount || 0}
            rowsPerPage={rowsPerPage}
            onPageChange={(event, newPage) => {
              onPage(newPage);
            }}
            onRowsPerPageChange={(event) => {
              onRowsPerPage(parseInt(event.target.value, 10));
              onPage(0);
            }}
          />
        </Stack>

        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button color="secondary" variant="outlined" onClick={onClose}>
            Abbrechen
          </Button>
          <Button
            startIcon={<LoadIcon />}
            variant="contained"
            onClick={() => {
              onLoad();
              onClose();
            }}
          >
            Laden
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
