import { Layer, Source, useMap } from 'react-map-gl';
import { Feature, FeatureCollection, LineString } from 'geojson';
import { useEffect, useMemo } from 'react';
import { LngLatBounds } from 'mapbox-gl';

import { useHotspotsStore } from '@/stores/useHotspotsStore';
import { ROUTING_NET_LINE_OFFSET } from '@/map/components/fcd/FcdLayers';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { LineHighlightLayers } from '@/map/components/utils/LineHighlightLayers';
import { HOTSPOTS_LEGEND } from '@/hotspots/components/HotspotsLegend';

export const HOTSPOTS_LAYER = 'HOTSPOTS';
const HOTSPOTS_LINE_WIDTH = 6;

export function HotspotsLayer() {
  const hotspotHighlightId = useHotspotsStore((state) => state.hotspotHighlightId);
  const hotspotFeature = useHotspotsStore((state) => state.hotspotFeature);
  const visibleHotspotsFeatureCollection = useHotspotsStore((state) => state.visibleHotspotsFeatureCollection);

  const overlappingFeatureCollection = useMemo<FeatureCollection<LineString, { count: number }>>(() => {
    const overlapCount = (visibleHotspotsFeatureCollection?.features || [])
      .map(({ geometry }) => (geometry.type === 'LineString' ? [geometry.coordinates] : geometry.coordinates))

      .flat()
      .map((coordinates) => JSON.stringify(coordinates))
      .reduce<Record<string, number>>((counts, coordinates) => {
        counts[coordinates] = (counts[coordinates] || 0) + 1;

        return counts;
      }, {});

    const features = Object.entries(overlapCount).reduce<Feature<LineString, { count: number }>[]>(
      (featuresAcc, [coordinatesString, count]) => {
        if (count > 1) {
          featuresAcc.push({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: JSON.parse(coordinatesString),
            },
            properties: {
              count,
            },
          });
        }

        return featuresAcc;
      },
      [],
    );

    return {
      type: 'FeatureCollection',
      features: features.sort(({ properties: { count: a } }, { properties: { count: b } }) => a - b),
    };
  }, [visibleHotspotsFeatureCollection?.features]);

  const map = useMap();

  useEffect(() => {
    if (!hotspotFeature || !map.current) {
      return;
    }

    const bounds = new LngLatBounds();

    const coordinates =
      hotspotFeature?.geometry.type === 'MultiLineString'
        ? hotspotFeature.geometry.coordinates.flat()
        : hotspotFeature.geometry.coordinates;

    coordinates.forEach((coordinate) => {
      bounds.extend(coordinate as [number, number]);
    });

    setTimeout(() => {
      map.current?.fitBounds(bounds, { padding: { top: 48, right: 144, bottom: 48, left: 48 } });
    }, 0);
  }, [hotspotFeature, map]);

  return (
    <>
      <Source type="geojson" id={HOTSPOTS_LAYER} data={visibleHotspotsFeatureCollection} promoteId="id">
        <Layer
          type="line"
          id={HOTSPOTS_LAYER}
          paint={{
            'line-width': HOTSPOTS_LINE_WIDTH,
            'line-color': HOTSPOTS_LEGEND[0].color,
            'line-offset': ROUTING_NET_LINE_OFFSET,
          }}
          layout={{ 'line-cap': 'round', 'line-join': 'round' }}
        />

        <LineHighlightLayers
          source={HOTSPOTS_LAYER}
          highlightId={hotspotHighlightId || hotspotFeature?.properties.id}
          lineWidthBorder={HOTSPOTS_LINE_WIDTH + 6}
          lineWidthWhite={HOTSPOTS_LINE_WIDTH + 4}
        />
      </Source>

      <Source type="geojson" id={`${HOTSPOTS_LAYER}_OVERLAP`} data={overlappingFeatureCollection}>
        <Layer
          type="line"
          id={`${HOTSPOTS_LAYER}_OVERLAP`}
          paint={{
            'line-width': HOTSPOTS_LINE_WIDTH,
            'line-color': [
              'case',
              ...HOTSPOTS_LEGEND.slice(0, HOTSPOTS_LEGEND.length - 1)
                .map(({ value, color }) => [['==', ['get', 'count'], value], color])
                .flat(),
              HOTSPOTS_LEGEND[HOTSPOTS_LEGEND.length - 1].color,
            ],
            'line-offset': ROUTING_NET_LINE_OFFSET,
          }}
          layout={{ 'line-cap': 'round', 'line-join': 'round' }}
        />
      </Source>

      {hotspotFeature && (
        <Layer
          source={HOTSPOTS_LAYER}
          type="line"
          id={`${HOTSPOTS_LAYER}_HIGHLIGHT`}
          filter={['==', ['get', 'id'], hotspotFeature.properties.id]}
          paint={{
            'line-width': HOTSPOTS_LINE_WIDTH,
            'line-color': ROUTE_COLOR[0],
            'line-offset': ROUTING_NET_LINE_OFFSET,
          }}
          layout={{ 'line-cap': 'round', 'line-join': 'round' }}
        />
      )}
    </>
  );
}
