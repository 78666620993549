import { BackgroundLayerType } from '@/types/BackgroundLayerType';

const urlSearchParams = new URLSearchParams({
  service: 'WMS',
  version: '1.3.0',
  request: 'GetMap',
  layers: 'g_stadtkarte_gesamt',
  format: 'image/png',
  transparent: 'true',
  width: '512',
  height: '512',
  format_options: 'dpi:270',
  srs: 'EPSG:3857',
  bbox: '{bbox-epsg-3857}',
});

const cityMapSourceData = decodeURI(`${new URL(`ows?${urlSearchParams}`, import.meta.env.VITE_GEOSERVER_MUNICH_URL)}`);

export class MapBackground {
  public static id = {
    [BackgroundLayerType.CITY_MAP]: {
      name: 'Empty',
      styleData: {
        version: 8 as const,
        name: 'Empty',
        metadata: {
          'mapbox:autocomposite': true,
        },
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        sources: {
          'munich-raster-tiles': {
            type: 'raster' as const,
            tiles: [cityMapSourceData],
            tileSize: 256,
            attribution:
              '<a href="https://stadt.muenchen.de/infos/geobasisdaten.html" target="_blank" title="GeodatenService München" aria-label="GeodatenService München" role="listitem">© GeodatenService München</a> <a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap" aria-label="OpenStreetMap" role="listitem">© OpenStreetMap</a>',
          },
        },
        layers: [
          {
            id: 'munich-background-map',
            type: 'raster' as const,
            source: 'munich-raster-tiles',
          },
        ],
      },
      sourceData: cityMapSourceData,
    },
    [BackgroundLayerType.SATELLITE_MAP]: {
      name: 'Mapbox Satellite',
      styleData: 'mapbox://styles/trafficon/clcergvvb000p14o7rroffaoz',
    },
    [BackgroundLayerType.LIGHT_MAP]: {
      name: 'Mapbox Light',
      styleData: 'mapbox://styles/trafficon/clcenodc2000l15myjjunol8r',
    },
    [BackgroundLayerType.DARK_MAP]: {
      name: 'Mapbox Dark',
      styleData: 'mapbox://styles/trafficon/clcerlyu300b615pjzpctzgz6',
    },
  };
}
