import { MenuItem, TextField } from '@mui/material';
import { GridApiPro, GridFilterItem, GridFilterOperator, ValueOptions } from '@mui/x-data-grid-pro';
import { MutableRefObject } from 'react';

import { HotspotFeature } from '@/types/HotspotFeature';
import { DayCategory } from '@/types/DayCategory';
import { isNonNullable } from '@/utils/isNonNullable';

type TimeInputComponentProps = {
  item: GridFilterItem;
  applyValue: (item: { value: string[] }) => void;
  valueOptions: ValueOptions[];
  apiRef: MutableRefObject<GridApiPro>;
};

function MultiSelectInputComponent({ item, applyValue, valueOptions, apiRef }: TimeInputComponentProps) {
  return (
    <TextField
      select
      variant="standard"
      label={apiRef.current.getColumn(item.field).headerName}
      value={item.value || []}
      SelectProps={{
        multiple: true,
      }}
      onChange={(event) => {
        applyValue({ ...item, value: event.target.value as unknown as string[] });
      }}
      InputLabelProps={{
        shrink: true,
      }}
    >
      {valueOptions.map((option) => {
        const value = typeof option === 'object' && 'value' in option ? option.value : option;
        const label = typeof option === 'object' && 'label' in option ? option.label : option;

        return (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export const multiSelectFilterOperators = ({
  valueOptions,
}: {
  valueOptions: ValueOptions[];
}): GridFilterOperator<HotspotFeature>[] => [
  {
    label: 'enthält manche',
    value: 'includes_some',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || !filterItem.value.every(isNonNullable)) {
        return null;
      }

      return ({ value }) => filterItem.value.some((item: DayCategory) => value.includes(item));
    },
    InputComponent: MultiSelectInputComponent,
    InputComponentProps: {
      valueOptions,
    },
  },
  {
    label: 'enthält alle',
    value: 'includes_every',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || !filterItem.value.every(isNonNullable)) {
        return null;
      }

      return ({ value }) => filterItem.value.every((item: DayCategory) => value.includes(item));
    },
    InputComponent: MultiSelectInputComponent,
    InputComponentProps: {
      valueOptions,
    },
  },

  {
    label: 'ist gleich',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value) || !filterItem.value.every(isNonNullable)) {
        return null;
      }

      return ({ value }) => JSON.stringify(value.sort()) === JSON.stringify(filterItem.value.sort());
    },
    InputComponent: MultiSelectInputComponent,
    InputComponentProps: {
      valueOptions,
    },
  },
];
