import { Box, css, Link, Paper, Stack, styled, Typography, useTheme } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';

import DTMLogo from '@/assets/logos/dtm.svg?react';
import MunichLogo from '@/assets/logos/munich.svg?react';
import TempusLogo from '@/assets/logos/tempus.svg?react';
import TrafficonLogo from '@/assets/logos/trafficon.svg?react';
import { useSession } from '@/authentication/hooks/useSession';

const Root = styled(Box)(
  () => css`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
);

const Card = styled(Paper)(
  ({ theme }) => css`
    padding: ${theme.spacing(6)} ${theme.spacing(4)};
    min-width: min(94vw, ${theme.spacing(55)});
    border-radius: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(6)};
  `,
);

const Title = styled(Typography)(
  () => css`
    text-transform: uppercase;
  `,
);

export function AuthenticationLayout() {
  const theme = useTheme();

  const { data: session } = useSession();

  if (session) {
    return <Navigate to="/karte/monitoring" replace />;
  }

  return (
    <Root>
      <Card>
        <Stack direction="row" spacing={2} justifyContent="center" mb={5}>
          <DTMLogo width={64} color={theme.palette.text.primary} />
          <Title variant="h1">
            Dynamic
            <br />
            Traffic
            <br />
            Monitor
          </Title>
        </Stack>

        <Outlet />

        <Box sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link href="#">Ein Problem melden</Link>
        </Box>
      </Card>
      <Stack direction="row" justifyContent="center" spacing={4} sx={{ color: '#cecece' }}>
        <MunichLogo height={28} />
        <TempusLogo height={28} style={{ marginTop: -4 }} />
        <TrafficonLogo height={28} style={{ marginTop: 2 }} />
      </Stack>
    </Root>
  );
}
