/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeParameter } from './TimeParameter';
export type FcdAnalysisRequest = {
  analysisType: FcdAnalysisRequest.analysisType;
  valueTypes: Array<'v' | 'los' | 'timeloss' | 'traveltimehere' | 'traveltimeself' | 'confidence' | 'vfree'>;
  mode: FcdAnalysisRequest.mode;
  interval: FcdAnalysisRequest.interval;
  aggregate?: boolean;
  timeParameters?: Array<TimeParameter>;
  routes: Array<Array<string>>;
};
export namespace FcdAnalysisRequest {
  export enum analysisType {
    FCD_ANALYSIS = 'fcdAnalysis',
    FCD_TIME = 'fcdTime',
  }
  export enum mode {
    AVG = 'avg',
    SUM = 'sum',
    MEDIAN = 'median',
  }
  export enum interval {
    MIN_3 = 'min_3',
    MIN_15 = 'min_15',
    HOUR_1 = 'hour_1',
    DAY_1 = 'day_1',
    WEEK_1 = 'week_1',
    MONTH_1 = 'month_1',
    YEAR_1 = 'year_1',
    UNKNOWN = 'unknown',
  }
}
