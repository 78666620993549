import { Table, TableBody, TableContainer, Box, Button } from '@mui/material';
import { useState } from 'react';

import { DetailCardTableRow, DetailCardTableRowProps } from '@/map/components/detail-card/DetailCardTableRow';

type DetailCardTableProps = {
  data: DetailCardTableRowProps[];
  additionalData?: DetailCardTableRowProps[];
};

export function DetailCardTable({ data, additionalData }: DetailCardTableProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableContainer sx={{ marginTop: 2, marginBottom: 2 }}>
        <Table size="small">
          <TableBody>
            {data.map(({ label, value, tooltipLabel, tooltipValue }, index) => (
              <DetailCardTableRow
                // eslint-disable-next-line react/no-array-index-key
                key={`${label}-${index}`}
                label={label}
                value={value}
                tooltipLabel={tooltipLabel}
                tooltipValue={tooltipValue}
              />
            ))}

            {additionalData &&
              open &&
              additionalData.map(({ label, value, tooltipLabel, tooltipValue }, index) => (
                <DetailCardTableRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${label}-${index}`}
                  label={label}
                  value={value}
                  tooltipLabel={tooltipLabel}
                  tooltipValue={tooltipValue}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {additionalData && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button size="small" color="neutral" onClick={() => setOpen(!open)}>
            {open ? 'Details ausblenden' : 'Details anzeigen'}
          </Button>
        </Box>
      )}
    </>
  );
}
