import { Grid, Typography, css, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { StyledSmallTypography } from '@/components/StyledSmallTypography';
import { supabase } from '@/setup/supabaseClient';
import { QueryKey } from '@/modules/QueryKey';

const StyledTypography = styled(Typography)(
  () => css`
    color: #7c8586;
  `,
);

type DialogGridComponentProps = {
  data?: { label: string; value: string | undefined }[];
};

export function DialogGridComponent({ data }: DialogGridComponentProps) {
  const { data: userEmail, error: userError } = useQuery({
    queryKey: QueryKey.userData.all,
    queryFn: async () => {
      const user = await supabase.auth.getUser();

      const email = user?.data?.user?.email;

      if (userError) {
        return null;
      }

      return email;
    },
  });

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={6}>
        <Grid container direction="column" spacing={2}>
          {data?.map((item) => (
            <Grid item key={item.label}>
              <StyledSmallTypography>{item.label}</StyledSmallTypography>
              <StyledTypography>{item.value}</StyledTypography>
            </Grid>
          ))}

          <Grid item>
            <StyledSmallTypography>Erstellt von</StyledSmallTypography>
            <StyledTypography>{userEmail}</StyledTypography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
