import { MenuItem, MenuList } from '@mui/material';
import { MapGeoJSONFeature, Popup } from 'react-map-gl';
import { t } from 'i18next';

import { FeatureGuard } from '@/map/modules/FeatureGuard';
import { useMapContext } from '@/stores/useMapContext';
import { useHotspotsStore } from '@/stores/useHotspotsStore';

type FeatureSelectMenuPopupProps = {
  onSelect: (feature: MapGeoJSONFeature) => void;
};

export function FeatureSelectMenuPopup({ onSelect }: FeatureSelectMenuPopupProps) {
  const featureSelectMenu = useMapContext((state) => state.featureSelectMenu);
  const { setFeatureSelectMenu } = useMapContext((state) => state.actions);
  const { setHotspotHighlightId } = useHotspotsStore((state) => state.actions);

  const { features, point } = featureSelectMenu || {};

  if (!features || !point) {
    return null;
  }

  const onClose = () => {
    setFeatureSelectMenu(undefined);
  };

  const [longitude, latitude] =
    features[0].geometry.type === 'Point' ? features[0].geometry.coordinates : point.coordinates;

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeOnClick
      closeOnMove
      closeButton={false}
      onClose={onClose}
      maxWidth="50vw"
      offset={18}
    >
      <MenuList>
        {features.map((feature, index) => {
          let displayName;

          if (FeatureGuard.isObstructionFeature(feature)) {
            displayName = t([`ObstructionType.${feature.type}`, 'ObstructionType.misc'], { type: feature.type });
          } else if (FeatureGuard.isStrategyFeature(feature)) {
            displayName =
              feature.properties?.name?.replace(`${feature.properties.type}: `, '') ||
              feature.properties?.type ||
              'Strategie';
          } else if (feature.properties && 'hotspot_name' in feature.properties) {
            displayName = feature.properties.hotspot_name;
          } else if (FeatureGuard.isDetectorFeature(feature)) {
            displayName = `${feature.properties.type}: ${feature.properties.id}`;
          }

          return (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${feature.id}${index}`}
              onClick={() => {
                onSelect(feature);
                onClose();
              }}
              onMouseMove={() => {
                if (feature.properties && 'hotspot_name' in feature.properties) {
                  setHotspotHighlightId(feature.properties.id);
                }
              }}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {displayName || 'Unbekanntes Feature'}
            </MenuItem>
          );
        })}
      </MenuList>
    </Popup>
  );
}
