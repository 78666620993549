import { Alert, AlertTitle, Box, css, IconButton, Stack, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

import { FcdAnalysisRequest } from '@/modules/api';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import ChevronUpIcon from '@/assets/icons/analysis/chevron-up.svg?react';
import { MultiTimeRangePlot } from '@/components/plot/MultiTimeRangePlot';
import { SingleTimeRangePlot } from '@/components/plot/SingleTimeRangePlot';
import { DurationType } from '@/types/DurationType';
import { WayTimePlot } from '@/fcd-analysis/components/WayTimePlot';
import { DiagramType } from '@/types/DiagramType';
import { useMapFitBounds } from '@/fcd-analysis/hooks/useMapFitBounds';
import { ExportButton } from '@/components/plot/ExportButton';
import { DurationToggleButtonGroup } from '@/fcd-analysis/components/results/DurationToggleButtonGroup';
import { ContextPlot } from '@/components/plot/ContextPlot';
import { AnalysisType } from '@/types/AnalysisType';
import { PlotRef } from '@/types/PlotRef';
import { AnalysisPlotComposition } from '@/types/AnalysisPlotComposition';

const Root = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    border-left: 1px solid ${theme.palette.divider};
    max-height: ${theme.spacing(70)};
    overflow: auto;
    background-color: ${theme.palette.common.white};
  `,
);

type AnalysisResultsProps = {
  plotRef: RefObject<PlotRef>;
  hasMultipleData?: boolean | undefined;
  hasMultipleTimeParameters: boolean | undefined;
  showDiagram: boolean;
  onShowDiagram: () => void;
  setDiagramVisibleDateRange: (diagramVisibleDateRange: DateRange<Dayjs> | undefined) => void;
  resetDiagramVisibleDateRange: () => void;
  diagramVisibleDateRange: DateRange<Dayjs> | undefined;
  plotComposition: AnalysisPlotComposition;
};

export function AnalysisResults({
  plotRef,
  plotComposition,
  hasMultipleData,
  hasMultipleTimeParameters,
  onShowDiagram,
  showDiagram,
  setDiagramVisibleDateRange,
  resetDiagramVisibleDateRange,
  diagramVisibleDateRange,
}: AnalysisResultsProps) {
  const { analysisType, diagramParameters, queryResult } = plotComposition;

  const [durationType, setDurationType] = useState<DurationType>(DurationType.SEC);
  const diagramType = useFcdAnalysisStore((state) => state.diagramType);
  const { setDiagramType } = useFcdAnalysisStore((state) => state.actions);

  const mapFitBounds = useMapFitBounds();

  const { setNlpFcdRequest } = useFcdAnalysisStore((state) => state.actions);

  const { data, isLoading, isError } = queryResult;

  useEffect(() => {
    if (analysisType === AnalysisType.FCD && (hasMultipleData || hasMultipleTimeParameters)) {
      setDiagramType(DiagramType.GRAPH);
    }
  }, [hasMultipleData, hasMultipleTimeParameters, setDiagramType, analysisType]);

  useEffect(() => {
    if (analysisType === AnalysisType.FCD && data) {
      mapFitBounds.fitRoutes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!showDiagram) {
    return null;
  }

  return (
    <Root>
      <Stack direction="row" spacing={2} alignItems="center" mb={2} sx={{ width: '100%' }}>
        {!hasMultipleTimeParameters && !hasMultipleData && analysisType === AnalysisType.FCD && (
          <ToggleButtonGroup
            color="primary"
            size="small"
            onChange={(event, value) => {
              if (value) {
                setDiagramType(value);
              }
            }}
            value={diagramType}
            exclusive
          >
            <ToggleButton value={DiagramType.GRAPH}>Ganglinie</ToggleButton>
            <ToggleButton value={DiagramType.WAYTIME}>Weg-Zeit</ToggleButton>
          </ToggleButtonGroup>
        )}

        {data &&
          data.request?.analysisType === FcdAnalysisRequest.analysisType.FCD_TIME &&
          diagramType === DiagramType.GRAPH && (
            <DurationToggleButtonGroup value={durationType} onChange={(value) => setDurationType(value)} />
          )}

        {diagramType !== DiagramType.WAYTIME && (
          <ExportButton disabled={isLoading} plotRef={plotRef} composition={plotComposition} />
        )}

        <IconButton
          onClick={() => {
            onShowDiagram();
            if (analysisType === AnalysisType.FCD) {
              setNlpFcdRequest(undefined);
            }
          }}
          aria-label="Minimieren"
          sx={{
            marginLeft: 'auto !important',
            border: (theme) => `1px solid ${theme.palette.secondary.main}`,
            width: 24,
            height: 24,
            padding: 0,
          }}
        >
          <ChevronUpIcon style={{ transform: 'rotate(180deg)' }} />
        </IconButton>
      </Stack>

      {diagramParameters.showContexts && diagramParameters.contexts.features.length > 0 && (
        <Box sx={{ height: 128, display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
          <ContextPlot
            diagramParameters={diagramParameters}
            dateRanges={diagramParameters.dateRanges}
            timeRange={diagramParameters.timeRange}
            diagramVisibleDateRange={diagramVisibleDateRange}
          />
        </Box>
      )}

      <Box
        sx={{
          height: diagramParameters.showContexts ? 312 : 448,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!isError ? (
          {
            [DiagramType.GRAPH]: hasMultipleTimeParameters ? (
              <MultiTimeRangePlot ref={plotRef} composition={plotComposition} durationType={durationType} />
            ) : (
              <SingleTimeRangePlot
                ref={plotRef}
                composition={plotComposition}
                durationType={durationType}
                setDiagramVisibleDateRange={setDiagramVisibleDateRange}
                resetDiagramVisibleDateRange={resetDiagramVisibleDateRange}
              />
            ),
            [DiagramType.WAYTIME]:
              analysisType === AnalysisType.FCD ? <WayTimePlot ref={plotRef} composition={plotComposition} /> : null,
          }[diagramType]
        ) : (
          <Alert severity="error" sx={{ maxWidth: 480 }}>
            <AlertTitle sx={{ fontWeight: 'bold' }}>Fehler</AlertTitle>
            Bei der Analyse ist ein Fehler aufgetreten. Bitte überprüfen Sie die Eingaben oder wenden Sie sich an den
            Support.
          </Alert>
        )}
      </Box>
    </Root>
  );
}
