import { Box, css, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Authenticated } from '@/authentication/components/Authenticated';
import { Sidebar } from '@/components/sidebar/Sidebar';
import { Loading } from '@/components/plot/Loading';

const Root = styled(Box)(
  () => css`
    min-height: 100vh;
    display: flex;
    flex-direction: row;
  `,
);

const Main = styled(Box)(
  () => css`
    flex: 1 1 0;
    min-width: 0;
    position: relative;
    display: flex;
  `,
);

export function PageLayout() {
  return (
    <Authenticated>
      <Root>
        <Sidebar />
        <Main component="main">
          <Loading />
          <Outlet />
        </Main>
      </Root>
    </Authenticated>
  );
}
