import { Box, css, styled } from '@mui/material';

import { useMapContext } from '@/stores/useMapContext';
import { useMapStore } from '@/stores/useMapStore';

const Cursor = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
    border-radius: 50%;
    border: 2px solid red;
    will-change: transform;
  `,
);

export function SideBySideCursor() {
  const side = useMapContext((state) => state.side);

  const sideBySideMaps = useMapStore((state) => state.sideBySideMaps);
  const activeSide = useMapStore((state) => state.activeSide);
  const cursorCoordinates = useMapStore((state) => state.cursorCoordinates);

  return sideBySideMaps && activeSide !== side && cursorCoordinates ? (
    <Cursor
      sx={{
        transform: `translate(${cursorCoordinates.x - 8}px, ${cursorCoordinates.y - 8}px)`,
      }}
    />
  ) : null;
}
