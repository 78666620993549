import { Navigate, Route } from 'react-router-dom';

import { SentryRoutes } from '@/setup/sentry';
import { MapLayout } from '@/map/MapLayout';
import { PageLayout } from '@/components/PageLayout';
import { LoginPage } from '@/authentication/LoginPage';
import { AuthenticationLayout } from '@/authentication/components/AuthenticationLayout';
import { ForgotPasswordPage } from '@/authentication/ForgotPasswordPage';
import { ChangePasswordPage } from '@/authentication/ChangePasswordPage';
import { FcdAnalysisMapContent } from '@/fcd-analysis/FcdAnalysisMapContent';
import { MonitoringMapContent } from '@/monitoring/MonitoringMapContent';
import { NlpMapContent } from '@/nlp/NlpMapContent';
import { HotspotsMapContent } from '@/hotspots/HotspotsMapContent';
import { DetectorAnalysisMapContent } from '@/detector-analysis/DetectorAnalysisMapContent';

export function App() {
  return (
    <SentryRoutes>
      <Route path="/*" element={<PageLayout />}>
        <Route path="karte/*" element={<MapLayout />}>
          <Route path="monitoring" element={<MonitoringMapContent controls />} />
          <Route path="fcd-verkehrsanalyse" element={<FcdAnalysisMapContent />} />
          <Route path="hotspots" element={<HotspotsMapContent />} />
          <Route path="detektoranalyse" element={<DetectorAnalysisMapContent />} />
          <Route path="nlp/*" element={<NlpMapContent />} />
        </Route>

        <Route path="profil">
          <Route index element={null} />
          <Route path="neuigkeiten" element={null} />
          <Route path="streckenverwaltung" element={null} />
          <Route path="konfigurationen" element={null} />
          <Route path="helpdesk" element={null} />
          <Route path="passwort" element={<ChangePasswordPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>

      <Route path="/*" element={<AuthenticationLayout />}>
        <Route index element={<LoginPage />} />
        <Route path="passwort-vergessen" element={<ForgotPasswordPage />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </SentryRoutes>
  );
}
