import { keepPreviousData, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';

import { GenericHistoryRequest, TempusService } from '@/modules/api';
import { QueryKey } from '@/modules/QueryKey';
import { FCD_LAYER } from '@/types/FcdLayer';
import { useMonitoringStore } from '@/stores/useMonitoringStore';
import { MonitoringMode } from '@/types/MonitoringMode';
import { FcdLayerType } from '@/types/FcdLayerType';
import { useMapContext } from '@/stores/useMapContext';
import { ROUTING_NET_SOURCE_LAYER } from '@/map/components/fcd/FcdLayers';

const INTERVAL_HOURS = 6;

export const useFcdHistory = () => {
  const map = useMap();

  const dateTime = useMonitoringStore((state) => state.dateTime);
  const mode = useMonitoringStore((state) => state.mode);
  const styleLoaded = useMapContext((state) => state.styleLoaded);
  const layers = useMapContext((state) => state.layers);

  const requestBody = useMemo<GenericHistoryRequest | undefined>(() => {
    if (dateTime && dayjs(dateTime).isBefore(dayjs().startOf('day'))) {
      const day = dateTime?.startOf('day');
      const interval = Math.floor(dateTime.get('hour') / INTERVAL_HOURS);

      return {
        timeParameters: [
          {
            start: day
              .add(interval * INTERVAL_HOURS, 'hour')
              .toISOString()
              .replace(/\.\d+/, ''),
            end: day
              .add(interval * INTERVAL_HOURS + INTERVAL_HOURS, 'hour')
              .toISOString()
              .replace(/\.\d+/, ''),
          },
        ],
      };
    }

    return undefined;
  }, [dateTime]);

  const enabled =
    mode === MonitoringMode.HISTORICAL &&
    ![FcdLayerType.NONE, FcdLayerType.ROUTING_NET].includes(layers.fcd) &&
    !!requestBody;

  const { data } = useQuery({
    queryKey: QueryKey.fcdHistory.getFcdHistory(requestBody!),
    queryFn: () =>
      TempusService.getFcdHistory({
        requestBody: requestBody!,
      }),
    refetchOnWindowFocus: false,
    enabled,
    gcTime: 6000000,
    staleTime: 6000000,
    placeholderData: keepPreviousData,
  });

  const timeSlotString = useMemo(() => dateTime.tz('Europe/Berlin').toISOString().replace(/\.\d+/, ''), [dateTime]);

  useEffect(() => {
    if (styleLoaded && enabled) {
      if (
        data?.results &&
        data.request &&
        timeSlotString &&
        dayjs(timeSlotString).isBetween(
          dayjs(data.request.timeParameters[0].start),
          dayjs(data.request.timeParameters[0].end),
          null,
          '[]',
        )
      ) {
        Object.entries(data.results).forEach(([gid, value]) => {
          const segment = value[timeSlotString];

          if (segment !== undefined) {
            if (map.current?.getSource(FCD_LAYER))
              map.current?.setFeatureState(
                { id: gid, source: FCD_LAYER, sourceLayer: ROUTING_NET_SOURCE_LAYER },
                {
                  timestamp: timeSlotString,
                  ...(segment.v && { v: segment.v }),
                  ...(segment.vfree && { vfree: segment.vfree }),
                  ...(segment.confidence && { confidence: segment.confidence }),
                  ...(segment.traveltimehere && { traveltimehere: segment.traveltimehere }),
                  ...(segment.traveltimeself && { traveltimeself: segment.traveltimeself }),
                  ...(segment.timeloss && { timeloss: segment.timeloss }),
                  ...(segment.los && { los: segment.los }),
                  ...(segment.speeding && { speeding: segment.speeding }),
                  ...(segment.slowdown && { slowdown: segment.slowdown }),
                },
              );
          }
        });
      } else {
        map.current?.removeFeatureState({ source: FCD_LAYER, sourceLayer: ROUTING_NET_SOURCE_LAYER });
      }
    }
  }, [map, data, timeSlotString, enabled, styleLoaded]);
};
