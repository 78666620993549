export class NumberFormat {
  private static formatterLengthKm = new Intl.NumberFormat('de-DE', {
    style: 'unit',
    unit: 'kilometer',
    maximumFractionDigits: 1,
  });

  public static lengthKm(value?: number | null, fallback = '') {
    return typeof value === 'number' && !Number.isNaN(value) ? NumberFormat.formatterLengthKm.format(value) : fallback;
  }

  private static formatterDurationS = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 1,
  });

  public static durationS(value?: number | null, fallback = '') {
    return typeof value === 'number' && !Number.isNaN(value)
      ? `${NumberFormat.formatterDurationS.format(value)} s`
      : fallback;
  }

  private static formatterOneMaximumFractionDigits = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 1,
  });

  public static oneMaximumFractionDigits(value?: number | null, fallback = '') {
    return typeof value === 'number' && !Number.isNaN(value)
      ? NumberFormat.formatterOneMaximumFractionDigits.format(value)
      : fallback;
  }

  private static formatterTwoMaximumFractionDigits = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
  });

  public static twoMaximumFractionDigits(value?: number | null, fallback = '') {
    return typeof value === 'number' && !Number.isNaN(value)
      ? NumberFormat.formatterTwoMaximumFractionDigits.format(value)
      : fallback;
  }
}
