type TrashBinIconProps = { color?: string };

export function TrashBinIcon({ color }: TrashBinIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18" fill="none">
      <path
        d="M1.6875 5.17383H11.3125V15.6086C11.3125 16.3738 10.6938 16.9999 9.9375 16.9999H3.0625C2.30625 16.9999 1.6875 16.3738 1.6875 15.6086V5.17383Z"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6875 3.08691H11.3125C11.6906 3.08691 12 3.39996 12 3.78257V5.17387H1V3.78257C1 3.39996 1.30937 3.08691 1.6875 3.08691Z"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.4375 1H8.5625C8.94063 1 9.25 1.31304 9.25 1.69565V3.08696H3.75V1.69565C3.75 1.31304 4.05937 1 4.4375 1Z"
        stroke={color || 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.4375 7.26074V14.2173" stroke={color || 'currentColor'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.5 7.26074V14.2173" stroke={color || 'currentColor'} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5625 7.26074V14.2173" stroke={color || 'currentColor'} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
