import { Layer } from 'react-map-gl';

import { BackgroundLayerType } from '@/types/BackgroundLayerType';
import { useMapContext } from '@/stores/useMapContext';
import { MapBefore } from '@/map/types/MapBefore';

export function BackgroundLayer() {
  const mapBackgroundLayer = useMapContext((state) => state.backgroundLayer);

  return (
    <>
      <Layer
        id={MapBefore.BACKGROUND}
        type="background"
        beforeId={
          {
            [BackgroundLayerType.CITY_MAP]: undefined,
            [BackgroundLayerType.DARK_MAP]: 'road-label',
            [BackgroundLayerType.LIGHT_MAP]: 'road-label',
            [BackgroundLayerType.SATELLITE_MAP]: 'tunnel-minor-case',
          }[mapBackgroundLayer]
        }
        layout={{ visibility: 'none' }}
      />
      <Layer
        id={MapBefore.CONTENT}
        type="background"
        beforeId={mapBackgroundLayer !== BackgroundLayerType.CITY_MAP ? 'road-label' : undefined}
        layout={{ visibility: 'none' }}
      />
    </>
  );
}
