import { RouteForms } from '@/fcd-analysis/components/RouteForms';
import { DateTimeForm } from '@/components/form/DateTimeForm';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { LocationsTooltip } from '@/components/tooltip/LocationsTooltip';
import { DateTooltip } from '@/components/tooltip/DateTooltip';
import { ParameterTooltip } from '@/components/tooltip/ParameterTooltip';
import { SidebarChip } from '@/components/sidebar/SidebarChip';
import { useValidation } from '@/hooks/useValidation';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { ContextForm } from '@/components/form/ContextForm';
import { ContextTooltip } from '@/components/tooltip/ContextTooltip';
import { CollapsibleSidebar } from '@/components/sidebar/CollapsibleSidebar';
import { AnalysisType } from '@/types/AnalysisType';
import { ParameterForm } from '@/components/form/ParameterForm';
import { FcdActionButtons } from '@/fcd-analysis/components/FcdActionButtons';
import { DetectorParameterType } from '@/types/DetectorParameterType';
import { FcdParameterType } from '@/types/FcdParameterType';

export function FcdAnalysisSidebar() {
  const step = useFcdAnalysisStore((state) => state.step);
  const {
    setFormStep,
    setInterval,
    setDateRange,
    removeDateRange,
    setTimeRange,
    setDayCategories,
    setParameterType,
    setContexts,
    setShowContexts,
  } = useFcdAnalysisStore((state) => state.actions);
  const dateRanges = useFcdAnalysisStore((state) => state.dateRanges);
  const interval = useFcdAnalysisStore((state) => state.interval);
  const dayCategories = useFcdAnalysisStore((state) => state.dayCategories);
  const timeRange = useFcdAnalysisStore((state) => state.timeRange);
  const parameterType = useFcdAnalysisStore((state) => state.parameterType);
  const validateForm = useFcdAnalysisStore((state) => state.validateForm);
  const routes = useFcdAnalysisStore((state) => state.routes);
  const showContexts = useFcdAnalysisStore((state) => state.showContexts);
  const contexts = useFcdAnalysisStore((state) => state.contexts);

  const validation = useValidation({
    dateRanges,
    parameterType,
    showContexts,
    validateForm,
    routes,
    contexts,
  });

  const items = [
    {
      title: 'Strecke',
      Component: <RouteForms />,
      Tooltip: <LocationsTooltip analysisType={AnalysisType.FCD} />,
      chip: (
        <SidebarChip
          label={validation[AnalysisFormStep.LOCATION].count}
          color={validation[AnalysisFormStep.LOCATION].status}
        />
      ),
    },
    {
      title: 'Zeit',
      Component: (
        <DateTimeForm
          analysisType={AnalysisType.FCD}
          dateRanges={dateRanges}
          interval={interval}
          dayCategories={dayCategories}
          timeRange={timeRange}
          onSetDateRange={setDateRange}
          onRemoveDateRange={removeDateRange}
          onSetDayCategories={setDayCategories}
          onSetInterval={setInterval}
          onSetTimeRange={setTimeRange}
        />
      ),
      Tooltip: <DateTooltip />,
      chip: (
        <SidebarChip
          label={validation[AnalysisFormStep.DATE_TIME].count}
          color={validation[AnalysisFormStep.DATE_TIME].status}
        />
      ),
    },
    {
      title: 'Kennwert',
      Component: (
        <ParameterForm
          parameterType={AnalysisType.FCD}
          i18nKey="FcdParameterType"
          selectedParameter={parameterType}
          onSetParameterType={setParameterType as (paramaterType: DetectorParameterType | FcdParameterType) => void}
        />
      ),
      Tooltip: <ParameterTooltip />,
      chip: (
        <SidebarChip
          label={validation[AnalysisFormStep.PARAMETER].count}
          color={validation[AnalysisFormStep.PARAMETER].status}
        />
      ),
    },
    {
      title: 'Kontext',
      Component: (
        <ContextForm
          dateRanges={dateRanges}
          showContexts={showContexts}
          selectedContexts={contexts}
          onSetShowContexts={setShowContexts}
          onSetContexts={setContexts}
        />
      ),
      Tooltip: <ContextTooltip />,
      chip: validation[AnalysisFormStep.CONTEXTS].status !== 'error' && (
        <SidebarChip
          label={validation[AnalysisFormStep.CONTEXTS].count}
          color={validation[AnalysisFormStep.CONTEXTS].status}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleSidebar step={step} setFormStep={setFormStep} items={items} />
      <FcdActionButtons validation={validation} />
    </>
  );
}
