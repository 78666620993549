import { FcdLayerType } from '@/types/FcdLayerType';

export const FCD_LEGEND: Readonly<
  Record<
    Exclude<FcdLayerType, FcdLayerType.NONE>,
    Readonly<{ value?: number; label?: string; color: string; text?: string }[]>
  >
> = {
  [FcdLayerType.LEVEL_OF_SERVICE]: [
    { value: 1.11, label: 'A', color: '#00a827', text: 'free_flow_speed / speed_avg ≤ 1,1' },
    { value: 1.41, label: 'B', color: '#6cce67', text: 'free_flow_speed / speed_avg ≤ 1,4' },
    { value: 2.01, label: 'C', color: '#f1ee27', text: 'free_flow_speed / speed_avg ≤ 2' },
    { value: 2.51, label: 'D', color: '#fcb119', text: 'free_flow_speed / speed_avg ≤ 2,5' },
    { label: 'E', color: '#cf2e26', text: 'free_flow_speed / speed_avg > 2,5' },
  ],
  [FcdLayerType.SPEEDING]: [
    { value: 1.0, color: 'rgba(200,200,200,0.7)' },
    { value: 1.2, label: '≤ 20%', color: '#f0ee57', text: 'speed_avg / max_speed ≤ 1,2' },
    { value: 1.4, label: '≤ 40%', color: '#f0c139', text: 'speed_avg / max_speed ≤ 1,4' },
    { value: 1.6, label: '≤ 60%', color: '#e8942d', text: 'speed_avg / max_speed ≤ 1,6' },
    { value: 1.8, label: '≤ 80%', color: '#d7692d', text: 'speed_avg / max_speed ≤ 1,8' },
    { label: '> 80%', color: '#bf3d31', text: 'speed_avg / max_speed > 1,8' },
  ],
  [FcdLayerType.SPEEDING_LOS]: [
    {
      value: 1.0,
      color: 'rgba(200,200,200,0.7)',
    },
    { value: 0.8, label: '≤ 20%', color: '#f0ee57', text: 'free_flow_speed / speed_avg ≤ 1' },
    { value: 0.6, label: '≤ 40%', color: '#f0c139', text: 'free_flow_speed / speed_avg ≤ 0,8' },
    { value: 0.4, label: '≤ 60%', color: '#e8942d', text: 'free_flow_speed / speed_avg ≤ 0,6' },
    { value: 0.2, label: '≤ 80%', color: '#d7692d', text: 'free_flow_speed / speed_avg ≤ 0,4' },
    { value: 0.0, label: '> 80%', color: '#bf3d31', text: 'free_flow_speed / speed_avg ≤ 0,2' },
  ],
  [FcdLayerType.SLOW_DOWN]: [
    {
      value: 1.0,
      color: 'rgba(200,200,200,0.7)',
    },
    { value: 1.1, label: '≤ 10%', color: '#00a827', text: 'max_speed / free_flow_speed ≤ 1,1' },
    { value: 1.4, label: '≤ 40%', color: '#6cce67', text: 'max_speed / free_flow_speed ≤ 1,4' },
    { value: 2, label: '≤ 100%', color: '#f1ee27', text: 'max_speed / free_flow_speed ≤ 2' },
    { value: 2.5, label: '≤ 150%', color: '#fcb119', text: 'max_speed / free_flow_speed ≤ 2,5' },
    { label: '> 150%', color: '#cf2e26', text: 'max_speed / free_flow_speed > 2,5' },
  ],

  [FcdLayerType.CONFIDENCE_VALUE]: [
    {
      value: 0.98,
      label: '≥ 0,98',
      color: '#0c2e62',
      text: 'Basierend auf Daten aus Echtzeitbeobachtungen(≥ 6 eindeutige Fahrzeuge)',
    },
    {
      value: 0.95,
      label: '≥ 0,95',
      color: '#5992c2',
      text: 'Basierend auf Daten aus Echtzeitbeobachtungen (5 eindeutige Fahrzeuge)',
    },
    {
      value: 0.9,
      label: '≥ 0,90',
      color: '#d3e5f1',
      text: 'Basierend auf Daten aus Echtzeitbeobachtungen (4 eindeutige Fahrzeuge)',
    },
    {
      value: 0.8,
      label: '≥ 0,80',
      color: '#f5bea4',
      text: 'Basierend auf Daten aus Echtzeitbeobachtungen (3 eindeutige Fahrzeuge)',
    },
    {
      value: 0.75,
      label: '≥ 0,75',
      color: '#ef9875',
      text: 'Basierend auf Daten aus Echtzeitbeobachtungen (2 eindeutige Fahrzeuge)',
    },
    {
      value: 0.7,
      label: '≥ 0,70',
      color: '#e34938',
      text: 'Basierend auf historische Geschwindigkeiten der letzten 30 Minuten (falls verfügbar)',
    },
    { value: 0.5, label: '≥ 0,50', color: '#93211a', text: 'Basierend auf historische Geschwindigkeiten' },
    { label: '< 0,50', color: '#a3a3a3', text: 'Basierend auf berechneten Daten der Geschwindigkeitsbegrenzung' },
  ],
  [FcdLayerType.ROUTING_NET]: [{ color: '#a8a8a8', label: 'Routing möglich' }],
};
