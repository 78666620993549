import { useMapContext } from '@/stores/useMapContext';
import { MunichConstructionsLayer } from '@/map/components/feature-layers/MunichConstructionsLayer';
import { TrafficLightLayer } from '@/map/components/feature-layers/TrafficLightLayer';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { StrategyLayer } from '@/map/components/feature-layers/StrategyLayer';
import { ObstructionLayer } from '@/map/components/feature-layers/ObstructionLayer';

type FeatureLayersProps = {
  types: FeatureLayerType[];
};

export function FeatureLayers({ types }: FeatureLayersProps) {
  const mapLayers = useMapContext((state) => state.layers);

  return (
    <>
      {types.map(
        (layer) =>
          mapLayers.features[layer] &&
          {
            [FeatureLayerType.STRATEGY]: <StrategyLayer key={layer} />,
            [FeatureLayerType.OBSTRUCTION]: <ObstructionLayer key={layer} />,
            [FeatureLayerType.MUNICH_CONSTRUCTIONS]: <MunichConstructionsLayer key={layer} />,
            [FeatureLayerType.TRAFFIC_LIGHT]: <TrafficLightLayer key={layer} />,
          }[layer],
      )}
    </>
  );
}
