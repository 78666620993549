import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

import { useMapContext } from '@/stores/useMapContext';
import { FCD_LAYER } from '@/types/FcdLayer';
import { ROUTING_NET_SOURCE_LAYER } from '@/map/components/fcd/FcdLayers';
import { useFcdLiveQuery } from '@/map/queries/useFcdLiveQuery';

export const useFcdLive = () => {
  const map = useMap();
  const styleLoaded = useMapContext((state) => state.styleLoaded);

  const { enabled, data } = useFcdLiveQuery();

  useEffect(() => {
    if (styleLoaded && enabled) {
      if (data?.results) {
        Object.entries(data.results).forEach(([gid, value]) => {
          if (value) {
            const entry = Object.entries(value);
            const segment = entry[0][1];

            if (segment !== undefined) {
              if (map.current?.getSource(FCD_LAYER))
                map.current?.setFeatureState(
                  { id: gid, source: FCD_LAYER, sourceLayer: ROUTING_NET_SOURCE_LAYER },
                  {
                    timestamp: entry[0][0],
                    ...(segment.v && { v: segment.v }),
                    ...(segment.vfree && { vfree: segment.vfree }),
                    ...(segment.confidence && { confidence: segment.confidence }),
                    ...(segment.traveltimehere && { traveltimehere: segment.traveltimehere }),
                    ...(segment.traveltimeself && { traveltimeself: segment.traveltimeself }),
                    ...(segment.timeloss && { timeloss: segment.timeloss }),
                    ...(segment.los && { los: segment.los }),
                    ...(segment.speeding && { speeding: segment.speeding }),
                    ...(segment.slowdown && { slowdown: segment.slowdown }),
                  },
                );
            }
          }
        });
      } else {
        map.current?.removeFeatureState({ source: FCD_LAYER, sourceLayer: ROUTING_NET_SOURCE_LAYER });
      }
    }
  }, [enabled, data, map, styleLoaded]);
};
