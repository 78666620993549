import { FcdParameterType } from '@/types/FcdParameterType';
import { DurationType } from '@/types/DurationType';

export const FcdParameterTypeFormatOptions = {
  [FcdParameterType.V]: { decimals: 1, unit: 'km/h' },
  [FcdParameterType.VFREE]: { decimals: 1, unit: 'km/h' },
  [FcdParameterType.TIMELOSS]: { decimals: 1, unit: 's' },
  [FcdParameterType.TRAVELTIMESELF]: { decimals: 1, unit: 's' },
  [FcdParameterType.LOS]: { decimals: 1, unit: null },
  [FcdParameterType.CONFIDENCE]: { decimals: 2, unit: null },
};

export const getFcdParameterTypeFormatOptionsForTimeRangePlot = ({
  parameterType,
  durationType,
}: {
  parameterType: FcdParameterType;
  durationType: DurationType;
}) =>
  [FcdParameterType.TIMELOSS, FcdParameterType.TRAVELTIMESELF].includes(parameterType) &&
  durationType === DurationType.MIN
    ? { decimals: 1, unit: 'min', multiplier: 1 / 60 }
    : { ...FcdParameterTypeFormatOptions[parameterType], multiplier: 1 };
