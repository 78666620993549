import { Button, Container, css, FormGroup, Paper, styled, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import { supabase } from '@/setup/supabaseClient';

const StyledFormGroup = styled(FormGroup)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
  `,
);

type UserData = { password: string };

export function ChangePasswordPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserData>();

  const { mutateAsync, error, isSuccess } = useMutation({
    mutationFn: async (userData: UserData) => {
      const { data, error: supabaseError } = await supabase.auth.updateUser(userData);

      if (supabaseError) {
        return Promise.reject(supabaseError);
      }

      return Promise.resolve(data);
    },
  });

  return (
    <Container maxWidth="sm">
      <Paper sx={{ marginTop: 2, padding: 2 }}>
        {isSuccess ? (
          <Typography>Passwort geändert.</Typography>
        ) : (
          <form
            onSubmit={handleSubmit(async (loginData) => {
              await mutateAsync(loginData);

              reset();
            })}
          >
            <StyledFormGroup>
              <TextField
                label="Passwort"
                type="password"
                size="small"
                error={!!error || !!errors.password}
                helperText="Mindestens 8 Zeichen"
                {...register('password', { required: true, minLength: 8 })}
              />
            </StyledFormGroup>

            <Button variant="contained" type="submit" fullWidth sx={{ marginBottom: 3 }}>
              Passwort ändern
            </Button>
          </form>
        )}
      </Paper>
    </Container>
  );
}
