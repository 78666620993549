import { styled, css, List } from '@mui/material';

export const StyledList = styled(List)(
  ({ theme }) => css`
    list-style-type: disc;
    padding-left: ${theme.spacing(2)};

    & .MuiListItem-root {
      display: list-item;
      padding-top: 0;
      padding-bottom: ${theme.spacing(0.5)};
    }
  `,
);
