import { create } from 'zustand';
import dayjs from 'dayjs';

import { MonitoringState } from '@/types/MonitoringState';
import { MonitoringMode } from '@/types/MonitoringMode';

const GET_INITIAL_DATE_TIME = () => {
  const now = dayjs();
  const startOfDay = now.startOf('day');
  const minutesSinceStartOfDay = now.diff(startOfDay, 'minutes');

  return startOfDay.subtract(1, 'day').add(minutesSinceStartOfDay - (minutesSinceStartOfDay % 15), 'minutes');
};

export const useMonitoringStore = create<MonitoringState>()((set) => ({
  mode: MonitoringMode.LIVE,

  tooltip: false,

  monitoringMode: true,

  dateTime: GET_INITIAL_DATE_TIME(),

  actions: {
    setMode: (mode) => set({ mode }),

    showTooltip: () => {
      set({ tooltip: true });

      setTimeout(() => {
        set({ tooltip: false });
      }, 10000);
    },

    updateDate: (date) =>
      set(({ dateTime }) => ({
        dateTime: dateTime.set('year', date.get('year')).set('month', date.get('month')).set('date', date.get('date')),
      })),

    updateTime: (minutes) => set(({ dateTime }) => ({ dateTime: dateTime.startOf('day').add(minutes, 'minutes') })),

    setDateTime: (dateTime) => set({ dateTime }),
  },
}));
