import { Box, Button } from '@mui/material';

import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { PlusIcon } from '@/components/PlusIcon';
import { RouteForm } from '@/fcd-analysis/components/RouteForm';
import { FormsWrapper } from '@/components/form/FormsWrapper';

export function RouteForms() {
  const { addRoute } = useFcdAnalysisStore((state) => state.actions);

  const routes = useFcdAnalysisStore((state) => state.routes);

  return (
    <FormsWrapper>
      <>
        {routes?.features.map((route, index) => <RouteForm key={route.id} index={index} />)}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'align-left',
          }}
        >
          {routes.features.length < 3 && (
            <Button
              startIcon={<PlusIcon />}
              size="small"
              color="neutral"
              sx={{ marginLeft: 3 }}
              onClick={() => {
                addRoute();
              }}
            >
              Strecke hinzufügen
            </Button>
          )}
        </Box>
      </>
    </FormsWrapper>
  );
}
