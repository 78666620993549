import { css, IconButton, Stack, styled } from '@mui/material';

import CrossIcon from '@/assets/icons/analysis/cross.svg?react';
import SaveIcon from '@/assets/icons/analysis/save.svg?react';
import LoadIcon from '@/assets/icons/analysis/load.svg?react';
import { ButtonTooltip } from '@/components/ButtonTooltip';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { AnalysisType } from '@/types/AnalysisType';

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.spacing(1)};
    border: 1px solid ${theme.palette.secondary.main};

    &.Mui-disabled {
      color: #bdbdbd;
    }
  `,
);

type DataActionButtonProps = {
  index: number;
  onSaveDialog: (value: boolean) => void;
  onLoadDialog: (value: boolean) => void;
  onRemove: () => void;
  analysisType: AnalysisType;
};

export function DataActionButtons({
  index,
  onSaveDialog,
  onLoadDialog,
  onRemove,
  analysisType,
}: DataActionButtonProps) {
  const routes = useFcdAnalysisStore((state) => state.routes);
  const route = routes.features[index];
  const detectorGroups = useDetectorAnalysisStore((state) => state.detectorGroups);
  const detectorGroup = detectorGroups.features[index];

  const fcdAnalysis = analysisType === AnalysisType.FCD;

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2} mb={2}>
      <ButtonTooltip title={`${fcdAnalysis ? 'Strecke' : 'Detektorgruppe'} speichern`}>
        <span>
          <StyledIconButton
            onClick={() => onSaveDialog(true)}
            disabled={
              (!route?.geometry && fcdAnalysis) ||
              (!fcdAnalysis && detectorGroup.properties.detectors.features.length <= 0)
            }
          >
            <SaveIcon style={{ width: 20, height: 'auto' }} />
          </StyledIconButton>
        </span>
      </ButtonTooltip>
      <ButtonTooltip title={`${fcdAnalysis ? 'Strecke' : 'Detektorgruppe'} laden`}>
        <StyledIconButton onClick={() => onLoadDialog(true)}>
          <LoadIcon style={{ width: 20, height: 'auto' }} />
        </StyledIconButton>
      </ButtonTooltip>
      <ButtonTooltip title={`${fcdAnalysis ? 'Strecke' : 'Detektorgruppe'} entfernen`}>
        <StyledIconButton
          sx={(theme) => ({
            '&:hover': {
              backgroundColor: theme.palette.error.main,
            },
          })}
          onClick={() => {
            onRemove();
          }}
        >
          <CrossIcon style={{ width: 20, height: 'auto' }} />
        </StyledIconButton>
      </ButtonTooltip>
    </Stack>
  );
}
