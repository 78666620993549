import { memo, useEffect } from 'react';

import { RouteLocationMarkers } from '@/fcd-analysis/components/RouteLocationMarkers';
import { AnalysisRoutesLayer } from '@/fcd-analysis/components/AnalysisRoutesLayer';
import { useMapContext } from '@/stores/useMapContext';
import { FcdLayerType } from '@/types/FcdLayerType';
import { FeatureLayers } from '@/map/components/feature-layers/FeatureLayers';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { BottomControls } from '@/monitoring/components/BottomControls';
import { FcdLegend } from '@/map/components/fcd/FcdLegend';
import { ShowDiagramButton } from '@/components/ShowDiagramButton';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';

export const FcdAnalysisMapContent = memo(() => {
  const { setLayers } = useMapContext((state) => state.actions);

  const showDiagram = useFcdAnalysisStore((state) => state.showDiagram);

  const { setShowDiagram } = useFcdAnalysisStore((state) => state.actions);
  const diagramParameters = useFcdAnalysisStore((state) => state.diagramParameters);

  useEffect(() => {
    setLayers({ fcd: FcdLayerType.ROUTING_NET });
  }, [setLayers]);

  return (
    <>
      <FeatureLayers types={[FeatureLayerType.TRAFFIC_LIGHT]} />

      <BottomControls>
        <FcdLegend />
      </BottomControls>

      <ShowDiagramButton
        visible={showDiagram}
        onShowDiagram={() => setShowDiagram(true)}
        diagramParameters={diagramParameters}
      />

      <RouteLocationMarkers />
      <AnalysisRoutesLayer />
    </>
  );
});
