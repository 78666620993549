import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { styled, TableCell, TableRow, Tooltip } from '@mui/material';

const StyledTableCell = styled(TableCell)(
  () => css`
    padding-top: 0;
    padding-bottom: 0;
  `,
);

export type DetailCardTableRowProps = {
  label?: string;
  value: ReactNode;
  tooltipLabel?: string;
  tooltipValue?: string;
};

export function DetailCardTableRow({ label, value, tooltipLabel, tooltipValue }: DetailCardTableRowProps) {
  const header = (
    <StyledTableCell component="th" scope="row">
      {label}
    </StyledTableCell>
  );

  const data = <StyledTableCell sx={{ fontWeight: 'bold' }}>{value}</StyledTableCell>;

  return (
    <TableRow>
      {tooltipLabel ? <Tooltip title={tooltipLabel}>{header}</Tooltip> : header}

      {tooltipValue ? <Tooltip title={tooltipValue}>{data}</Tooltip> : data}
    </TableRow>
  );
}
