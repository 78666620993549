import { SaveDialog } from '@/components/dialog/SaveDialog';
import { QueryKey } from '@/modules/QueryKey';
import { DetectorGroupFeature } from '@/types/DetectorGroupFeature';

type DetectorSaveDialogProps = {
  detectorGroup?: DetectorGroupFeature;
  onClose: () => void;
};

export function DetectorSaveDialog({ detectorGroup, onClose }: DetectorSaveDialogProps) {
  const detectors = detectorGroup?.properties.detectors.features.map((feature) => feature.properties.id);

  return (
    <SaveDialog
      onClose={onClose}
      title="Detektorgruppe"
      mutationEndpoint="user_query_detector_group"
      initialValues={detectorGroup}
      queryKey={QueryKey.detectors.all}
      additionalData={{ detectors }}
    />
  );
}
