import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { FeatureCollection, LineString } from 'geojson';

import { QueryKey } from '@/modules/QueryKey';
import { TempusService } from '@/modules/api';
import { RouteFeature } from '@/types/RouteFeature';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { RouteLocationType } from '@/types/RouteLocationType';

type AnalysisRouteProps = { route: RouteFeature };

export function AnalysisRoute({ route }: AnalysisRouteProps) {
  const { updateRoute } = useFcdAnalysisStore((state) => state.actions);

  const requestBody =
    route.properties.routeLocations.features.length >= 2 &&
    route.properties.routeLocations.features.some(
      ({ properties: { routeLocationType } }) => routeLocationType === RouteLocationType.ORIGIN,
    ) &&
    route.properties.routeLocations.features.some(
      ({ properties: { routeLocationType } }) => routeLocationType === RouteLocationType.DESTINATION,
    )
      ? {
          routingPoints: route.properties.routeLocations.features.map((routeLocation) => ({
            lng: routeLocation.geometry!.coordinates[0],
            lat: routeLocation.geometry!.coordinates[1],
          })),
        }
      : undefined;

  const { data } = useQuery({
    queryKey: QueryKey.routeGeojson.calculateRouteGeojson(requestBody!),
    queryFn: () =>
      TempusService.calculateRouteGeojson({
        requestBody: requestBody!,
      }),
    refetchOnWindowFocus: false,
    enabled: !!requestBody,
  });

  useEffect(() => {
    if (data) {
      const results = data.results as unknown as FeatureCollection<
        LineString,
        { gid: number; hash_id: string; length: number }
      >;

      route.geometry = {
        type: 'MultiLineString',
        coordinates: results.features.map(({ geometry: { coordinates } }) => coordinates),
      };
      route.properties.lineStringProperties = results.features.map(({ properties }) => ({
        gid: properties.gid,
        hashId: properties.hash_id,
        length: properties.length,
      }));
      route.properties.length =
        route.properties.lineStringProperties.reduce((totalLength, { length }) => totalLength + length, 0) / 1000;

      updateRoute(route);
    }
  }, [data, route, updateRoute]);

  return null;
}
