import { useTranslation } from 'react-i18next';
import { ColorScale, Datum, PlotData } from 'plotly.js';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';

import { Plot } from '@/components/plot/Plot';
import { FcdParameterType } from '@/types/FcdParameterType';
import { FcdParameterTypeFormatOptions } from '@/fcd-analysis/modules/FcdParameterTypeFormatOptions';
import { useMapStore } from '@/stores/useMapStore';
import { FCD_LEGEND } from '@/map/modules/FcdLegend';
import { FcdLayerType } from '@/types/FcdLayerType';
import { PlotRef } from '@/types/PlotRef';
import { RouteName } from '@/fcd-analysis/modules/RouteName';
import { getWayTimePlotData } from '@/fcd-analysis/modules/getWayTimePlotData';
import { useExportToImageConfig } from '@/hooks/useExportToImageConfig';
import { AnalysisType } from '@/types/AnalysisType';
import { FcdAnalysisPlotComposition } from '@/types/AnalysisPlotComposition';
import { PlotTitle } from '@/modules/PlotTitle';

type WayTimePlotProps = {
  composition: FcdAnalysisPlotComposition;
};

const reverseTypes = [FcdParameterType.V, FcdParameterType.VFREE];

const colorScaleConfidence = [
  [0, '#a3a3a3'],
  ...FCD_LEGEND[FcdLayerType.CONFIDENCE_VALUE].map((c) => [c.value || 0.5, c.color]).reverse(),
  [1, '#0c2e62'],
] as ColorScale;

const createWorker = createWorkerFactory(() => import('@/fcd-analysis/modules/getWayTimePlotData'));

export const WayTimePlot = forwardRef<PlotRef, WayTimePlotProps>(({ composition }, ref) => {
  const {
    diagramParameters,
    queryResult: { data: response, isLoading },
  } = composition;

  const { setFcdHighlightId } = useMapStore((state) => state.actions);

  const { t } = useTranslation();
  const config = useExportToImageConfig({ analysisType: AnalysisType.FCD, diagramParameters });

  const { unit } = FcdParameterTypeFormatOptions[diagramParameters.parameterType];

  const title = `${t(`FcdParameterType.${diagramParameters.parameterType}`).toString()}${
    unit ? ` (${unit})` : ''
  }${PlotTitle.formatDateTime(response?.request?.timeParameters)}${
    diagramParameters?.routes && diagramParameters.routes.features.length > 0 ? '<br />' : ''
  }${diagramParameters?.routes.features.map((route) => RouteName.get(route)).join('<br />')}`;

  const route = diagramParameters.routes.features[0];

  const worker = useWorker(createWorker);
  const [dataValues, setDataValues] = useState<ReturnType<typeof getWayTimePlotData>>();

  useEffect(() => {
    (async () => {
      const webWorkerMessage = await worker.getWayTimePlotData({
        fcdResponse: response,
        route,
        parameterType: diagramParameters.parameterType,
      });
      setDataValues(webWorkerMessage);
    })();
  }, [diagramParameters.parameterType, response, route, worker]);

  const data:
    | (Partial<Omit<PlotData, 'customdata'> & { customdata: any[][]; colorbar: any }> & {
        hoverongaps: boolean;
      })[]
    | undefined = dataValues
    ? [
        {
          type: 'heatmap',
          x: [0, ...dataValues.x.map((x) => x.progress)],
          y: dataValues.y,
          z: dataValues.z,
          customdata: dataValues.customdata,
          reversescale: reverseTypes.includes(diagramParameters.parameterType),
          colorscale:
            diagramParameters.parameterType === FcdParameterType.CONFIDENCE
              ? colorScaleConfidence
              : [
                  [0, '#006400'],
                  [0.5, '#ffff00'],
                  [1, '#ff0000'],
                ],
          hovertemplate: `Distanz von Startpunk: %{customdata.progress:.1f} m<br />Zeit: %{y}<br />Segment-ID: %{customdata.id}<br />Länge: %{customdata.length:.1f} m<br />${t(
            `ParameterType.${diagramParameters.parameterType}`,
          ).toString()}: %{customdata.zFormatted}<extra></extra>`,
          xhoverformat: '.1f',
          yhoverformat: '%d.%m.%Y %H:%M',
          hoverongaps: true,
          ...(diagramParameters.parameterType === FcdParameterType.LOS && {
            zmin: 1,
            zmax: FCD_LEGEND[FcdLayerType.LEVEL_OF_SERVICE].at(-2)?.value,
          }),
          ...(diagramParameters.parameterType === FcdParameterType.CONFIDENCE && {
            zmin: 0,
            zmax: 1,
          }),
          ...(diagramParameters.parameterType === FcdParameterType.LOS && {
            colorbar: {
              tickvals: [1, 1.3, 1.7, 2.1, 2.5],
              ticktext: FCD_LEGEND[FcdLayerType.LEVEL_OF_SERVICE].map(({ label }) => label),
            },
          }),
        },
      ]
    : undefined;

  useImperativeHandle(ref, () => ({
    exportData() {
      if (!data) return [];

      const zData = data[0].z as Datum[][];

      return zData.map((y, yIndex) => {
        const date = dayjs(data[0].y?.[yIndex] as string);
        const dataObj: Record<string, Datum | Datum[] | undefined> = {
          Datum: date.format('DD.MM.YYYY'),
          Uhrzeit: date.format('HH:mm'),
        };

        y.forEach((z, xIndex) => {
          const key = data[0].x![xIndex + 1]?.toString();
          if (key) {
            dataObj[key] = z;
          }
        });

        return dataObj;
      });
    },
  }));

  return !isLoading && data ? (
    <Plot
      data={data}
      layout={{
        title: { text: title },
        xaxis: {
          tickvals: data[0].x as Datum[],
          ticktext: (data[0].x as Datum[]).map((x, i, array) => {
            if (i === 0) return '0 m';
            if (i === array.length - 1) return `${Math.round(x as number)} m`;

            return '';
          }),
        },
      }}
      onHover={(event) => {
        setFcdHighlightId((event.points[0].customdata as any).id);
      }}
      onUnhover={() => setFcdHighlightId(undefined)}
      config={config}
    />
  ) : (
    <CircularProgress />
  );
});
