import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
}));

type ButtonTooltipProps = {
  children: React.ReactElement;
  title: string;
};

export function ButtonTooltip({ children, title }: ButtonTooltipProps) {
  return (
    <StyledTooltip arrow title={title} placement="top">
      {children}
    </StyledTooltip>
  );
}
