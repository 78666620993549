import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { CollapseSidebarItem } from '@/components/sidebar/CollapseSidebarItem';
import { SidebarListItemButton } from '@/components/sidebar/SidebarListItemButton';
import { supabase } from '@/setup/supabaseClient';
import { queryClient } from '@/setup/query-client';

const PROFILE_ITEMS = [
  // { title: 'Neuigkeiten', path: '/profil/neuigkeiten' },
  // { title: 'Streckenverwaltung', path: '/profil/streckenverwaltung' },
  // { title: 'Konfigurationen', path: '/profil/konfigurationen' },
  // { title: 'Helpdesk', path: '/profil/helpdesk' },
  { title: 'Einstellungen', path: '/profil/passwort' },
];

export function ProfileSidebar() {
  const navigate = useNavigate();
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);

  const numberOfSidebarItems = PROFILE_ITEMS.length + 1;

  return (
    <Box sx={{ flex: 1 }}>
      {PROFILE_ITEMS.map(({ title, path }, index) => (
        <CollapseSidebarItem
          openMenuIndex={openMenuIndex}
          onMenuOpenIndex={(newIndex) => {
            navigate(path);
            setOpenMenuIndex(newIndex);
          }}
          key={title}
          title={title}
          index={index}
          numberOfSidebarItems={numberOfSidebarItems}
        />
      ))}

      <SidebarListItemButton
        onClick={async () => {
          await supabase.auth.signOut();
          queryClient.clear();
          navigate('/');
        }}
      >
        Logout
      </SidebarListItemButton>
    </Box>
  );
}
