import { DetectorAnalysisRequest } from '@/modules/api';
import { DetectorParameterType } from '@/types/DetectorParameterType';

const IntervalDisplay = {
  [DetectorAnalysisRequest.interval.MIN_3]: '3 min',
  [DetectorAnalysisRequest.interval.MIN_15]: '15 min',
  [DetectorAnalysisRequest.interval.HOUR_1]: '1 Stunde',
  [DetectorAnalysisRequest.interval.DAY_1]: '1 Tag',
  [DetectorAnalysisRequest.interval.WEEK_1]: '1 Woche',
  [DetectorAnalysisRequest.interval.MONTH_1]: '1 Monat',
  [DetectorAnalysisRequest.interval.YEAR_1]: '1 Jahr',
  [DetectorAnalysisRequest.interval.UNKNOWN]: 'unbekannt',
};

export const DetectorParameterTypeFormatOptions = {
  [DetectorParameterType.V]: { decimals: 1, unit: 'km/h' },
  [DetectorParameterType.NUM_VEHICLES]: (interval: DetectorAnalysisRequest.interval) => ({
    decimals: 0,
    unit: `Fahrzeuge/${IntervalDisplay[interval]}`,
  }),
};

export const getDetectorParameterTypeFormatOptionsForTimeRangePlot = ({
  parameterType,
  interval,
}: {
  parameterType: DetectorParameterType;
  interval: DetectorAnalysisRequest.interval;
}) => {
  if (parameterType === DetectorParameterType.NUM_VEHICLES) {
    return { ...DetectorParameterTypeFormatOptions[parameterType](interval), multiplier: 1 };
  }

  return { ...DetectorParameterTypeFormatOptions[parameterType], multiplier: 1 };
};
