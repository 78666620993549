import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Layer, Popup, Source } from 'react-map-gl';

import { PopupContent } from '@/components/PopupContent';
import { MapBefore } from '@/map/types/MapBefore';
import { useMapStore } from '@/stores/useMapStore';

export function ObstructionPopup() {
  const { t } = useTranslation();

  const feature = useMapStore((state) => state.obstructionPopupFeature);
  const { setObstructionPopupFeature } = useMapStore((state) => state.actions);

  if (!feature) {
    return null;
  }

  const onClose = () => setObstructionPopupFeature(undefined);

  const [longitude, latitude] = feature.geometry.coordinates;
  const { type, comment_description: description, valid_from: from, valid_to: to } = feature.properties;

  return (
    <>
      <Popup
        key={type + description + from + to}
        longitude={longitude}
        latitude={latitude}
        onClose={onClose}
        offset={18}
        maxWidth="min(480px, 75vw)"
        closeButton={false}
      >
        <PopupContent onClose={onClose}>
          <Typography fontWeight="bold" color="text.primary" mb={1} sx={{ fontSie: '0.75rem' }}>
            {t([`ObstructionType.${type}`, 'ObstructionType.misc'], { type })}
          </Typography>

          {(to || from) && (
            <Typography mb={1}>
              {from && !to && 'Ab '}
              {from && dayjs(from).format('L')}

              {from && to && ' – '}

              {!from && to && 'Bis '}
              {to && dayjs(to).format('L')}
            </Typography>
          )}

          {description && (
            <Typography color="text.primary">
              {description.replace('{}', '').replace('{"', '').replace('"}', '')}
            </Typography>
          )}
        </PopupContent>
      </Popup>

      {feature.properties.geom && (
        <Source type="geojson" data={JSON.parse(feature.properties.geom)}>
          <Layer
            beforeId={MapBefore.CONTENT}
            type="line"
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }}
            paint={{
              'line-color': '#cf2e26',
              'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8],
              'line-dasharray': [1, 2],
            }}
          />
        </Source>
      )}
    </>
  );
}
