import { DetectorsFeatureCollection } from '@/types/DetectorFeature';
import { PG_FEATURESERV } from '@/setup/pg-featureserv';

export class DetectorsService {
  public static async getDetectors(): Promise<DetectorsFeatureCollection> {
    const response = await fetch(`${PG_FEATURESERV}/collections/frontend.detector/items.json`);

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    return response.json();
  }
}
