import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { OpenAPI } from '@/modules/api';
import { supabase } from '@/setup/supabaseClient';
import { queryClient } from '@/setup/query-client';
import { QueryKey } from '@/modules/QueryKey';

export const useSession = () => {
  useEffect(() => {
    supabase.auth.onAuthStateChange((event, newSession) => {
      if (newSession && newSession.token_type === 'bearer' && newSession.access_token) {
        OpenAPI.TOKEN = newSession.access_token;
      } else {
        OpenAPI.TOKEN = undefined;
      }

      queryClient.setQueryData(QueryKey.authentication.session(), newSession);
    });
  }, []);

  return useQuery({
    queryKey: QueryKey.authentication.session(),
    queryFn: async () => {
      const {
        data: { session: newSession },
      } = await supabase.auth.getSession();

      return newSession;
    },
  });
};
