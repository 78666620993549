import { css, FormControlLabel, Radio, RadioGroup, styled, TableCell, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Point } from 'geojson';

import { supabase } from '@/setup/supabaseClient';
import { QueryKey } from '@/modules/QueryKey';
import { RouteDeleteDialog } from '@/fcd-analysis/components/RouteDeleteDialog';
import { Database } from '@/types/Supabase';
import { NumberFormat } from '@/modules/NumberFormat';
import { LoadDialog } from '@/components/dialog/LoadDialog';
import { LoadDialogTable } from '@/components/dialog/LoadDialogTable';
import { RouteFeature } from '@/types/RouteFeature';
import { RouteLocationType } from '@/types/RouteLocationType';
import { Geocoder } from '@/modules/Geocoder';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';

const StyledTableCell = styled(TableCell)(
  () => css`
    text-align: center;
  `,
);

type RouteLoadDialogProps = {
  route?: RouteFeature;
  onClose: () => void;
};

export function RouteLoadDialog({ route, onClose }: RouteLoadDialogProps) {
  const [selectedRoute, setSelectedRoute] = useState<Database['public']['Tables']['user_query_route']['Row']>();

  const [routeDeleteDialog, setRouteDeleteDialog] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { updateRoute } = useFcdAnalysisStore((state) => state.actions);

  const handlePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const { data: { data: routes, count: routesCount } = {}, refetch } = useQuery({
    queryKey: QueryKey.routes.getUserRoutesPaginated({ page, rowsPerPage }),
    queryFn: async () =>
      supabase
        .from('user_query_route')
        .select('*', { count: 'exact' })
        .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1)
        .throwOnError(),
  });

  useEffect(() => {
    if (!route) {
      setSelectedRoute(undefined);
    }
  }, [route]);

  const handleDeleteDialog = (deleteDialog: boolean) => {
    setRouteDeleteDialog(deleteDialog);
  };

  const handleRouteLoad = async () => {
    if (route && routes) {
      route.properties.routeLocations = {
        type: 'FeatureCollection',
        features: await Promise.all(
          (selectedRoute?.routing_points as Point[]).map(async (point, index, array) => {
            let type: RouteLocationType;

            if (index === 0) {
              type = RouteLocationType.ORIGIN;
            } else if (index === array.length - 1) {
              type = RouteLocationType.DESTINATION;
            } else {
              type = RouteLocationType.VIA;
            }

            const location = await Geocoder.reverse(point);

            return {
              ...location,
              properties: {
                ...location.properties,
                routeLocationType: type,
              },
            };
          }),
        ),
      };

      route.geometry = null;
      route.properties.lineStringProperties = null;
      route.properties.length = null;
      route.properties.name = selectedRoute?.name as string;

      updateRoute(route);
    }
  };

  return (
    <>
      <LoadDialog
        open={!!route}
        data={routes as Database['public']['Tables']['user_query_route']['Row'][]}
        onClose={onClose}
        title="Strecken"
        page={page}
        dataCount={routesCount}
        rowsPerPage={rowsPerPage}
        selectedData={selectedRoute as Database['public']['Tables']['user_query_route']['Row']}
        onDeleteDialog={handleDeleteDialog}
        onPage={handlePage}
        onRowsPerPage={handleRowsPerPage}
        onLoad={handleRouteLoad}
      >
        <LoadDialogTable columns={['Name', 'Länge', 'Erstellt am', 'Bearbeitet am', 'Kategorie']}>
          {routes?.map((routeItem) => (
            <TableRow
              key={routeItem.route_id}
              sx={{ borderBottom: (theme) => `2px solid ${theme.palette.secondary.main}` }}
            >
              <TableCell>
                <RadioGroup
                  name="route-selection"
                  value={selectedRoute?.route_id || null}
                  onChange={(event, value) => {
                    setSelectedRoute(routes.find((routeEl) => routeEl.route_id === value));
                  }}
                >
                  <FormControlLabel value={routeItem.route_id} control={<Radio />} label={routeItem.name} />
                </RadioGroup>
              </TableCell>
              <StyledTableCell>{NumberFormat.lengthKm(routeItem.length)}</StyledTableCell>
              <StyledTableCell>{dayjs(routeItem.created_at).format('DD.MM.YYYY')}</StyledTableCell>
              <StyledTableCell>{dayjs(routeItem.updated_at).format('DD.MM.YYYY')}</StyledTableCell>
              <StyledTableCell>{routeItem.route_category}</StyledTableCell>
            </TableRow>
          ))}
        </LoadDialogTable>
      </LoadDialog>

      <RouteDeleteDialog
        route={routeDeleteDialog ? selectedRoute : undefined}
        onClose={() => setRouteDeleteDialog(false)}
        onDeleteSuccess={() => {
          refetch();
          setSelectedRoute(undefined);
        }}
      />
    </>
  );
}
