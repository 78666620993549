import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RouteName } from '@/fcd-analysis/modules/RouteName';
import { getDetectorGroupLocationDescription } from '@/detector-analysis/modules/getDetectorGroupLocationDescription';
import { PlotTitle } from '@/modules/PlotTitle';
import { AnalysisType } from '@/types/AnalysisType';
import { AnalysisPlotComposition } from '@/types/AnalysisPlotComposition';

type GeneratePlotTitleParams = {
  composition: AnalysisPlotComposition;
  unit: string | null;
};
export const usePlotTitle = ({ composition, unit }: GeneratePlotTitleParams) => {
  const { t } = useTranslation();

  const { analysisType, diagramParameters, queryResult } = composition;

  return useMemo(() => {
    const titleFcdNames =
      analysisType === AnalysisType.FCD &&
      `${diagramParameters.routes.features.length > 0 ? '<br />' : ''}${diagramParameters.routes.features
        .map((route, index, array) => `${array.length > 1 ? `${route.properties.name}: ` : ''}${RouteName.get(route)}`)
        .join('<br />')}`;

    const titleDetectorNames =
      analysisType === AnalysisType.DETECTOR &&
      `${diagramParameters.detectorGroups.features
        .map(
          (detectorGroup) =>
            `<br />${detectorGroup.properties.name}: ${getDetectorGroupLocationDescription(
              detectorGroup.properties.detectors.features,
            )}`,
        )
        .join(' ')}`;

    const mainTitle = `${t(
      `${{ [AnalysisType.FCD]: 'FcdParameterType', [AnalysisType.DETECTOR]: 'DetectorParameterType' }[analysisType]}.${
        diagramParameters.parameterType
      }`,
    ).toString()}${unit ? ` (${unit})` : ''}`;

    return `${mainTitle}${PlotTitle.formatDateTime(queryResult.data?.request?.timeParameters)}${
      titleFcdNames || titleDetectorNames
    }`;
  }, [analysisType, diagramParameters, queryResult.data?.request?.timeParameters, t, unit]);
};
