import { Layer, Source } from 'react-map-gl';

import { FeatureLayerType } from '@/types/FeatureLayerType';
import { PG_FEATURESERV } from '@/setup/pg-featureserv';
import { useMapImages } from '@/map/hooks/useMapImages';
import StrategyIcon from '@/assets/icons/analysis/strategy.svg?url';
import { useMonitoringFeatureServSearchParams } from '@/monitoring/hooks/useMonitoringFeatureServSearchParams';

export function StrategyLayer() {
  const { urlSearchParams } = useMonitoringFeatureServSearchParams();
  const url = `${PG_FEATURESERV}/collections/frontend.strategy/items.json?${urlSearchParams}`;

  useMapImages({
    images: [
      {
        name: FeatureLayerType.STRATEGY,
        url: StrategyIcon,
        width: 36,
        height: 36,
      },
    ],
  });

  return (
    <Source type="geojson" id={FeatureLayerType.STRATEGY} data={url} promoteId="id">
      <Layer
        id={FeatureLayerType.STRATEGY}
        type="symbol"
        layout={{
          'icon-image': FeatureLayerType.STRATEGY,
          'icon-allow-overlap': true,
        }}
      />
    </Source>
  );
}
