import { ContextFeature } from '@/types/ContextFeature';

export class ContextName {
  public static get(context: ContextFeature) {
    return (
      ('name' in context.properties && context.properties?.name) ||
      ('comment_description' in context.properties && context.properties?.comment_description) ||
      context.properties?.id.toString()
    );
  }
}
