import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { CollapseSidebarItem } from '@/components/sidebar/CollapseSidebarItem';

type CollapsibleSidebarProps = {
  step: number;
  setFormStep: (step: number) => void;
  items: {
    title: string;
    Component: ReactNode;
    Tooltip: ReactNode;
    chip: ReactNode;
  }[];
};

export function CollapsibleSidebar({ step, setFormStep, items }: CollapsibleSidebarProps) {
  return (
    <Box sx={{ flex: 1, zIndex: 2 }}>
      {items.map(({ title, Component, Tooltip, chip }, index) => (
        <CollapseSidebarItem
          openMenuIndex={step}
          onMenuOpenIndex={setFormStep}
          key={title}
          title={title}
          index={index}
          toolTip={Tooltip}
          numberOfSidebarItems={items.length}
          actionsHeight={56}
          chip={chip}
        >
          {Component}
        </CollapseSidebarItem>
      ))}
    </Box>
  );
}
