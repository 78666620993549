import { ReactNode } from 'react';
import { Box, css, styled } from '@mui/material';

const Root = styled(Box)(
  () => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
);

type SidebarContentProps = { actions: ReactNode; children: ReactNode };

export function SidebarContent({ actions, children }: SidebarContentProps) {
  return (
    <Root>
      <Box sx={{ padding: 2 }}>{children}</Box>
      <Box sx={{ padding: 2 }}>{actions}</Box>
    </Root>
  );
}
