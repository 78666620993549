import { useEffect } from 'react';
import { Box, css, Stack, styled, ToggleButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

import { FcdAnalysisRequest } from '@/modules/api';
import { TimeRangeSlider } from '@/components/form/TimeRangeSlider';
import { DateRangeInput } from '@/components/form/DateRangeInput';
import { StyledSmallTypography } from '@/components/StyledSmallTypography';
import { SidebarItemContainer } from '@/components/sidebar/SidebarItemContainer';
import { DateRangeLength } from '@/modules/DateRangeLength';
import { DayCategoryToggleButtons } from '@/components/form/DayCategoryToggleButtons';
import { ToggleButtonGroupWithMargin } from '@/components/ToggleButtonGroupWithMargin';
import { DayCategory } from '@/types/DayCategory';
import { AnalysisType } from '@/types/AnalysisType';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid #dfdfdf;
    padding-top: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
  `,
);

type DateTimeFormProps = {
  analysisType: AnalysisType;
  dateRanges: DateRange<Dayjs>[];
  interval: FcdAnalysisRequest.interval;
  dayCategories: DayCategory[];
  timeRange: number[];
  onSetInterval: (detectorInterval: FcdAnalysisRequest.interval) => void;
  onSetDateRange: (dateRange: DateRange<Dayjs>, index: number) => void;
  onRemoveDateRange: (index: number) => void;
  onSetDayCategories: (detectorDayCategories: DayCategory[]) => void;
  onSetTimeRange: (detectorTimeRange: number[]) => void;
};

export function DateTimeForm({
  analysisType,
  dateRanges,
  interval,
  dayCategories,
  timeRange,
  onSetInterval,
  onSetDateRange,
  onRemoveDateRange,
  onSetDayCategories,
  onSetTimeRange,
}: DateTimeFormProps) {
  const { t } = useTranslation();

  const allowedIntervals = Object.values(FcdAnalysisRequest.interval).filter((value) => {
    if (value === FcdAnalysisRequest.interval.WEEK_1 && DateRangeLength.get(dateRanges[0], 'weeks')! < 1) return false;
    if (value === FcdAnalysisRequest.interval.MONTH_1 && DateRangeLength.get(dateRanges[0], 'months')! < 1)
      return false;
    if (value === FcdAnalysisRequest.interval.YEAR_1 && DateRangeLength.get(dateRanges[0], 'years')! < 1) return false;
    if (value === FcdAnalysisRequest.interval.UNKNOWN) return false;

    return true;
  });

  useEffect(() => {
    if (!allowedIntervals.some((value) => value === interval)) {
      onSetInterval(FcdAnalysisRequest.interval.MIN_15);
    }
  }, [allowedIntervals, interval, onSetInterval]);

  const numberOfDays = DateRangeLength.get(dateRanges[0], 'day');

  return (
    <SidebarItemContainer>
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <Typography>Zeitraum 1</Typography>

        {typeof numberOfDays === 'number' && (
          <StyledSmallTypography>{`${numberOfDays} Tag${numberOfDays !== 1 ? 'e' : ''}`}</StyledSmallTypography>
        )}
      </Stack>

      {dateRanges.map((dateRange, index) => {
        if (index >= 1) {
          return (
            <DateRangeInput
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              analysisType={analysisType}
              index={index}
              onSetDateRange={onSetDateRange}
              onRemoveDateRange={onRemoveDateRange}
              dateRanges={dateRanges}
            />
          );
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index}>
            <DateRangeInput
              analysisType={analysisType}
              index={index}
              onSetDateRange={onSetDateRange}
              onRemoveDateRange={onRemoveDateRange}
              dateRanges={dateRanges}
            />
            <StyledSmallTypography mb={2}>
              Startdatum für weitere Zeiträume auswählen. Die Dauer dieser Zeiträume wird automatisch festlegegt.
            </StyledSmallTypography>
          </Box>
        );
      })}

      <DayCategoryToggleButtons
        dayCategories={dayCategories}
        onSetDayCategories={onSetDayCategories}
        dateRanges={dateRanges}
      />

      <StyledBox>
        <Typography mb={6}>Beobachtungszeitraum</Typography>
        <TimeRangeSlider timeRange={timeRange} onSetTimeRange={onSetTimeRange} />
      </StyledBox>
      <StyledBox>
        <Typography mb={2} mt={2}>
          Aggregationsintervall
        </Typography>

        <ToggleButtonGroupWithMargin
          color="secondary"
          fullWidth
          size="small"
          value={interval}
          onChange={(event, value: FcdAnalysisRequest.interval) => onSetInterval(value)}
          exclusive
        >
          {allowedIntervals.map((value, index, array) => (
            <ToggleButton key={value} value={value}>
              {t(`IntervalType${array.length > 4 ? 'Short' : ''}.${value}`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroupWithMargin>
      </StyledBox>
    </SidebarItemContainer>
  );
}
