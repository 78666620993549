import { Point } from 'geojson';
import { v4 as uuidv4 } from 'uuid';

import { PhotonFeature, PhotonService } from '@/modules/api-photon';
import { LocationFeature, LocationFeatureProperties } from '@/types/LocationFeature';
import { RouteLocationFeature } from '@/types/RouteLocationFeature';

export class Geocoder {
  public static async reverse(point: Point): Promise<LocationFeature> {
    const response = await PhotonService.reverse({
      lon: point.coordinates[0],
      lat: point.coordinates[1],
    });

    const id = uuidv4();
    const full = `${point.coordinates[1].toFixed(6)}, ${point.coordinates[0].toFixed(6)}`;

    return {
      id,
      type: 'Feature',
      geometry: point,
      properties: {
        id,
        name: response.features?.length
          ? Geocoder.getNameFromGeocoderFeature(response.features?.[0])
          : { full, firstLine: full },
      },
    };
  }

  public static async geocoding(...parameters: Parameters<typeof PhotonService.geocoding>) {
    return ((await PhotonService.geocoding(...parameters))?.features || []).map((feature) =>
      Geocoder.transformToLocationFeature(feature),
    );
  }

  public static areSameLocationFeatures(
    featureA: LocationFeature | RouteLocationFeature,
    featureB: LocationFeature | RouteLocationFeature,
  ) {
    return (
      featureA.geometry.coordinates[0] === featureB.geometry.coordinates[0] &&
      featureA.geometry.coordinates[1] === featureB.geometry.coordinates[1]
    );
  }

  private static getNameFromGeocoderFeature({ properties }: PhotonFeature): LocationFeatureProperties['name'] {
    const { osm_key: osmKey, osm_value: osmValue, name, street, housenumber, postcode, city, country } = properties;

    if (osmKey === 'place' && osmValue === 'town' && postcode && name && country) {
      return {
        full: `${postcode} ${name}`,
        firstLine: `${postcode} ${name}`,
        secondLine: country,
      };
    }

    const displayName: string[] = [];

    if (name) {
      displayName.push(name);
    }

    if (street) {
      if (housenumber) {
        displayName.push(`${street} ${housenumber}`);
      } else {
        displayName.push(street);
      }
    }

    if (postcode && city) {
      displayName.push(`${postcode} ${city}`);
    } else {
      if (postcode) {
        displayName.push(postcode);
      }

      if (city) {
        displayName.push(city);
      }
    }

    const [firstLine, ...secondLine] = displayName;

    return {
      full: displayName.join(', '),
      firstLine,
      secondLine: secondLine.join(', '),
    };
  }

  private static transformToLocationFeature(feature: PhotonFeature): LocationFeature {
    const id = uuidv4();

    return {
      id,
      type: 'Feature',
      geometry: feature.geometry as Point,
      properties: {
        id,
        osmKey: feature.properties.osm_key,
        osmId: feature.properties.osm_id,
        name: Geocoder.getNameFromGeocoderFeature(feature),
      },
    };
  }
}
