import { ReactNode } from 'react';
import { Box, css, IconButton, styled } from '@mui/material';

import CrossCircleIcon from '@/assets/icons/analysis/cross-circle.svg?react';

const Root = styled(Box)(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.spacing(3)};
  }
  `,
);

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(1)};
  }
  `,
);

type PopupContentProps = { children: ReactNode; onClose?: () => void };

export function PopupContent({ children, onClose }: PopupContentProps) {
  return (
    <Root>
      {onClose && (
        <CloseButton aria-label="Schließen" onClick={onClose}>
          <CrossCircleIcon />
        </CloseButton>
      )}

      {children}
    </Root>
  );
}
