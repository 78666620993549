import dayjs, { Dayjs } from 'dayjs';
import { PlotData } from 'plotly.js';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { bbox } from '@turf/turf';

import { Plot } from '@/components/plot/Plot';
import { StrategyFeature } from '@/map/types/StrategyFeature';
import { useMapStore } from '@/stores/useMapStore';
import { ContextType } from '@/types/ContextType';
import { ObstructionFeature } from '@/types/ObstructionFeature';
import { ContextName } from '@/modules/ContextName';
import { DETAIL_CARD_MAX_WIDTH } from '@/map/components/detail-card/DetailCard';
import { isNonNullable } from '@/utils/isNonNullable';
import { DetectorDiagramParameters, FcdDiagramParameters } from '@/types/DiagramParameters';
import { ContextFeature } from '@/types/ContextFeature';

const CONTEXT_COLOR = {
  [ContextType.CALENDAR]: '#aecdc2',
  [ContextType.CONSTRUCTION]: '#de425b',
  [ContextType.HOLIDAY]: '#a3c266',
  [ContextType.NOTIFICATION]: '#7aa6c2',
  [ContextType.STRATEGY]: '#fa9fb5',
  [ContextType.OBSTRUCTION]: '#de425b',
};

const wordWrap = (str: string, max: number, br = '\n') =>
  str.replace(new RegExp(`(?![^\\n]{1,${max}}$)([^\\n]{1,${max}})\\s`, 'g'), `$1${br}`);

const truncate = (str: string, n: number, useWordBoundary: boolean) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1);

  return `${useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString}...`;
};

type ContextPlotProps = {
  diagramParameters: FcdDiagramParameters | DetectorDiagramParameters;
  dateRanges: DateRange<Dayjs>[];
  timeRange: number[];
  diagramVisibleDateRange: DateRange<Dayjs> | undefined;
};

export function ContextPlot({ diagramParameters, dateRanges, timeRange, diagramVisibleDateRange }: ContextPlotProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { setStrategyDetailCardFeature, setObstructionPopupFeature } = useMapStore((state) => state.actions);
  const map = useMapStore((state) => state.map);

  const dateTimeRange = [
    (dateRanges[0][0] || dayjs()).startOf('day').add(timeRange[0], 'minutes'),
    (dateRanges[0][1] || dayjs()).startOf('day').add(timeRange[1], 'minutes'),
  ];
  const bufferMinutes = (dateTimeRange[1].diff(dateTimeRange[0], 'minutes') || 2) * 0.5;
  const xRange = [dateTimeRange[0].subtract(bufferMinutes, 'minutes'), dateTimeRange[1].add(bufferMinutes, 'minutes')];

  if (!diagramParameters) {
    return null;
  }

  const data: Partial<Omit<PlotData, 'customdata'> & { customdata: any[] }>[] = diagramParameters.contexts.features.map(
    (feature: ContextFeature, index: number) => ({
      x: [
        feature.properties.valid_from
          ? dayjs(feature.properties.valid_from).tz('Europe/Berlin').format('YYYY-MM-DDTHH:mm:ss')
          : xRange[0]?.format('YYYY-MM-DDTHH:mm:ss'),
        feature.properties.valid_to
          ? dayjs(feature.properties.valid_to).tz('Europe/Berlin').format('YYYY-MM-DDTHH:mm:ss')
          : xRange[1]?.format('YYYY-MM-DDTHH:mm:ss'),
      ],
      y: [index, index],
      name: '',
      customdata: new Array(2).fill({
        contextType: t(`ContextType.${feature.properties.contextType}`),
        truncatedName: truncate(ContextName.get(feature), 100, true),
        wrappedName: wordWrap(ContextName.get(feature), 50, '<br />'),
        valid_from: feature.properties.valid_from
          ? dayjs(feature.properties.valid_from).format('DD.MM.YYYY HH:mm')
          : '',
        valid_to: feature.properties.valid_to ? dayjs(feature.properties.valid_to).format('DD.MM.YYYY HH:mm') : '',
      }),
      type: 'scatter',
      mode: 'lines',
      hovertemplate: '%{customdata.wrappedName}<br />Beginn %{customdata.valid_from}<br />Ende: %{customdata.valid_to}',
      showlegend: false,
      line: { width: 2, color: CONTEXT_COLOR[feature.properties.contextType] },
    }),
  );

  return (
    <Plot
      data={data}
      layout={{
        margin: { t: 0, r: 24, b: 24, l: 24 },
        xaxis: {
          zeroline: false,
          range: xRange.map((dateTime) => dateTime?.format('YYYY-MM-DDTHH:mm:ss')),
        },
        yaxis: {
          fixedrange: true,
          rangemode: 'normal',
          range: [-0.5, diagramParameters.contexts.features.length],
          zeroline: false,
          showticklabels: false,
          ticks: '',
          ticktext: [...data.map(({ customdata }) => customdata?.[0]?.contextType)],
          tickvals: [...Array(data.length).keys()],
        },
        hoverdistance: 5,
        hovermode: 'y unified',
        hoverlabel: { align: 'left' },
        annotations: data.map(({ customdata }, index) => ({
          xref: 'paper',
          x: 0,
          xanchor: 'left',
          y: index,
          yshift: -4,
          yanchor: 'bottom',
          text: customdata?.[0].truncatedName,
          showarrow: false,
        })),
        shapes:
          diagramVisibleDateRange && diagramVisibleDateRange[0] && diagramVisibleDateRange[1]
            ? [
                {
                  type: 'rect',
                  xref: 'x',
                  yref: 'y',
                  x0: diagramVisibleDateRange[0].format('YYYY-MM-DDTHH:mm:ss'),
                  x1: diagramVisibleDateRange[1].format('YYYY-MM-DDTHH:mm:ss'),
                  y0: -0.5,
                  y1: diagramParameters.contexts.features.length,
                  opacity: 0.4,
                  fillcolor: theme.palette.secondary.main,
                  line: { width: 0 },
                  layer: 'below',
                },
              ]
            : undefined,
      }}
      config={{
        scrollZoom: false,
        modeBarButtonsToRemove: ['zoomIn2d', 'zoomOut2d', 'zoom2d', 'lasso2d', 'toImage', 'select2d'],
      }}
      onClick={(event) => {
        if (event.points.length > 0) {
          const context = diagramParameters.contexts.features[event.points[0].y as number];

          const toLngLatBounds = (bboxArray: number[]) => bboxArray.slice(0, 4) as [number, number, number, number];

          if (context.properties.contextType === ContextType.STRATEGY) {
            setObstructionPopupFeature(undefined);
            setStrategyDetailCardFeature(context as unknown as StrategyFeature);

            const bounds = toLngLatBounds(
              bbox({
                type: 'GeometryCollection',
                geometries: [
                  context.geometry,
                  context.properties.strategy_route && JSON.parse(context.properties.strategy_route),
                  context.properties.avoided_area && JSON.parse(context.properties.avoided_area),
                  context.properties.origin_area && JSON.parse(context.properties.origin_area),
                  context.properties.destination_area && JSON.parse(context.properties.destination_area),
                  context.properties.avoided_route && JSON.parse(context.properties.avoided_route),
                ].filter(isNonNullable),
              }),
            );

            map.current?.fitBounds(bounds, {
              duration: 2000,
              padding: {
                top: 48,
                right: DETAIL_CARD_MAX_WIDTH + 16 + 196 >= map.current?.getCanvasContainer().clientWidth ? 48 : 196,
                bottom: 48,
                left:
                  DETAIL_CARD_MAX_WIDTH + 16 + 196 >= map.current?.getCanvasContainer().clientWidth
                    ? 48
                    : DETAIL_CARD_MAX_WIDTH + 16 + 196,
              },
            });
          }

          if (context.properties.contextType === ContextType.OBSTRUCTION) {
            setStrategyDetailCardFeature(undefined);
            setObstructionPopupFeature(context as unknown as ObstructionFeature);

            const bounds = toLngLatBounds(
              bbox({
                type: 'GeometryCollection',
                geometries: [context.geometry, context.properties.geom && JSON.parse(context.properties.geom)].filter(
                  isNonNullable,
                ),
              }),
            );

            map.current?.fitBounds(bounds, {
              duration: 2000,
              padding: { top: 48, right: 196, bottom: 48, left: 48 },
            });
          }
        }
      }}
    />
  );
}
