import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { nearestPointOnLine } from '@turf/turf';

import { FcdAnalysisRequest } from '@/modules/api';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { FcdAnalysisState } from '@/fcd-analysis/types/FcdAnalysisState';
import { RouteFeature } from '@/types/RouteFeature';
import { DayCategory } from '@/types/DayCategory';
import { DiagramType } from '@/types/DiagramType';
import { RouteLocationType } from '@/types/RouteLocationType';
import { RouteLocationFeature } from '@/types/RouteLocationFeature';

const createRouteFeature = (index: number = 0): RouteFeature => {
  const id = uuidv4();

  return {
    id,
    type: 'Feature',
    geometry: null,
    properties: {
      id,
      name: `Strecke ${index + 1}`,
      routeLocations: {
        type: 'FeatureCollection',
        features: [],
      },
      lineStringProperties: null,
      length: null,
    },
  };
};

export const useFcdAnalysisStore = create<FcdAnalysisState>()((set, get) => {
  const initialRoute: RouteFeature = createRouteFeature();

  return {
    step: AnalysisFormStep.LOCATION,

    routes: { type: 'FeatureCollection', features: [initialRoute] },
    parameterType: undefined,
    dateRanges: [[null, null]],
    dayCategories: Object.values(DayCategory).sort(),
    interval: FcdAnalysisRequest.interval.MIN_15,
    timeRange: [0, 1439],

    diagramType: DiagramType.GRAPH,
    nlpFcdRequest: undefined,
    routeHighlightId: initialRoute.id,
    selectedRoutePoints: undefined,
    showContexts: false,
    contexts: { type: 'FeatureCollection', features: [] },
    validateForm: false,
    diagramParameters: undefined,
    showDiagram: false,
    diagramVisibleDateRange: undefined,

    actions: {
      setFormStep: (step) => set({ step }),
      setRoutes: (routes) => set(() => ({ routes })),

      resetRoutes: () =>
        set(({ routes }) => {
          const route = createRouteFeature();

          return {
            routes: { ...routes, features: [route] },
            routeHighlightId: route.id,
            step: AnalysisFormStep.LOCATION,
          };
        }),

      addRoute: () =>
        set(({ routes }) => {
          const route = createRouteFeature(routes.features.length);

          return {
            routes: { ...routes, features: [...routes.features, route] },
            step: AnalysisFormStep.LOCATION,
            routeHighlightId: route.id,
          };
        }),

      removeRoute: (removeIndex) => {
        set(({ routes, routeHighlightId }) => {
          const features = [...routes.features];
          features.splice(removeIndex, 1);

          return {
            routes: { ...routes, features },
            step: AnalysisFormStep.LOCATION,
            routeHighlightId: features.some(({ id }) => id === routeHighlightId) ? routeHighlightId : features[0].id,
          };
        });
      },

      updateRoute: (route) =>
        set(({ routes }) => {
          const features = [...routes.features];
          const index = features.findIndex((routeItem) => routeItem.id === route.id);

          if (index !== -1) {
            features[index] = route;
          }

          return { routes: { ...routes, features }, step: AnalysisFormStep.LOCATION };
        }),

      setRouteLocation: ({ routeId, location, routeLocationType, index }) => {
        const routeLocation: RouteLocationFeature = {
          ...location,
          properties: {
            ...location.properties,
            routeLocationType,
          },
        };

        set(({ routes }) => {
          const routesFeatures = [...routes.features];
          const route = routesFeatures.find((feature) => feature.id === routeId);

          if (route) {
            route.geometry = null;
            route.properties.lineStringProperties = null;
            route.properties.length = null;
            const { routeLocations } = route.properties;

            if (routeLocationType === RouteLocationType.ORIGIN) {
              if (
                routeLocations.features[0] &&
                routeLocations.features[0].properties.routeLocationType === RouteLocationType.ORIGIN
              ) {
                routeLocations.features[0] = routeLocation;
              } else {
                routeLocations.features.unshift(routeLocation);
              }
            }

            if (routeLocationType === RouteLocationType.VIA) {
              if (index !== undefined) {
                routeLocations.features[index] = routeLocation;
              }
            }

            if (routeLocationType === RouteLocationType.DESTINATION) {
              const lastIndex = routeLocations.features.length - 1;

              if (
                routeLocations.features[lastIndex] &&
                routeLocations.features[lastIndex].properties.routeLocationType === RouteLocationType.DESTINATION
              ) {
                routeLocations.features[lastIndex] = routeLocation;
              } else {
                routeLocations.features.push(routeLocation);
              }
            }

            return { routes: { ...routes, features: routesFeatures }, step: AnalysisFormStep.LOCATION };
          }

          return {};
        });
      },

      insertViaRouteLocation: ({ routeId, location }) => {
        set(({ routes }) => {
          const routesFeatures = routes.features;
          const route = routesFeatures.find((feature) => feature.id === routeId);

          if (route) {
            route.geometry = null;
            route.properties.lineStringProperties = null;
            route.properties.length = null;
            const { routeLocations } = route.properties;
            const routeLocation: RouteLocationFeature = {
              ...location,
              properties: {
                ...location.properties,
                routeLocationType: RouteLocationType.VIA,
              },
            };

            let spliceIndex: number | undefined;
            const lastIndex = routeLocations.features.length - 1;

            if (
              routeLocations.features.find(
                ({ properties: { routeLocationType } }) => routeLocationType === RouteLocationType.VIA,
              )
            ) {
              const {
                properties: { index: nearestIndex },
              } = nearestPointOnLine(
                {
                  type: 'LineString',
                  coordinates: routeLocations.features.map(({ geometry }) => geometry.coordinates),
                },
                routeLocation,
              );

              if (nearestIndex !== undefined) {
                if (
                  nearestIndex === routeLocations.features.length - 1 &&
                  routeLocations.features[lastIndex].properties.routeLocationType === RouteLocationType.DESTINATION
                ) {
                  spliceIndex = nearestIndex;
                } else {
                  spliceIndex = nearestIndex + 1;
                }
              }
            } else if (
              lastIndex >= 0 &&
              routeLocations.features[lastIndex].properties.routeLocationType === RouteLocationType.DESTINATION
            ) {
              spliceIndex = lastIndex;
            }

            if (spliceIndex !== undefined) {
              routeLocations.features.splice(spliceIndex, 0, routeLocation);
            } else {
              routeLocations.features.push(routeLocation);
            }

            return { routes: { ...routes, features: routesFeatures }, step: AnalysisFormStep.LOCATION };
          }

          return {};
        });
      },

      removeRouteLocation: ({ routeId, routeLocationId }) =>
        set(({ routes }) => {
          const features = [...routes.features];
          const route = features.find((item) => item.id === routeId);

          if (route) {
            route.geometry = null;
            route.properties.lineStringProperties = null;
            route.properties.length = null;
            const { routeLocations } = route.properties;

            routeLocations.features = routeLocations.features.filter(
              (routeLocation) => routeLocation.id !== routeLocationId,
            );
          }

          return { routes: { ...routes, features }, step: AnalysisFormStep.LOCATION };
        }),

      setParameterType: (parameterType) => set({ parameterType }),

      setDateRange: (dateRange, index) =>
        set((state) => {
          const dateRanges = [...state.dateRanges];
          dateRanges.splice(index, 1, dateRange);

          return { dateRanges, ...(index > 0 && { showContexts: false }) };
        }),

      removeDateRange: (index) =>
        set((state) => {
          const dateRanges = [...state.dateRanges];
          dateRanges.splice(index, 1);

          return { dateRanges };
        }),

      setDayCategories: (dayCategories) => set({ dayCategories: dayCategories.sort() }),
      setInterval: (interval) => set({ interval }),
      setDiagramType: (diagramType) => set({ diagramType }),
      setTimeRange: (timeRange) => set({ timeRange }),
      setNlpFcdRequest: (nlpFcdRequest) => set({ nlpFcdRequest }),
      setRouteHighlightId: (routeHighlightId) => set({ routeHighlightId }),
      setShowDiagram: (showDiagram) => set({ showDiagram }),
      setValidateForm: (validateForm) => set({ validateForm }),

      resetForm: () => {
        const route = createRouteFeature();

        return set({
          step: AnalysisFormStep.LOCATION,

          routes: { type: 'FeatureCollection', features: [route] },
          parameterType: undefined,
          dateRanges: [[null, null]],
          dayCategories: Object.values(DayCategory).sort(),
          interval: FcdAnalysisRequest.interval.MIN_15,
          timeRange: [0, 1439],

          routeHighlightId: route.id,
          validateForm: false,
        });
      },

      submitDiagramParameters: () => {
        set(({ routes, parameterType, interval, dateRanges, dayCategories, timeRange, showContexts, contexts }) => ({
          validateForm: false,
          diagramParameters:
            parameterType &&
            cloneDeep({
              routes,
              parameterType,
              interval,
              dateRanges,
              dayCategories,
              timeRange,
              showContexts,
              contexts,
            }),
        }));

        get().actions.resetDiagramVisibleDateRange();
      },

      setShowContexts: (showContexts) => set({ showContexts }),
      setContexts: (contexts) => set({ contexts }),
      setDiagramVisibleDateRange: (diagramVisibleDateRange) => set({ diagramVisibleDateRange }),
      resetDiagramVisibleDateRange: () =>
        set(({ diagramParameters }) => ({
          diagramVisibleDateRange:
            diagramParameters?.dateRanges[0][0] && diagramParameters?.dateRanges[0][1]
              ? [
                  diagramParameters.dateRanges[0][0].startOf('day').add(diagramParameters.timeRange[0], 'minutes'),
                  diagramParameters.dateRanges[0][1].startOf('day').add(diagramParameters.timeRange[1], 'minutes'),
                ]
              : undefined,
        })),
    },
  };
});
