import { css, ListItemIcon, MenuItem, MenuList, styled } from '@mui/material';
import { Popup } from 'react-map-gl';

import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { RouteLocationType } from '@/types/RouteLocationType';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { useMapContext } from '@/stores/useMapContext';
import { Geocoder } from '@/modules/Geocoder';
import { RouteIcon } from '@/fcd-analysis/components/RouteIcon';

const StyledListItemIcon = styled(ListItemIcon)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2)};
  `,
);

export function RouteLocationPopup() {
  const point = useMapContext((state) => state.routePopupPoint);
  const { setRoutePopupPoint } = useMapContext((state) => state.actions);
  const routes = useFcdAnalysisStore((state) => state.routes);

  const routeHighlightId = useFcdAnalysisStore((state) => state.routeHighlightId);

  const { setRouteLocation, insertViaRouteLocation } = useFcdAnalysisStore((state) => state.actions);

  if (!point) {
    return null;
  }

  const onClose = () => setRoutePopupPoint(undefined);

  const handleSetMarkerClick = async (routeLocationType: RouteLocationType) => {
    const location = await Geocoder.reverse({
      type: 'Point',
      coordinates: [point.coordinates[0], point.coordinates[1]],
    });

    if (routeLocationType === RouteLocationType.VIA) {
      insertViaRouteLocation({ routeId: routeHighlightId, location });
    } else {
      setRouteLocation({ routeId: routeHighlightId, location, routeLocationType });
    }

    onClose();
  };

  const [longitude, latitude] = point.coordinates;

  const highlightedRouteIndex = routes.features.findIndex((route) => route.id === routeHighlightId);

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={18}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
      closeOnClick
      closeOnMove
      onClose={onClose}
    >
      <MenuList sx={{ width: 140 }}>
        {[RouteLocationType.ORIGIN, RouteLocationType.VIA, RouteLocationType.DESTINATION].map((type) => (
          <MenuItem key={type} onClick={() => handleSetMarkerClick(type)}>
            <StyledListItemIcon>
              <RouteIcon type={type} color={ROUTE_COLOR[highlightedRouteIndex]} width={28} height="auto" />
            </StyledListItemIcon>
            {
              {
                [RouteLocationType.ORIGIN]: 'Start',
                [RouteLocationType.VIA]: 'Via',
                [RouteLocationType.DESTINATION]: 'Ziel',
              }[type]
            }
          </MenuItem>
        ))}
      </MenuList>
    </Popup>
  );
}
