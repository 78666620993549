import { FormControlLabel, Radio, Tooltip } from '@mui/material';
import { Trans } from 'react-i18next';

type LayerControlRadioProps = {
  value: string;
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  i18nKey: string;
  tooltip?: string;
};
export function LayerControlRadio({ value, checked, onChange, i18nKey, tooltip }: LayerControlRadioProps) {
  return tooltip ? (
    <Tooltip title={tooltip} placement="left">
      <FormControlLabel
        label={<Trans i18nKey={`${i18nKey}.${value}`} shouldUnescape />}
        control={<Radio checked={checked} onChange={(event, newChecked) => onChange(newChecked)} size="small" />}
      />
    </Tooltip>
  ) : (
    <FormControlLabel
      label={<Trans i18nKey={`${i18nKey}.${value}`} shouldUnescape />}
      control={<Radio checked={checked} onChange={(event, newChecked) => onChange(newChecked)} size="small" />}
    />
  );
}
