import { ToggleButtonGroup, css, styled } from '@mui/material';

export const ToggleButtonGroupWithMargin = styled(ToggleButtonGroup)(
  ({ theme }) => css`
    & .MuiToggleButtonGroup-grouped {
      &:not(:last-of-type) {
        margin-right: ${theme.spacing(1)};
      }

      &.Mui-disabled {
        border: 0;
      }

      &:not(:first-of-type) {
        border: 1px solid ${theme.palette.secondary.main};
        border-radius: ${theme.shape.borderRadius}px;
      }

      &:first-of-type {
        border: 1px solid ${theme.palette.secondary.main};
        border-radius: ${theme.shape.borderRadius}px;
      }
    }
  `,
);
