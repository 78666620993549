import { Trans } from 'react-i18next';

import { FcdLayerType } from '@/types/FcdLayerType';
import { LegendControl } from '@/monitoring/components/LegendControl';
import { useMapContext } from '@/stores/useMapContext';
import { FCD_LEGEND } from '@/map/modules/FcdLegend';

export function FcdLegend() {
  const mapLayers = useMapContext((state) => state.layers);

  return mapLayers.fcd !== FcdLayerType.NONE ? (
    <LegendControl
      title={<Trans i18nKey={`FcdLayerType.${mapLayers.fcd}`} shouldUnescape />}
      legend={FCD_LEGEND[mapLayers.fcd]}
      titleTooltip={
        {
          [FcdLayerType.LEVEL_OF_SERVICE]: undefined,
          [FcdLayerType.CONFIDENCE_VALUE]: undefined,
          [FcdLayerType.SPEEDING]: undefined,
          [FcdLayerType.SPEEDING_LOS]: undefined,
          [FcdLayerType.SLOW_DOWN]:
            'Geschwindigkeitsunterschreitungen im unbel. Netz (Zul. Geschwindigkeit / Freiflussgeschwindigkeit)',
          [FcdLayerType.ROUTING_NET]: undefined,
        }[mapLayers.fcd]
      }
    />
  ) : null;
}
