import { LineString, MultiPolygon } from 'geojson';

import { StrategyFeature } from '@/map/types/StrategyFeature';
import { RouteSegmentsFeatureCollection } from '@/map/types/RouteSegmentFeature';
import { StrategyLineType } from '@/map/types/StrategyLineType';

export class Strategy {
  public static getGeometries(feature: StrategyFeature) {
    const routeAvoidString = feature.properties.avoided_route;
    const routeAvoid: LineString | undefined = routeAvoidString && JSON.parse(routeAvoidString);

    const routePreferredString = feature.properties.strategy_route;
    const routePreferred: LineString | undefined = routePreferredString && JSON.parse(routePreferredString);

    const routeSegments: RouteSegmentsFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    if (routeAvoid) {
      routeSegments.features.push({
        type: 'Feature',
        geometry: routeAvoid,
        properties: {
          routeLineType: StrategyLineType.AVOID,
        },
      });
    }

    if (routePreferred) {
      routeSegments.features.push({
        type: 'Feature',
        geometry: routePreferred,
        properties: {
          routeLineType: StrategyLineType.PREFERRED,
        },
      });
    }

    const avoidedAreaString = feature.properties.avoided_area;
    const avoidedArea: MultiPolygon | undefined = avoidedAreaString && JSON.parse(avoidedAreaString);
    const originAreaString = feature.properties.origin_area;
    const originArea: MultiPolygon | undefined = originAreaString && JSON.parse(originAreaString);
    const destinationAreaString = feature.properties.destination_area;
    const destinationArea: MultiPolygon | undefined = destinationAreaString && JSON.parse(destinationAreaString);

    return {
      routeAvoid,
      routePreferred,
      avoidedArea,
      originArea,
      destinationArea,
    };
  }
}
