import { Box, Button, css, FormGroup, Link as MuiLink, styled, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { supabase } from '@/setup/supabaseClient';
import { useSession } from '@/authentication/hooks/useSession';

const StyledFormGroup = styled(FormGroup)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
  `,
);

type LoginData = { email: string; password: string };

export function LoginPage() {
  const { data: session } = useSession();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>();

  const { mutate, error } = useMutation({
    mutationFn: async (loginData: LoginData) => {
      const { data, error: supabaseError } = await supabase.auth.signInWithPassword(loginData);

      if (supabaseError) {
        return Promise.reject(supabaseError);
      }

      return Promise.resolve(data);
    },
  });

  if (session) {
    return <Navigate to="/" />;
  }

  return (
    <form
      onSubmit={handleSubmit((loginData) => {
        mutate(loginData);
      })}
    >
      <StyledFormGroup>
        <TextField
          label="E-Mail"
          type="email"
          size="small"
          error={!!error || !!errors.email}
          {...register('email', { required: true })}
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <TextField
          label="Passwort"
          type="password"
          size="small"
          error={!!error || !!errors.password}
          {...register('password', { required: true })}
        />
      </StyledFormGroup>

      <Box sx={{ textAlign: 'right', fontWeight: 'bold', marginBottom: 3 }}>
        <MuiLink component={Link} to="/passwort-vergessen">
          Passwort vergessen?
        </MuiLink>
      </Box>

      <Button variant="contained" type="submit" fullWidth sx={{ marginBottom: 3 }}>
        Login
      </Button>
    </form>
  );
}
