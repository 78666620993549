import { Navigate, useLocation } from 'react-router-dom';
import { ReactElement } from 'react';

import { useSession } from '@/authentication/hooks/useSession';

type AuthenticatedProps = {
  children: ReactElement;
};

export function Authenticated({ children }: AuthenticatedProps) {
  const { data: session, isLoading } = useSession();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!session) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
}
