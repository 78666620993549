import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

import { RouteLocationType } from '@/types/RouteLocationType';
import { isNonNullable } from '@/utils/isNonNullable';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { ValidationStatus } from '@/types/ValidationStatus';
import { RoutesFeatureCollection } from '@/types/RouteFeature';
import { DetectorParameterType } from '@/types/DetectorParameterType';
import { FcdParameterType } from '@/types/FcdParameterType';
import { ContextsFeatureCollection } from '@/types/ContextFeature';
import { DetectorGroupsFeatureCollection } from '@/types/DetectorGroupFeature';

interface ValidationOptions {
  dateRanges: DateRange<Dayjs>[];
  parameterType: FcdParameterType | DetectorParameterType | undefined;
  showContexts: boolean;
  validateForm: boolean;
  routes?: RoutesFeatureCollection | undefined;
  detectorGroups?: DetectorGroupsFeatureCollection | undefined;
  contexts: ContextsFeatureCollection;
}

export const useValidation = ({
  dateRanges,
  parameterType,
  showContexts,
  validateForm,
  routes = undefined,
  detectorGroups = undefined,
  contexts,
}: ValidationOptions): Record<AnalysisFormStep, { status: ValidationStatus; count: string | number }> => {
  const validRoutesCount =
    routes &&
    routes.features.filter(
      ({
        geometry,
        properties: {
          routeLocations: { features },
        },
      }) =>
        geometry !== null &&
        features.some(({ properties: { routeLocationType } }) => routeLocationType === RouteLocationType.ORIGIN) &&
        features.some(({ properties: { routeLocationType } }) => routeLocationType === RouteLocationType.DESTINATION),
    ).length;

  const validDetectorGroupsCount =
    detectorGroups &&
    detectorGroups.features.filter((detectors) => detectors?.properties.detectors.features.length > 0).length;

  let locationStatus: ValidationStatus = 'success';

  if (routes) {
    if (routes.features.length === 1 && routes.features[0].properties.routeLocations.features.length === 0) {
      locationStatus = 'neutral';
    } else if (routes.features.length !== validRoutesCount) {
      locationStatus = 'error';
    }
  }

  if (detectorGroups) {
    if (detectorGroups.features.length === 1 && detectorGroups.features[0].properties.detectors.features.length === 0) {
      locationStatus = 'neutral';
    } else if (detectorGroups.features.length !== validDetectorGroupsCount) {
      locationStatus = 'error';
    }
  }

  const validDateRangesCount = dateRanges?.filter((dates) =>
    dates.every((value) => isNonNullable(value) && value.isValid()),
  ).length;

  let dateTimeStatus: ValidationStatus = 'success';
  if (dateRanges?.length === 1 && dateRanges[0].every((value) => value === null)) {
    dateTimeStatus = 'neutral';
  } else if (dateRanges?.length !== validDateRangesCount) {
    dateTimeStatus = 'error';
  }

  let parameterStatus: ValidationStatus = 'success';

  if (!parameterType) {
    parameterStatus = 'neutral';
  }

  let contextsStatus: ValidationStatus = 'info';
  if (dateRanges?.length > 1) {
    contextsStatus = 'error';
  }

  return {
    [AnalysisFormStep.LOCATION]: {
      status: validateForm && locationStatus !== 'success' ? 'error' : locationStatus,
      count:
        // eslint-disable-next-line no-nested-ternary
        routes && routes.features.length > 0
          ? locationStatus === 'neutral'
            ? 'Leer'
            : routes.features.length
          : // eslint-disable-next-line no-nested-ternary
            detectorGroups && detectorGroups.features.length > 0
            ? locationStatus === 'neutral'
              ? 'Leer'
              : detectorGroups.features.length
            : 'Leer',
    },
    [AnalysisFormStep.DATE_TIME]: {
      status: validateForm && dateTimeStatus !== 'success' ? 'error' : dateTimeStatus,
      count: dateTimeStatus === 'neutral' ? 'Leer' : dateRanges.length,
    },
    [AnalysisFormStep.PARAMETER]: {
      status: validateForm && parameterStatus !== 'success' ? 'error' : parameterStatus,
      count: parameterStatus === 'neutral' ? 'Leer' : '',
    },
    [AnalysisFormStep.CONTEXTS]: {
      status: contextsStatus,
      count: contexts && contexts.features.length > 0 && showContexts ? `${contexts?.features.length}/5` : 'Leer',
    },
  };
};
