import { Dayjs } from 'dayjs';

import { TempusService } from '@/modules/api';
import { HotspotsService } from '@/modules/HotspotsService';

export class QueryKey {
  static authentication = {
    all: ['authentication'],
    session: () => [...this.authentication.all, 'session'],
  };

  static fcdHistory = {
    all: ['fcdHistory'],
    getFcdHistory: (parameters: Parameters<typeof TempusService.getFcdHistory>[0]['requestBody']) => [
      ...this.fcdHistory.all,
      'getFcdHistory',
      parameters,
    ],
  };

  static fcdLive = {
    all: ['fcdLive'],
    getFcdLive: (parameters: Parameters<typeof TempusService.getFcdLive>) => [
      ...this.fcdLive.all,
      'getFcdLive',
      parameters,
    ],
  };

  static routeGeojson = {
    all: ['calculateRouteGeojson'],
    calculateRouteGeojson: (parameters: Parameters<typeof TempusService.calculateRouteGeojson>[0]['requestBody']) => [
      ...this.routeGeojson.all,
      'calculateRouteGeojson',
      parameters,
    ],
  };

  static fcdAnalysis = {
    all: ['fcd'],
    getFcd: (parameters: Parameters<typeof TempusService.getFcd>[0]['requestBody']) => [
      ...this.fcdAnalysis.all,
      'getFcd',
      parameters,
    ],
  };

  static detectorAnalysis = {
    all: ['detectors'],
    getDetectorAnalysis: (parameters: Parameters<typeof TempusService.getDetectorAnalysis>[0]['requestBody']) => [
      ...this.detectorAnalysis.all,
      'getDetectorAnalysis',
      parameters,
    ],
  };

  static userData = {
    all: ['userId'],
    getUserId: () => [...this.userData.all, 'getUserId'],
  };

  static routes = {
    all: ['routes'],
    getUserRoutesPaginated: (parameters: { page: number; rowsPerPage: number }) => [
      ...this.routes.all,
      'getUserRoutesPaginated',
      parameters,
    ],
  };

  static hotspots = {
    all: ['hotspots'],
    getHotspotsPaginated: (parameters: { page: number; rowsPerPage: number }) => [
      ...this.hotspots.all,
      'getHotspotsPaginated',
      parameters,
    ],
  };

  static hotspotEvents = {
    all: ['hotspotEvents'],
    get: (parameters: Parameters<typeof HotspotsService.getHotspotEvents>[0]) => [
      ...this.hotspotEvents.all,
      'get',
      parameters,
    ],
  };

  static strategies = {
    getStategiesFiltered: (parameters: { from: Dayjs | null; to: Dayjs | null }) => [
      'getStategiesFiltered',
      parameters,
    ],
  };

  static trafficMessages = {
    getTrafficMessagesFiltered: (parameters: { from: Dayjs | null; to: Dayjs | null }) => [
      'getTrafficMessagesFiltered',
      parameters,
    ],
  };

  static holidays = {
    getHolidaysFiltered: (parameters: { from: Dayjs | null; to: Dayjs | null }) => ['getHolidaysFiltered', parameters],
  };

  static detectors = {
    all: ['detectors'],
    getDetectors: () => [...this.detectors.all, 'getDetectors'],
    getUserDetectorsPaginated: (parameters: { page: number; rowsPerPage: number }) => [
      ...this.detectors.all,
      'getUserDetectorsPaginated',
      parameters,
    ],
  };
}
