import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import dayjs from 'dayjs';

import CopyIcon from '@/map/assets/copy.svg?react';
import { useMapContext } from '@/stores/useMapContext';
import { DetailCard } from '@/map/components/detail-card/DetailCard';
import { DetailCardTable } from '@/map/components/detail-card/DetailCardTable';
import { DetectorPopupActions } from '@/detector-analysis/components/DetectorPopupActions';

export function DetectorDetailCard() {
  const { setDetectorDetailCardFeature } = useMapContext((state) => state.actions);
  const feature = useMapContext((state) => state.detectorDetailCardFeature);

  const [copiedDetektorId, setCopiedDetektorId] = useState(false);

  if (!feature) {
    return null;
  }

  const onClose = () => setDetectorDetailCardFeature(undefined);

  const {
    id,
    last_update: update,
    location_description: location,
    measurement_interval: interval,
    type,
  } = feature.properties;

  return (
    <DetailCard title="Detektorinformation" onClose={onClose}>
      <DetailCardTable
        data={[
          {
            label: 'Detektor-ID',
            value: (
              <>
                {id}
                <Tooltip
                  title={copiedDetektorId ? 'Detektor-ID wurde kopiert' : 'Detektor-ID in Zwischenablage kopieren'}
                  placement="right"
                >
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(id);
                      setCopiedDetektorId(true);
                    }}
                    onMouseLeave={() =>
                      setTimeout(() => {
                        setCopiedDetektorId(false);
                      }, 500)
                    }
                  >
                    <CopyIcon style={{ width: 20, height: 'auto' }} />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            label: 'Zuletzt aktualisiert',
            value: dayjs(update).format('L LT'),
          },
          { label: 'Standortbeschreibung', value: location },
          { label: 'Messintervall', value: interval },
          { label: 'Typ', value: type },
        ]}
      />
      <DetectorPopupActions detectors={[feature]} />
    </DetailCard>
  );
}
