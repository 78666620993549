import { Button, IconButton, Stack, Typography } from '@mui/material';
import { DatePicker, DateRange, DateRangePicker, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import CrossCircleIcon from '@/assets/icons/analysis/cross-circle.svg?react';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { StyledSmallTypography } from '@/components/StyledSmallTypography';
import { AnalysisType } from '@/types/AnalysisType';
import { PlusIcon } from '@/components/PlusIcon';
import { HistoricalDateRanges } from '@/modules/HistoricalDateRanges';

type DateRangeProps = {
  analysisType: AnalysisType;
  index: number;
  onSetDateRange: (dateRange: [Dayjs | null, Dayjs | null], index: number) => void;
  onRemoveDateRange: (index: number) => void;
  dateRanges: DateRange<Dayjs>[];
};

export function DateRangeInput({ analysisType, index, onSetDateRange, onRemoveDateRange, dateRanges }: DateRangeProps) {
  const [open, setOpen] = useState(false);

  const validateForm = useFcdAnalysisStore((state) => state.validateForm);
  const daySpan = dateRanges[0][1]?.diff(dateRanges[0][0], 'days') || 0;
  const maxDate = dayjs().subtract(1, 'day').endOf('day');

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = HistoricalDateRanges.getAvailableYears({
    type: analysisType,
  }).map((year) => ({
    label: year.toString(),
    getValue: () => [
      HistoricalDateRanges.getYearMinDate({ type: analysisType, year: dayjs().year(year).startOf('year') }) || null,
      null,
    ],
  }));

  return (
    <>
      {index === 0 ? (
        <DateRangePicker
          sx={{ mb: 1 }}
          format="DD.MM.YYYY"
          value={dateRanges[index]}
          onAccept={(v) => {
            onSetDateRange(v, index);
            const tempDaySpan = v[1]?.diff(v[0], 'days') || 0;

            for (let i = 1; i < dateRanges.length; i += 1) {
              if (dateRanges[i][0]) {
                onSetDateRange([dateRanges[i][0], dateRanges[i][0]!.add(tempDaySpan, 'days')], i);
              }
            }
          }}
          calendars={2}
          localeText={{ start: 'Beginn', end: 'Ende' }}
          slotProps={{
            textField: {
              variant: 'outlined',
              error: validateForm && dateRanges[index].some((date) => !date || !date.isValid()),
            },
            fieldSeparator: { sx: { display: 'none' } },
            shortcuts: {
              items: shortcutsItems,
              changeImportance: 'set',
            },
          }}
          disableHighlightToday
          shouldDisableDate={(date) => !HistoricalDateRanges.isDateAvailable({ type: analysisType, date })}
          minDate={HistoricalDateRanges.getMinDate({ type: analysisType })}
          maxDate={maxDate}
        />
      ) : (
        <>
          <Typography mb={1}>Zeitraum {index + 1}</Typography>
          <Stack direction="row" justifyContent="center" spacing={2} mb={2}>
            <DatePicker
              format="DD.MM.YYYY"
              disableHighlightToday
              value={dateRanges[index][0]}
              onChange={(newValue) => {
                if (newValue) onSetDateRange([newValue, newValue.add(daySpan, 'days')], index);
              }}
              shouldDisableYear={(year) => !HistoricalDateRanges.isYearAvailable({ type: analysisType, year })}
              shouldDisableDate={(date) =>
                !HistoricalDateRanges.isDateAvailable({ type: analysisType, date: date.add(daySpan, 'days') })
              }
              minDate={HistoricalDateRanges.getMinDate({ type: analysisType })}
              maxDate={maxDate.subtract(daySpan, 'days')}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              slotProps={{
                textField: {
                  error: validateForm && (!dateRanges[index][0] || !dateRanges[index][0]!.isValid()),
                  onClick: () => setOpen(true),
                  sx: {
                    maxWidth: (theme) => `${theme.spacing(20.5)}`,
                  },
                  variant: 'outlined',
                  label: 'Beginn',
                  InputProps: { endAdornment: null },
                },
              }}
            />
            <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ flex: 1 }}>
              <StyledSmallTypography>
                {dateRanges[index][1]?.isValid() && dateRanges[index][1]!.format('DD.MM.YYYY')}
              </StyledSmallTypography>
              <IconButton sx={{ marginLeft: 2 }} onClick={() => onRemoveDateRange(index)} aria-label="Entfernen">
                <CrossCircleIcon />
              </IconButton>
            </Stack>
          </Stack>
        </>
      )}

      {index < 2 && dateRanges.length === index + 1 && (
        <Button
          startIcon={<PlusIcon />}
          size="small"
          color="neutral"
          sx={{ marginBottom: 2 }}
          onClick={() => {
            onSetDateRange([null, null], dateRanges.length);
          }}
        >
          Zeitraum hinzufügen
        </Button>
      )}
    </>
  );
}
