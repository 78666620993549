import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { ApiError, DetectorAnalysisRequest, DetectorAnalysisResponse, TempusService } from '@/modules/api';
import { QueryKey } from '@/modules/QueryKey';
import { DiagramType } from '@/types/DiagramType';
import { DayCategory } from '@/types/DayCategory';
import { isNonNullable } from '@/utils/isNonNullable';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { DetectorAnalysisValueTypes } from '@/types/DetectorAnalysisValueTypes';
import { DetectorParameterType } from '@/types/DetectorParameterType';

const transformMinutesToUtcTimeString = (minutes: number) =>
  dayjs().startOf('day').add(minutes, 'minutes').tz('UTC').format('HH:mm');

export const useDetectorAnalysis = () => {
  const diagramParameters = useDetectorAnalysisStore((state) => state.diagramParameters);

  const detectorRequest = useMemo<DetectorAnalysisRequest | undefined>(() => {
    if (!diagramParameters) {
      return undefined;
    }

    if (
      !diagramParameters?.dateRanges.every((dateRanges) =>
        dateRanges.every((date) => isNonNullable(date) && date.isValid()),
      )
    ) {
      return undefined;
    }

    return {
      ...(diagramParameters.detectorGroups.features.length > 1 && { diagramType: DiagramType.GRAPH }),

      analysisType: DetectorAnalysisRequest.analysisType.DETECTOR_ANALYSIS,
      valueTypes: [diagramParameters?.parameterType?.toLowerCase()] as DetectorAnalysisValueTypes,
      mode: {
        [DetectorParameterType.NUM_VEHICLES]: DetectorAnalysisRequest.mode.SUM,
        [DetectorParameterType.V]: DetectorAnalysisRequest.mode.AVG,
      }[diagramParameters.parameterType as DetectorParameterType],
      interval: diagramParameters.interval,
      aggregate: false,
      timeParameters: diagramParameters.dateRanges.map((dateRange) => ({
        start: dateRange[0]!.startOf('day').toISOString(),
        end: dateRange[1]!.endOf('day').toISOString(),
        ...(!Object.values(DayCategory).every((elem) => diagramParameters.dayCategories.includes(elem)) && {
          dayCategories: diagramParameters.dayCategories,
        }),
        ...(!(diagramParameters.timeRange[0] === 0 && diagramParameters.timeRange[1] === 1439) && {
          timeRanges: [
            {
              from: transformMinutesToUtcTimeString(diagramParameters.timeRange[0]),
              to: transformMinutesToUtcTimeString(diagramParameters.timeRange[1]),
            },
          ],
        }),
      })),
      detectors: diagramParameters.detectorGroups.features.map((detectorGroup) =>
        detectorGroup.properties.detectors.features.map((detector) => detector.properties.id),
      ),
    };
  }, [diagramParameters]);

  return useQuery<DetectorAnalysisResponse, ApiError, DetectorAnalysisResponse>({
    queryKey: QueryKey.detectorAnalysis.getDetectorAnalysis(detectorRequest!),
    queryFn: () =>
      TempusService.getDetectorAnalysis({
        requestBody: detectorRequest!,
      }),

    refetchOnWindowFocus: false,
    retry: false,
    gcTime: 6000000,
    staleTime: 6000000,
    enabled: !!detectorRequest,
  });
};
