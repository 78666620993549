import { MapGeoJSONFeature } from 'react-map-gl';

import { ObstructionFeature } from '@/types/ObstructionFeature';
import { StrategyFeature } from '@/map/types/StrategyFeature';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { HotspotFeature } from '@/types/HotspotFeature';
import { HotspotEventFeature } from '@/types/HotspotEventFeature';
import { DETECTORS_CLUSTER_SOURCE, DETECTORS_SELECTED_SOURCE } from '@/detector-analysis/components/DetectorsLayer';
import { DetectorFeature } from '@/types/DetectorFeature';

export class FeatureGuard {
  public static isStrategyFeature(feature: MapGeoJSONFeature): feature is StrategyFeature & MapGeoJSONFeature {
    return FeatureLayerType.STRATEGY === feature.layer.id;
  }

  public static isObstructionFeature(feature: MapGeoJSONFeature): feature is ObstructionFeature & MapGeoJSONFeature {
    return FeatureLayerType.OBSTRUCTION === feature.layer.id;
  }

  public static isHotspotFeature(feature: HotspotFeature | HotspotEventFeature): feature is HotspotFeature {
    return 'hotspot_name' in feature.properties;
  }

  public static isDetectorFeature(feature: MapGeoJSONFeature): feature is DetectorFeature & MapGeoJSONFeature {
    return [DETECTORS_CLUSTER_SOURCE, DETECTORS_SELECTED_SOURCE].includes(feature.source);
  }
}
