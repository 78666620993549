import { TextField } from '@mui/material';
import { GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro';

import { HotspotFeature } from '@/types/HotspotFeature';

type TimeInputComponentProps = {
  item: GridFilterItem;
  applyValue: (item: { value: string }) => void;
};

function TimeInputComponent({ item, applyValue }: TimeInputComponentProps) {
  return (
    <TextField
      type="time"
      variant="standard"
      label="Uhrzeit"
      value={item.value || ''}
      onChange={(event) => {
        applyValue({ ...item, value: event.target.value });
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export const timeFilterOperators: GridFilterOperator<HotspotFeature>[] = [
  {
    label: 'ab',
    value: 'from',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }

      return ({ formattedValue }) => formattedValue.localeCompare(filterItem.value) >= 0;
    },
    InputComponent: TimeInputComponent,
  },
  {
    label: 'bis',
    value: 'until',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) {
        return null;
      }

      return ({ formattedValue }) => formattedValue.localeCompare(filterItem.value) <= 0;
    },
    InputComponent: TimeInputComponent,
  },
];
