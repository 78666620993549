import { create } from 'zustand';

import { HotspotState } from '@/types/HotspotState';

export const useHotspotsStore = create<HotspotState>()((set) => ({
  hotspotHighlightId: undefined,
  hotspotFeature: undefined,
  hotspotEventFeature: undefined,
  visibleHotspotsFeatureCollection: undefined,
  dataGridInitialState: {
    filter: {
      filterModel: {
        items: [{ id: 99999, field: 'number_of_events', operator: '>=', value: '6' }],
      },
    },
    pagination: {
      paginationModel: {
        pageSize: 10,
      },
    },
  },
  detailPanelExpandedRowIds: [],

  actions: {
    setHotspotHighlightId: (hotspotHighlightId) => set({ hotspotHighlightId }),
    setHotspotFeature: (hotspotFeature) => set({ hotspotFeature }),
    setHotspotEventFeature: (hotspotEventFeature) => set({ hotspotEventFeature }),
    setVisibleHotspotsFeatureCollection: (visibleHotspotsFeatureCollection) =>
      set({ visibleHotspotsFeatureCollection }),
    setDataGridInitialState: (dataGridInitialState) => set({ dataGridInitialState }),
    setDetailPanelExpandedRowIds: (detailPanelExpandedRowIds) => set({ detailPanelExpandedRowIds }),
  },
}));
