import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Config } from 'plotly.js';

import { RouteName } from '@/fcd-analysis/modules/RouteName';
import { DetectorDiagramParameters, FcdDiagramParameters } from '@/types/DiagramParameters';
import { AnalysisType } from '@/types/AnalysisType';

export const useExportToImageConfig = ({
  analysisType,
  diagramParameters,
}:
  | { analysisType: AnalysisType.FCD; diagramParameters: FcdDiagramParameters }
  | { analysisType: AnalysisType.DETECTOR; diagramParameters: DetectorDiagramParameters }) => {
  const { t } = useTranslation();

  let filename;

  switch (analysisType) {
    case AnalysisType.FCD:
      filename = `${t(`FcdParameterType.${diagramParameters?.parameterType}`).toString()}_${RouteName.start(
        diagramParameters.routes.features[0],
      )}`;
      break;
    case AnalysisType.DETECTOR:
      filename = `${t(`DetectorParameterType.${diagramParameters?.parameterType}`).toString()}_${
        diagramParameters?.detectorGroups?.features[0].properties.name
      }`;
      break;
    default:
      break;
  }

  filename += `${filename}_${dayjs().format('DD.MM.YYYY')}`;

  const config = {
    toImageButtonOptions: {
      format: 'png',
      filename,
    },
  };

  return config as Partial<Config>;
};
