import { css, LinearProgress, styled } from '@mui/material';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

const StyledLinearProgress = styled(LinearProgress)(css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`);

export function Loading() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  return isFetching !== 0 || isMutating !== 0 ? <StyledLinearProgress /> : null;
}
