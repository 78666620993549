import { IconButton, css, styled } from '@mui/material';

import ShowDiagramIcon from '@/assets/icons/analysis/show-diagram.svg?react';
import { DetectorDiagramParameters, FcdDiagramParameters } from '@/types/DiagramParameters';

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing(1)};
    left: ${theme.spacing(1)};
    z-index: 3;
    background-color: ${theme.palette.common.white};
    box-shadow: ${theme.shadows[1]};

    &:hover {
      background-color: #f5f5f5;
    }
  `,
);

type ShowDiagramButtonProps = {
  visible: boolean;
  onShowDiagram: () => void;
  diagramParameters?: FcdDiagramParameters | DetectorDiagramParameters;
};

export function ShowDiagramButton({ visible, onShowDiagram, diagramParameters }: ShowDiagramButtonProps) {
  return !visible && diagramParameters ? (
    <StyledIconButton aria-label="Diagramm anzeigen" onClick={onShowDiagram}>
      <ShowDiagramIcon />
    </StyledIconButton>
  ) : null;
}
