import mapboxgl from 'mapbox-gl';

import { INITIAL_MAP_VIEW_STATE, useMapStore } from '@/stores/useMapStore';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';

export const useMapFitBounds = () => {
  const map = useMapStore((state) => state.map);
  const routes = useFcdAnalysisStore((state) => state.routes);

  const reset = () => {
    map.current?.resize();

    setTimeout(
      () =>
        map.current?.flyTo({
          center: {
            lng: INITIAL_MAP_VIEW_STATE.longitude,
            lat: INITIAL_MAP_VIEW_STATE.latitude,
          },
          zoom: INITIAL_MAP_VIEW_STATE.zoom,
        }),
      0,
    );
  };

  const fitRoutes = () => {
    map.current?.resize();

    setTimeout(() => {
      if (routes) {
        const bounds = new mapboxgl.LngLatBounds();

        routes.features.forEach((route) => {
          route?.geometry?.coordinates?.forEach((coordinates) => {
            coordinates?.forEach((coordinate) => bounds.extend(coordinate as [number, number]));
          });
        });

        if (!bounds.isEmpty()) {
          map.current?.fitBounds(bounds, {
            padding: 48,
            animate: true,
            duration: 300,
          });
        }
      }
    }, 0);
  };

  return { reset, fitRoutes };
};
