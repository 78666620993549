import { create } from 'zustand';
import { createRef } from 'react';
import { MapRef } from 'react-map-gl';

import { MapState } from '@/types/MapState';

export const INITIAL_MAP_VIEW_STATE = { longitude: 11.58022, latitude: 48.13913, zoom: 10.5 };

export const useMapStore = create<MapState>()((set) => ({
  map: createRef<MapRef>(),

  activeSide: undefined,

  viewState: INITIAL_MAP_VIEW_STATE,

  cursorCoordinates: undefined,

  fcdHighlightId: undefined,

  strategyDetailCardFeature: undefined,

  obstructionPopupFeature: undefined,

  sideBySideMaps: false,

  actions: {
    setActiveSide: (activeSide) => set({ activeSide }),

    setViewState: (viewState) => set({ viewState }),

    setCursorCoordinates: (cursorCoordinates) => set({ cursorCoordinates }),

    setFcdHighlightId: (fcdHighlightId) => set({ fcdHighlightId }),

    setStrategyDetailCardFeature: (strategyDetailCardFeature) => set({ strategyDetailCardFeature }),

    setObstructionPopupFeature: (obstructionPopupFeature) => set({ obstructionPopupFeature }),

    setSideBySideMaps: (sideBySideMaps) => set({ sideBySideMaps }),
  },
}));
