import { Layer, Source } from 'react-map-gl';
import { Expression, LinePaint } from 'mapbox-gl';
import { useMatch } from 'react-router-dom';

import { FcdLayerType } from '@/types/FcdLayerType';
import { FCD_LEGEND } from '@/map/modules/FcdLegend';
import { useMapContext } from '@/stores/useMapContext';
import { useFcdHistory } from '@/map/hooks/useFcdHistory';
import { FCD_LAYER } from '@/types/FcdLayer';
import { useFcdLive } from '@/map/hooks/useFcdLive';
import { MapBefore } from '@/map/types/MapBefore';
import { useMapStore } from '@/stores/useMapStore';
import { MARTIN_URL } from '@/setup/martin';
import { LineHighlightLayers } from '@/map/components/utils/LineHighlightLayers';

export const ROUTING_NET_SOURCE_LAYER = 'routing_net';

export const ROUTING_NET_LINE_OFFSET: Expression = [
  'interpolate',
  ['exponential', 0.5],
  ['zoom'],
  10,
  0,
  14,
  4,
  18,
  12,
];

const FCD_LINE_COLOR: Record<Exclude<FcdLayerType, FcdLayerType.NONE>, LinePaint['line-color']> = {
  [FcdLayerType.CONFIDENCE_VALUE]: [
    'case',
    ['to-boolean', ['feature-state', 'confidence']],
    [
      'step',
      ['feature-state', 'confidence'],
      ...FCD_LEGEND[FcdLayerType.CONFIDENCE_VALUE]
        .map(({ value, color }) => (value ? [color, value] : [color]))
        .flat()
        .reverse(),
    ],
    FCD_LEGEND[FcdLayerType.ROUTING_NET][0].color,
  ],
  [FcdLayerType.LEVEL_OF_SERVICE]: [
    'case',
    ['to-boolean', ['feature-state', 'los']],
    [
      'step',
      ['feature-state', 'los'],
      ...FCD_LEGEND[FcdLayerType.LEVEL_OF_SERVICE].map(({ value, color }) => (value ? [color, value] : [color])).flat(),
    ],
    FCD_LEGEND[FcdLayerType.ROUTING_NET][0].color,
  ],
  [FcdLayerType.SPEEDING]: [
    'case',
    ['to-boolean', ['feature-state', 'speeding']],
    [
      'step',
      ['feature-state', 'speeding'],
      ...FCD_LEGEND[FcdLayerType.SPEEDING].map(({ value, color }) => (value ? [color, value] : [color])).flat(),
    ],
    FCD_LEGEND[FcdLayerType.ROUTING_NET][0].color,
  ],
  [FcdLayerType.SPEEDING_LOS]: [
    'case',
    ['to-boolean', ['feature-state', 'los']],
    [
      'step',
      ['feature-state', 'los'],
      ...FCD_LEGEND[FcdLayerType.SPEEDING_LOS]
        .map(({ value, color }) => (value ? [color, value] : [color]))
        .flat()
        .reverse(),
    ],
    FCD_LEGEND[FcdLayerType.ROUTING_NET][0].color,
  ],
  [FcdLayerType.SLOW_DOWN]: [
    'case',
    ['to-boolean', ['feature-state', 'slowdown']],
    [
      'step',
      ['feature-state', 'slowdown'],
      ...FCD_LEGEND[FcdLayerType.SLOW_DOWN].map(({ value, color }) => (value ? [color, value] : [color])).flat(),
    ],
    FCD_LEGEND[FcdLayerType.ROUTING_NET][0].color,
  ],
  [FcdLayerType.ROUTING_NET]: FCD_LEGEND[FcdLayerType.ROUTING_NET][0].color,
};

export function FcdLayers() {
  const fcdHighlightId = useMapStore((state) => state.fcdHighlightId);
  const fcdDetailCardFeature = useMapContext((state) => state.fcdDetailCardFeature);

  const { fcd: fcdLayerType } = useMapContext((state) => state.layers);

  const analysisMatch = useMatch('/karte/fcd-verkehrsanalyse');

  useFcdLive();
  useFcdHistory();

  return (
    <Source id={FCD_LAYER} type="vector" url={`${MARTIN_URL}/routing_net`} promoteId="unique_gid">
      {fcdLayerType !== FcdLayerType.NONE && (
        <>
          {!analysisMatch && (
            <LineHighlightLayers
              source={FCD_LAYER}
              sourceLayer={ROUTING_NET_SOURCE_LAYER}
              highlightId={fcdHighlightId || fcdDetailCardFeature?.properties.id}
              lineWidthBorder={['interpolate', ['exponential', 0.5], ['zoom'], 10, 8, 14, 10, 18, 16]}
              lineWidthWhite={['interpolate', ['exponential', 0.5], ['zoom'], 10, 4, 14, 8, 18, 14]}
            />
          )}

          <Layer
            key={FCD_LAYER}
            id={FCD_LAYER}
            type="line"
            source={FCD_LAYER}
            source-layer={ROUTING_NET_SOURCE_LAYER}
            beforeId={MapBefore.CONTENT}
            layout={{
              'line-cap': 'round',
              'line-join': 'round',
            }}
            paint={{
              'line-color': FCD_LINE_COLOR[fcdLayerType],
              'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 2, 14, 4, 18, 8],
              'line-offset': ROUTING_NET_LINE_OFFSET,
            }}
          />
        </>
      )}
    </Source>
  );
}
