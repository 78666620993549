import {
  Box,
  Stack,
  Typography,
  styled,
  css,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { ReactElement } from 'react';

import { transientOptions } from '@/utils/transient-options';

const Root = styled(Paper)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    max-width: ${theme.spacing(22)};
    margin-left: auto;
  `,
);

const StyledBox = styled(
  Box,
  transientOptions,
)<{ $color: string; $border?: boolean }>(
  ({ theme, $color, $border }) => css`
    width: ${theme.spacing(3)};
    height: ${theme.spacing(1)};
    background-color: ${$color};
    display: flex;
    align-items: center;

    ${$border &&
    css`
      box-sizing: content-box;
      margin: -1px;
      border: 1px solid ${theme.palette.secondary.main};
    `}
  `,
);

const StyledTableRow = styled(TableRow)(
  ({ theme }) => css`
    &:hover {
      .MuiTableCell-root {
        background-color: ${theme.palette.divider};
      }
    }
  `,
);

type LegendProps = {
  title: string | ReactElement;
  titleTooltip?: string;
  legend: Readonly<{ value?: number; label?: string; color: string; text?: string; border?: boolean }[]>;
};

export function LegendControl({ title, titleTooltip, legend }: LegendProps) {
  return (
    <Root>
      <Stack>
        {titleTooltip ? (
          <Tooltip title={titleTooltip} placement="left">
            <Typography fontWeight="bold" mb={1}>
              {title}
            </Typography>
          </Tooltip>
        ) : (
          <Typography fontWeight="bold" mb={1}>
            {title}
          </Typography>
        )}

        <Table size="small">
          <TableBody>
            {legend.map(({ value, label, color, text, border }) => {
              if (!(label || text)) {
                return null;
              }

              const key = label || value;
              const row = (
                <StyledTableRow key={key}>
                  <TableCell padding="none" sx={{ borderBottom: 'none', paddingLeft: 0.5 }}>
                    <StyledBox $color={color} $border={border} />
                  </TableCell>
                  <TableCell padding="none" sx={{ textAlign: 'right', borderBottom: 'none', paddingRight: 0.5 }}>
                    <Typography fontSize="0.8rem">{label || value}</Typography>
                  </TableCell>
                </StyledTableRow>
              );

              return text ? (
                <Tooltip key={key} title={text} placement="left">
                  {row}
                </Tooltip>
              ) : (
                row
              );
            })}
          </TableBody>
        </Table>
      </Stack>
    </Root>
  );
}
