import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/modules/QueryKey';
import { HotspotsService } from '@/modules/HotspotsService';

export const useHotspotEventsQuery = (parameters?: Parameters<typeof HotspotsService.getHotspotEvents>[0]) =>
  useQuery({
    queryKey: QueryKey.hotspotEvents.get(parameters),
    queryFn: () => HotspotsService.getHotspotEvents(parameters),
    refetchOnWindowFocus: false,
  });
