import { css, DialogTitle, IconButton, Stack, styled } from '@mui/material';
import { ReactNode } from 'react';

import CrossCircleIcon from '@/assets/icons/analysis/cross-circle.svg?react';

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
  `,
);

type DialogTitleWithCloseProps = { children: ReactNode; onClose: () => void };

export function DialogTitleWithClose({ children, onClose }: DialogTitleWithCloseProps) {
  return (
    <DialogTitle textTransform="uppercase">
      <Stack justifyContent="space-between" alignItems="center" direction="row" sx={{ minHeight: 36 }}>
        <span>{children}</span>

        <StyledIconButton onClick={onClose} aria-label="Schließen">
          <CrossCircleIcon />
        </StyledIconButton>
      </Stack>
    </DialogTitle>
  );
}
