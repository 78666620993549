import { Typography, Stack, Dialog, DialogContent, Button } from '@mui/material';
import { ReactNode } from 'react';

import { DialogTitleWithClose } from '@/components/dialog/DialogTitleWithClose';

type DeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  children: ReactNode;
};

export function DeleteDialog({ onSubmit, onClose, title, open, children }: DeleteDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitleWithClose onClose={onClose}>{`${title} löschen`}</DialogTitleWithClose>
      <DialogContent sx={{ padding: 3 }}>
        <Typography textAlign="center">{`ACHTUNG! Gelöschte ${title}n können nicht wiederhergestellt werden.`}</Typography>
        {children}

        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button color="secondary" variant="outlined" onClick={onClose}>
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              onSubmit();
              onClose();
            }}
            sx={{ backgroundColor: '#FB8849' }}
            variant="contained"
          >
            Löschen
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
