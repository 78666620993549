import { Divider, FormGroup } from '@mui/material';

import { FcdLayerType } from '@/types/FcdLayerType';
import { LayerControlRadio } from '@/monitoring/components/LayerControlRadio';
import { useMapContext } from '@/stores/useMapContext';

export function FcdLayerTypeControl() {
  const layers = useMapContext((state) => state.layers);
  const { setLayers } = useMapContext((state) => state.actions);

  return (
    <FormGroup>
      {(
        [
          FcdLayerType.LEVEL_OF_SERVICE,
          FcdLayerType.CONFIDENCE_VALUE,
          FcdLayerType.SPEEDING,
          FcdLayerType.SPEEDING_LOS,
          FcdLayerType.SLOW_DOWN,
          FcdLayerType.ROUTING_NET,
        ] as Exclude<FcdLayerType, FcdLayerType.NONE>[]
      ).map((layer) => (
        <LayerControlRadio
          key={layer}
          value={layer}
          checked={layer === layers.fcd}
          onChange={(checked) => {
            if (checked) {
              setLayers({ fcd: layer });
            }
          }}
          i18nKey="FcdLayerType"
          tooltip={
            {
              [FcdLayerType.LEVEL_OF_SERVICE]: undefined,
              [FcdLayerType.CONFIDENCE_VALUE]: undefined,
              [FcdLayerType.SPEEDING]: 'In Echtzeit auf Basis der aktuellen Durchschnittsgeschwindigkeit',
              [FcdLayerType.SPEEDING_LOS]:
                'In Echtzeit auf Basis der aktuellen Durchschnittsgeschwindigkeit & Freiflussgeschwindigkeit',
              [FcdLayerType.SLOW_DOWN]: 'Im unbelasteten Netz auf Basis der Freiflussgeschwindigkeit',
              [FcdLayerType.ROUTING_NET]: undefined,
            }[layer]
          }
        />
      ))}

      <Divider />
    </FormGroup>
  );
}
