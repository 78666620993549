import dayjs, { Dayjs } from 'dayjs';

import { AnalysisType } from '@/types/AnalysisType';

const AVAILABLE_HISTORICAL_DATA: Record<
  AnalysisType,
  {
    minDate: dayjs.Dayjs;
    maxDate?: dayjs.Dayjs;
  }[]
> = {
  [AnalysisType.FCD]: [
    {
      minDate: dayjs('2018-01-01').startOf('day'),
      maxDate: dayjs('2019-12-31').endOf('day'),
    },
    {
      minDate: dayjs('2023-01-01').startOf('day'),
    },
  ],
  [AnalysisType.DETECTOR]: [
    {
      minDate: dayjs('2023-09-15').startOf('day'),
    },
  ],
};

export class HistoricalDateRanges {
  private static findRangeForDate({ type, date }: { type: AnalysisType; date: Dayjs }) {
    return AVAILABLE_HISTORICAL_DATA[type].find(
      (range) => date.isSameOrAfter(range.minDate) && (!range.maxDate || date.isSameOrBefore(range.maxDate)),
    );
  }

  private static findRangeForYear({ type, year }: { type: AnalysisType; year: Dayjs }) {
    return AVAILABLE_HISTORICAL_DATA[type].find(
      (range) =>
        year.isSameOrAfter(range.minDate, 'year') && (!range.maxDate || year.isSameOrBefore(range.maxDate, 'year')),
    );
  }

  public static getMinDate({ type }: { type: AnalysisType }) {
    return AVAILABLE_HISTORICAL_DATA[type][0].minDate;
  }

  public static getAvailableYears({ type }: { type: AnalysisType }) {
    const firstYear = HistoricalDateRanges.getMinDate({ type }).get('year');

    return [...Array(dayjs().get('year') - firstYear + 1).keys()]
      .map((year) => year + firstYear)
      .filter((year) => HistoricalDateRanges.isYearAvailable({ type, year: dayjs().year(year).startOf('year') }));
  }

  public static getYearMinDate({ type, year }: { type: AnalysisType; year: Dayjs }) {
    const minDate = HistoricalDateRanges.findRangeForYear({ type, year })?.minDate;

    return minDate?.get('year') === year.get('year') ? minDate : year.startOf('year');
  }

  public static isYearAvailable({ type, year }: { type: AnalysisType; year: Dayjs }) {
    return !!HistoricalDateRanges.findRangeForYear({ type, year });
  }

  public static isDateAvailable({ type, date }: { type: AnalysisType; date: Dayjs }) {
    return !!HistoricalDateRanges.findRangeForDate({ type, date });
  }
}
