import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawModeChangeEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import { forwardRef, useImperativeHandle } from 'react';
import { ControlPosition, useControl } from 'react-map-gl';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onCreate?: (event: DrawCreateEvent) => void;
  onUpdate?: (event: DrawUpdateEvent) => void;
  onDelete?: (event: DrawDeleteEvent) => void;
  onModeChange?: (event: DrawModeChangeEvent) => void;
};

export const DrawControl = forwardRef<MapboxDraw, DrawControlProps>((props: DrawControlProps, ref) => {
  const { onCreate, onUpdate, onDelete, onModeChange } = props;

  const drawRef = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    ({ map }) => {
      if (onCreate) map.on('draw.create', onCreate);
      if (onUpdate) map.on('draw.update', onUpdate);
      if (onDelete) map.on('draw.delete', onDelete);
      if (onModeChange) map.on('draw.modechange', onModeChange);
    },
    ({ map }) => {
      if (onCreate) map.off('draw.create', onCreate);
      if (onUpdate) map.off('draw.update', onUpdate);
      if (onDelete) map.off('draw.delete', onDelete);
      if (onModeChange) map.off('draw.modechange', onModeChange);
    },
    {
      position: props?.position,
    },
  );

  useImperativeHandle(ref, () => drawRef, [drawRef]);

  return null;
});
