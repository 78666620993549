import { Box, Button, css, IconButton, Stack, styled, Typography } from '@mui/material';
import { useMemo } from 'react';
import { pointsWithinPolygon } from '@turf/turf';

import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { DetectorPopupActions } from '@/detector-analysis/components/DetectorPopupActions';
import CrossCircleIcon from '@/assets/icons/analysis/cross-circle.svg?react';
import { useDetectorsQuery } from '@/detector-analysis/queries/useDetectorsQuery';
import { DetectorsFeatureCollection } from '@/types/DetectorFeature';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing(3.5)};
    left: 50%;
    transform: translateX(-50%);
    background-color: ${theme.palette.common.white};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius}px;
    box-shadow: ${theme.shadows[1]};
    min-width: ${theme.spacing(50)};
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(2)};
  `,
);

export function DetectorDrawBottomControl() {
  const mapboxDraw = useDetectorAnalysisStore((state) => state.mapboxDraw);
  const drawnPolygon = useDetectorAnalysisStore((state) => state.drawnPolygon);
  const { setIsDrawing, setDrawnPolygon } = useDetectorAnalysisStore((state) => state.actions);

  const { data } = useDetectorsQuery();

  const handleFinish = () => {
    mapboxDraw?.current?.deleteAll();
    setIsDrawing(false);
    setDrawnPolygon(undefined);
  };

  const selectedDetectors = useMemo(() => {
    if (drawnPolygon && data) {
      return pointsWithinPolygon(data, drawnPolygon) as DetectorsFeatureCollection;
    }

    return undefined;
  }, [data, drawnPolygon]);

  return (
    <StyledBox>
      <Stack mb={1}>
        <Typography variant="h2">Detektorenauswahl</Typography>
        <StyledIconButton onClick={handleFinish} aria-label="Schließen">
          <CrossCircleIcon />
        </StyledIconButton>
      </Stack>

      {!drawnPolygon && (
        <Typography color="#7c8586" mt={1}>
          Zeichnen sie mindestens drei Punkte. Die Auswahl kann auch mit einem Doppelklick beendet werden.
        </Typography>
      )}

      {drawnPolygon && selectedDetectors && selectedDetectors.features.length < 1 && (
        <Typography>Es befinden sich keine Detektoren in der Auswahl.</Typography>
      )}

      <Stack direction="row" mt={2} justifyContent="center">
        {drawnPolygon ? (
          <DetectorPopupActions detectors={selectedDetectors?.features || []} onClick={handleFinish} />
        ) : (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              mapboxDraw.current?.changeMode('simple_select');

              const geometry = mapboxDraw.current?.getAll().features[0]?.geometry;

              if (geometry && geometry.type === 'Polygon') {
                setDrawnPolygon(geometry);
              } else {
                setIsDrawing(false);
              }
            }}
          >
            Fertigstellen
          </Button>
        )}
      </Stack>
    </StyledBox>
  );
}
