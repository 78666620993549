import { ListItem, ListItemText, Typography } from '@mui/material';

import { StyledList } from '@/components/StyledList';

export function DateTooltip() {
  return (
    <>
      <Typography>
        Interaktion in der <strong>Zeitauswahl</strong>
      </Typography>
      <StyledList>
        <ListItem>
          <ListItemText>
            Wahl des Zeitraums über den Kalender. Daten können zusätzlich über das Eingabefeld bearbeitet werden.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Es können bis zu 3 Zeiträume gewählt werden. Die Länge der weiteren Zeiträume orientieren sich am ersten
            Zeitraum.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Die Tagesauswahl erscheint, wenn der gewählte Zeitraum 7 Tage oder mehr beträgt. </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Die manuelle Tagesauswahl erlaubt das selektieren einzelner Tage.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Der Beobachtungszeitraum wird im Zeitstrahl gewählt.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Die Optionen des Aggregationsintervalls sind adaptiv und orientieren sich an der Länge des gewählten
            Zeitraums.
          </ListItemText>
        </ListItem>
      </StyledList>
    </>
  );
}
