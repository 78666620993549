import { useState } from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { useThrottle } from '@/hooks/useThrottle';
import { Geocoder } from '@/modules/Geocoder';
import { LocationFeature } from '@/types/LocationFeature';
import { RouteLocationFeature } from '@/types/RouteLocationFeature';
import { RouteLocationType } from '@/types/RouteLocationType';

type GeocodingSearchInputProps = {
  label: string;
  routeId: string;
  routeLocationType: RouteLocationType;
  routeLocation: RouteLocationFeature | null;
  routeLocationIndex?: number;
};

export function GeocodingSearchInput({
  label,
  routeId,
  routeLocationType,
  routeLocation,
  routeLocationIndex,
}: GeocodingSearchInputProps) {
  const validateForm = useFcdAnalysisStore((state) => state.validateForm);
  const { setRouteLocation, removeRouteLocation, setRouteHighlightId } = useFcdAnalysisStore((state) => state.actions);

  const [inputValue, setInputValue] = useState('');
  const throttledInputValue = useThrottle(inputValue, 300);
  const [focus, setFocus] = useState(false);
  const [highlight, setHighlight] = useState<LocationFeature | null>(null);

  const queryParams = { q: throttledInputValue };
  const { data: options, isLoading } = useQuery({
    queryKey: ['geocoder', queryParams],
    queryFn: () => Geocoder.geocoding(queryParams),
    enabled: focus && throttledInputValue !== '',
    placeholderData: keepPreviousData,
  });

  const optionsIncludeValue =
    routeLocation && options && options.find((option) => Geocoder.areSameLocationFeatures(option, routeLocation));
  const autocompleteOptions = [...(options || [])];
  if (routeLocation && !optionsIncludeValue) {
    autocompleteOptions.unshift(routeLocation as LocationFeature);
  }

  const handleChange = (location: LocationFeature | null) => {
    if (location) {
      if (routeLocationType === RouteLocationType.DESTINATION || routeLocationType === RouteLocationType.ORIGIN) {
        setRouteLocation({ routeId, location, routeLocationType });
      } else if (routeLocationIndex !== undefined) {
        setRouteLocation({ routeId, location, routeLocationType, index: routeLocationIndex });
      }
    } else if (routeLocation) {
      removeRouteLocation({ routeId, routeLocationId: routeLocation.id });
      setRouteHighlightId(routeId);
    }
  };

  return (
    <Autocomplete<LocationFeature>
      getOptionLabel={(feature) => feature.properties.name?.full || ''}
      isOptionEqualToValue={Geocoder.areSameLocationFeatures}
      options={autocompleteOptions}
      autoComplete
      blurOnSelect
      includeInputInList
      loading={throttledInputValue !== '' && isLoading}
      autoSelect
      filterSelectedOptions={!optionsIncludeValue}
      filterOptions={(x) => x}
      forcePopupIcon={false}
      defaultValue={null}
      value={(routeLocation as LocationFeature) || null}
      onChange={(event, newLocation) => {
        handleChange(newLocation);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onHighlightChange={(event, option) => {
        setHighlight(option);
      }}
      onKeyDown={(event) => {
        if (options && event.key === 'Enter' && event.currentTarget.querySelector('input') === document.activeElement) {
          handleChange(options[0]);
          event.currentTarget.querySelector('input')?.blur();
        }

        if (event.key === 'Tab') {
          const location = highlight || options?.[0];

          if (location && inputValue !== location.properties.name?.firstLine) {
            event.preventDefault();
            setInputValue(location.properties.name?.firstLine || '');
          }
        }
      }}
      onFocus={() => {
        setFocus(true);
        setRouteHighlightId(routeId);
      }}
      onBlur={() => setFocus(false)}
      renderInput={(params) => (
        <TextField
          error={validateForm && !routeLocation}
          sx={{ marginTop: 1 }}
          variant="outlined"
          placeholder={label}
          {...params}
          fullWidth
          size="small"
          label={label}
        />
      )}
      renderOption={(props, location) => {
        const key = `${location.properties.osmId}-${location.properties.osmKey}`;

        return (
          <li {...props} key={key}>
            <Box>
              {location.properties.name?.firstLine}
              {location.properties.name?.secondLine && (
                <Box sx={{ fontSize: '0.75rem', lineHeight: 1.1 }}>{location.properties.name?.secondLine}</Box>
              )}
            </Box>
          </li>
        );
      }}
    />
  );
}
