import { DetectorFeature } from '@/types/DetectorFeature';

const getDetectorGroupLengthLabel = (detectors: DetectorFeature[]) => `${detectors.length} Detektoren`;

export const getDetectorGroupLocationDescription = (detectors: DetectorFeature[]) => {
  if (detectors.length > 8) {
    return getDetectorGroupLengthLabel(detectors);
  }

  const groupedByLocation = detectors.reduce(
    (groups: { [key: string]: string[] }, { properties: { location_description: locationDescription, id } }) => {
      if (locationDescription) {
        groups[locationDescription] = groups[locationDescription] || [];
        groups[locationDescription].push(id);
      }

      return groups;
    },
    {},
  );

  if (Object.entries(groupedByLocation).length > 4) {
    return getDetectorGroupLengthLabel(detectors);
  }

  return Object.entries(groupedByLocation)
    .map(([location, ids]) => `${location} (${ids.join(', ')})`)
    .join(', ');
};
