import { ListItem, ListItemText, Typography } from '@mui/material';

import { StyledList } from '@/components/StyledList';

export function ContextTooltip() {
  return (
    <>
      <Typography>
        Interaktion in der <b>Kontextauswahl</b>
      </Typography>
      <StyledList>
        <ListItem>
          <ListItemText>Kontextleiste ein- oder ausblenden</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Es können bis zu 5 Einzelkontexte angezeigt werden</ListItemText>
        </ListItem>
      </StyledList>
    </>
  );
}
