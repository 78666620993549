import { css, FormControlLabel, Radio, RadioGroup, styled, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Database } from '@/types/Supabase';
import { QueryKey } from '@/modules/QueryKey';
import { supabase } from '@/setup/supabaseClient';
import { DetectorDeleteDialog } from '@/detector-analysis/components/DetectorDeleteDialog';
import { LoadDialog } from '@/components/dialog/LoadDialog';
import { LoadDialogTable } from '@/components/dialog/LoadDialogTable';
import { DetectorGroupFeature } from '@/types/DetectorGroupFeature';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { useDetectorsQuery } from '@/detector-analysis/queries/useDetectorsQuery';
import { getDetectorGroupLocationDescription } from '@/detector-analysis/modules/getDetectorGroupLocationDescription';

const StyledTableCell = styled(TableCell)(
  () => css`
    text-align: center;
  `,
);

type DetectorLoadDialogProps = {
  detectorGroup?: DetectorGroupFeature;
  onClose: () => void;
  index: number;
};

export function DetectorLoadDialog({ detectorGroup, onClose, index }: DetectorLoadDialogProps) {
  const [selectedDetectorGroup, setSelectedDetectorGroup] =
    useState<Database['public']['Tables']['user_query_detector_group']['Row']>();
  const [detectorDeleteDialog, setDetectorDeleteDialog] = useState(false);
  const { updateDetectorGroup } = useDetectorAnalysisStore((state) => state.actions);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data } = useDetectorsQuery();

  const handlePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleDeleteDialog = (deleteDialog: boolean) => {
    setDetectorDeleteDialog(deleteDialog);
  };
  const { data: { data: detectors, count: detectorsCount } = {}, refetch } = useQuery({
    queryKey: QueryKey.detectors.getUserDetectorsPaginated({ page, rowsPerPage }),
    queryFn: async () =>
      supabase
        .from('user_query_detector_group')
        .select('*', { count: 'exact' })
        .range(page * rowsPerPage, (page + 1) * rowsPerPage - 1)
        .throwOnError(),
  });

  useEffect(() => {
    if (!selectedDetectorGroup) {
      setSelectedDetectorGroup(undefined);
    }
  }, [selectedDetectorGroup]);

  const handleDetectorLoad = async () => {
    if (detectorGroup && detectors && selectedDetectorGroup) {
      detectorGroup.properties.detectors = {
        type: 'FeatureCollection',
        features: await Promise.all(
          selectedDetectorGroup.detectors.map((id) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [0, 0],
            },
            properties: {
              id,
              last_update: selectedDetectorGroup.updated_at,
              location_description: '',
              measurement_interval: null,
              type: null,
              name: '',
            },
          })),
        ),
      };

      detectorGroup.geometry = null;
      detectorGroup.properties.name = selectedDetectorGroup?.name as string;

      updateDetectorGroup(detectorGroup, index);
    }
  };

  return (
    <>
      <LoadDialog
        open={!!detectorGroup}
        data={detectors as Database['public']['Tables']['user_query_detector_group']['Row'][]}
        onClose={onClose}
        title="Detektorgruppen"
        page={page}
        dataCount={detectorsCount}
        rowsPerPage={rowsPerPage}
        selectedData={selectedDetectorGroup as Database['public']['Tables']['user_query_detector_group']['Row']}
        onDeleteDialog={handleDeleteDialog}
        onPage={handlePage}
        onRowsPerPage={handleRowsPerPage}
        onLoad={handleDetectorLoad}
      >
        <LoadDialogTable columns={['Name', 'Erstellt am', 'Bearbeitet am', 'Detektoren']}>
          {detectors?.map((detectorItem) => {
            const filteredDetectorItems = data?.features.filter((feature) =>
              detectorItem.detectors.includes(feature.properties.id),
            );

            return (
              <TableRow
                key={detectorItem.detector_group_id}
                sx={{ borderBottom: (theme) => `2px solid ${theme.palette.secondary.main}` }}
              >
                <TableCell>
                  <RadioGroup
                    name="detector-selection"
                    value={selectedDetectorGroup?.detector_group_id || null}
                    onChange={(event, value) => {
                      setSelectedDetectorGroup(detectors.find((detectorEl) => detectorEl.detector_group_id === value));
                    }}
                  >
                    <FormControlLabel
                      value={detectorItem.detector_group_id}
                      control={<Radio />}
                      label={detectorItem.name}
                    />
                  </RadioGroup>
                </TableCell>

                <StyledTableCell>{dayjs(detectorItem.created_at).format('DD.MM.YYYY')}</StyledTableCell>
                <StyledTableCell>{dayjs(detectorItem.updated_at).format('DD.MM.YYYY')}</StyledTableCell>
                <StyledTableCell>
                  {filteredDetectorItems && getDetectorGroupLocationDescription(filteredDetectorItems)}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </LoadDialogTable>
      </LoadDialog>

      <DetectorDeleteDialog
        detectorGroup={detectorDeleteDialog ? selectedDetectorGroup : undefined}
        onClose={() => setDetectorDeleteDialog(false)}
        onDeleteSuccess={() => {
          refetch();
          setSelectedDetectorGroup(undefined);
        }}
      />
    </>
  );
}
