import { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { css, ListItemIcon, MenuItem, MenuList, styled } from '@mui/material';

import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { RouteLocationType } from '@/types/RouteLocationType';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import CrossCircleIcon from '@/assets/icons/analysis/cross-circle.svg?react';
import { Geocoder } from '@/modules/Geocoder';
import { RouteIcon } from '@/fcd-analysis/components/RouteIcon';

const StyledListItemIcon = styled(ListItemIcon)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2)};
  `,
);

export function RouteLocationMarkers() {
  const routes = useFcdAnalysisStore((state) => state.routes);
  const { setRouteLocation, removeRouteLocation, setRouteHighlightId } = useFcdAnalysisStore((state) => state.actions);

  const [popup, setPopup] = useState<{ lat: number; lng: number; routeId: string; routeLocationId: string }>();
  const [dragging, setDragging] = useState(false);

  return (
    <>
      {routes.features.map((route, routeIndex) =>
        route.properties.routeLocations.features.map((routeLocation, routeLocationIndex) => {
          const { routeLocationType } = routeLocation.properties;

          return (
            <Marker
              key={routeLocation.id}
              longitude={routeLocation.geometry.coordinates[0]}
              latitude={routeLocation.geometry.coordinates[1]}
              anchor={routeLocationType === RouteLocationType.VIA ? 'center' : 'bottom'}
              draggable
              onDragStart={() => {
                setDragging(true);
                setPopup(undefined);
              }}
              onDragEnd={async (event) => {
                setTimeout(() => setDragging(false), 100);

                const location = await Geocoder.reverse({
                  type: 'Point',
                  coordinates: [event.lngLat.lng, event.lngLat.lat],
                });

                setRouteLocation({
                  routeId: route.id,
                  location,
                  ...(routeLocationType === RouteLocationType.VIA
                    ? {
                        routeLocationType,
                        index: routeLocationIndex,
                      }
                    : {
                        routeLocationType,
                      }),
                });
              }}
              onClick={(event) => {
                event.originalEvent.stopPropagation();

                if (!dragging && routeLocation.geometry)
                  setPopup({
                    lng: routeLocation.geometry.coordinates[0],
                    lat: routeLocation.geometry.coordinates[1],
                    routeId: route.id,
                    routeLocationId: routeLocation.id,
                  });
                setRouteHighlightId(route.id);
              }}
            >
              <RouteIcon type={routeLocationType} color={ROUTE_COLOR[routeIndex]} width={28} height="auto" />
            </Marker>
          );
        }),
      )}

      {popup && (
        <Popup
          latitude={popup.lat}
          longitude={popup.lng}
          offset={40}
          closeButton={false}
          closeOnMove
          closeOnClick
          maxWidth="min(480px, 75vw)"
          onClose={() => setPopup(undefined)}
        >
          <MenuList sx={{ width: 140 }}>
            <MenuItem
              onClick={() => {
                removeRouteLocation({ routeId: popup.routeId, routeLocationId: popup.routeLocationId });
                setPopup(undefined);
                setRouteHighlightId(popup.routeId);
              }}
            >
              <StyledListItemIcon>
                <CrossCircleIcon />
              </StyledListItemIcon>
              Löschen
            </MenuItem>
          </MenuList>
        </Popup>
      )}
    </>
  );
}
