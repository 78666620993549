import dayjs, { Dayjs } from 'dayjs';

import { PG_FEATURESERV } from '@/setup/pg-featureserv';
import { ContextType } from '@/types/ContextType';
import { TempusService } from '@/modules/api/services/TempusService';
import { ContextFeature, ContextsFeatureCollection } from '@/types/ContextFeature';

const CONTEXT_ENDPOINT = {
  [ContextType.STRATEGY]: 'strategy',
  [ContextType.OBSTRUCTION]: 'obstruction',
  [ContextType.CONSTRUCTION]: undefined,
  [ContextType.HOLIDAY]: 'holidays',
  [ContextType.NOTIFICATION]: undefined,
  [ContextType.CALENDAR]: undefined,
};

export class ContextService {
  public static async getContexts(
    type: ContextType,
    filter: { from: Dayjs | null; to: Dayjs | null },
  ): Promise<ContextsFeatureCollection> {
    if (filter.from === null || filter.to === null) return { features: [], type: 'FeatureCollection' };

    if (!CONTEXT_ENDPOINT[type]) throw new Error(`No context endpoint for ${type}`);

    if (type === ContextType.HOLIDAY) {
      const response = await TempusService.getHolidays({
        requestBody: {
          startDate: filter.from.format('YYYY-MM-DD'),
          endDate: filter.to.format('YYYY-MM-DD'),
        },
      });

      if (!response.results) {
        throw new Error(`Failed to fetch data for ${type}`);
      }

      return {
        type: 'FeatureCollection',
        features: response.results.map((holiday) => {
          const id = `${holiday.type}-${holiday.startDate}-${holiday.endDate}-${holiday.name}`;

          return {
            type: 'Feature',
            id,
            properties: {
              id,
              contextType: type,
              holidayType: holiday.type,
              name: holiday.name,
              valid_from: dayjs(holiday.startDate).startOf('day').toISOString(),
              valid_to: dayjs(holiday.endDate).endOf('day').toISOString(),
            },
            geometry: null,
          };
        }),
      };
    }

    const urlSearchParams = new URLSearchParams({
      filter: `(valid_from IS NULL OR valid_from <= '${filter.from}') AND (valid_to IS NULL OR valid_to >= '${filter.to}')`,
    });

    const response = await fetch(
      `${PG_FEATURESERV}/collections/frontend.${CONTEXT_ENDPOINT[type]}/items.json?${urlSearchParams || ''}`,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch data for ${type}`);
    }

    const featureCollection = (await response.json()) as ContextsFeatureCollection;

    return {
      type: 'FeatureCollection',
      features: featureCollection.features.map(
        (feature) =>
          ({
            ...feature,
            id: feature.properties.id,
            properties: {
              ...feature.properties,
              contextType: type,
            },
          }) as ContextFeature,
      ),
    };
  }
}
