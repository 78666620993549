import { Stack, css, styled } from '@mui/material';
import { ReactNode } from 'react';

const StyledStack = styled(Stack)(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    overflow: scroll;
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(3.5)};

    & > * {
      pointer-events: auto;
    }
  `,
);

type BottomControlsProps = { children: ReactNode };

export function BottomControls({ children }: BottomControlsProps) {
  return (
    <StyledStack direction="row" alignItems="flex-end">
      {children}
    </StyledStack>
  );
}
