import { Box, ListItem, ListItemText, Typography } from '@mui/material';

import { StyledList } from '@/components/StyledList';
import { AnalysisType } from '@/types/AnalysisType';

type LocationsTooltipProps = { analysisType: AnalysisType };

export function LocationsTooltip({ analysisType }: LocationsTooltipProps) {
  const analysis = analysisType === AnalysisType.FCD ? 'Strecke' : 'Detektorgruppe';

  return (
    <Box>
      <Typography>
        Interaktion in der <strong>{`${analysis}nauswahl`}</strong>
      </Typography>
      <StyledList>
        <ListItem>
          <ListItemText>
            {`Das Speichern, Laden und Löschen von ${analysis}n ist über das ${analysis}auswahlmenü auf der linken Seite möglich`}
            .
          </ListItemText>
        </ListItem>
        {analysisType === AnalysisType.FCD && (
          <>
            <ListItem>
              <ListItemText>
                Start, Via sowie Zielpunkte können mittels rechter Maustaste zusätzlich direkt in der Karte gewählt
                werden.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>Die Routenberechnung geschieht automatisch im Hintergrund.</ListItemText>
            </ListItem>
          </>
        )}

        <ListItem>
          <ListItemText>{`Es können bis zu 3 ${analysis}n gleichzeitig gewählt und analysiert werden.`}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {`Es kann jeweils nur eine ${analysis} zur selben Zeit bearbeitet werden. Diese ${analysis} wird in der Seitenleiste
          farblich hervorgehoben.`}
          </ListItemText>
        </ListItem>
      </StyledList>
    </Box>
  );
}
