import { ReactNode } from 'react';
import { Box, Collapse, css, styled } from '@mui/material';

import { SidebarListItemButton } from '@/components/sidebar/SidebarListItemButton';

const StyledCollapse = styled(Collapse)(
  () => css`
    overflow: scroll;
  `,
);

type CollapseSidebarItemProps = {
  title: string;
  children?: ReactNode;
  chip?: ReactNode;
  index: number;
  onMenuOpenIndex: (index: number) => void;
  openMenuIndex: number;
  toolTip?: ReactNode;
  numberOfSidebarItems: number;
  actionsHeight?: number;
};

export function CollapseSidebarItem({
  title,
  children,
  chip,
  index,
  onMenuOpenIndex,
  openMenuIndex,
  toolTip,
  numberOfSidebarItems,
  actionsHeight,
}: CollapseSidebarItemProps) {
  return (
    <>
      <SidebarListItemButton
        open={index === openMenuIndex}
        onClick={() => onMenuOpenIndex(index === openMenuIndex ? -1 : index)}
        toolTip={toolTip}
        chip={chip}
      >
        {title}
      </SidebarListItemButton>

      <StyledCollapse in={index === openMenuIndex} unmountOnExit>
        <Box
          sx={{
            height: `calc(100vh - (${numberOfSidebarItems} * 49px) - 2px${
              actionsHeight ? ` - ${actionsHeight}px` : ''
            })`,
          }}
        >
          {children}
        </Box>
      </StyledCollapse>
    </>
  );
}
