import { Layer, Source, useMap } from 'react-map-gl';
import { useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { GeoJSONSource, MapLayerEventType } from 'mapbox-gl';

import { useMapImages } from '@/map/hooks/useMapImages';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { useDetectorsQuery } from '@/detector-analysis/queries/useDetectorsQuery';
import { DetectorFeature, DetectorsFeatureCollection } from '@/types/DetectorFeature';
import { useMapContext } from '@/stores/useMapContext';
import { DetectorPolygonFeature } from '@/types/DetectorPolygonFeature';
import DetectorIcon from '@/assets/icons/detector.svg?url';

export const DETECTORS_CLUSTER_SOURCE = 'DETECTORS_CLUSTER';
export const DETECTORS_CLUSTER_CIRCLE_LAYER = `${DETECTORS_CLUSTER_SOURCE}_CIRCLE_LAYER`;
export const DETECTORS_CLUSTER_COUNT_LAYER = `${DETECTORS_CLUSTER_SOURCE}_COUNT_LAYER`;
export const DETECTORS_CLUSTER_ICON_LAYER = `${DETECTORS_CLUSTER_SOURCE}_ICON_LAYER`;

export const DETECTORS_SELECTED_SOURCE = `DETECTORS_SELECTED`;
export const DETECTORS_SELECTED_ICON_LAYER = `${DETECTORS_SELECTED_SOURCE}_ICON_LAYER`;
export const DETECTORS_SELECTED_CIRCLE_LAYER = `${DETECTORS_SELECTED_SOURCE}_CIRCLE_LAYER`;

export function DetectorsLayer() {
  const theme = useTheme();
  const map = useMap();

  useMapImages({
    images: [
      {
        name: DETECTORS_CLUSTER_SOURCE,
        url: DetectorIcon,
        width: 36,
        height: 36,
      },
    ],
  });

  const detectorGroups = useDetectorAnalysisStore((state) => state.detectorGroups);
  const isDrawing = useDetectorAnalysisStore((state) => state.isDrawing);
  const { setDetectorClusterPopup, setDetectorDetailCardFeature } = useMapContext((state) => state.actions);

  const { setDetectorGroupHighlightId } = useDetectorAnalysisStore((state) => state.actions);

  const { data } = useDetectorsQuery();

  const selectedDetectorIds = detectorGroups.features
    .map((group) => group.properties.detectors.features.map((feature) => feature.properties.id))
    .flat();

  const { selectedDetectors, unselectedDetectors } = useMemo(
    () =>
      (data?.features || []).reduce(
        (collections, detector) => {
          if (selectedDetectorIds.includes(detector.properties.id)) {
            collections.selectedDetectors.features.push(detector);
          } else {
            collections.unselectedDetectors.features.push(detector);
          }

          return collections;
        },
        {
          selectedDetectors: { type: 'FeatureCollection', features: [] } as DetectorsFeatureCollection,
          unselectedDetectors: { type: 'FeatureCollection', features: [] } as DetectorsFeatureCollection,
        },
      ),
    [data?.features, selectedDetectorIds],
  );

  const handleClickCluster = useCallback(
    ({ target, features: [feature] = [] }: MapLayerEventType['click']) => {
      const clusterId = feature?.properties?.cluster_id;

      (target.getSource(DETECTORS_CLUSTER_SOURCE) as GeoJSONSource).getClusterLeaves(
        clusterId,
        1000000,
        0,
        (error, leaves) => {
          const polygonPopupFeature = {
            type: 'Feature',
            geometry: feature.geometry,
            properties: {
              detectors: leaves,
            },
          };

          setDetectorClusterPopup(polygonPopupFeature as DetectorPolygonFeature);
        },
      );
    },
    [setDetectorClusterPopup],
  );

  const handleClickResetDetailCard = useCallback(() => {
    setDetectorDetailCardFeature(undefined);
  }, [setDetectorDetailCardFeature]);

  const handleClickDetailCard = useCallback(
    ({ features: [feature] = [] }: MapLayerEventType['click']) => {
      setDetectorDetailCardFeature(feature as unknown as DetectorFeature);
      const highlightedDetectorGroup = detectorGroups.features.find(
        (group) =>
          group.properties &&
          group.properties.detectors &&
          group.properties.detectors.features.some(
            (detector) => detector.properties && detector.properties.id === feature?.properties?.id,
          ),
      );

      if (highlightedDetectorGroup) {
        setDetectorGroupHighlightId(highlightedDetectorGroup.id);
      }
    },
    [detectorGroups.features, setDetectorDetailCardFeature, setDetectorGroupHighlightId],
  );

  useEffect(() => {
    const mapRef = map.current;

    mapRef?.on('click', DETECTORS_CLUSTER_CIRCLE_LAYER, handleClickCluster);

    mapRef?.on('click', handleClickResetDetailCard);
    mapRef?.on('click', [DETECTORS_CLUSTER_ICON_LAYER, DETECTORS_SELECTED_ICON_LAYER], handleClickDetailCard);

    return () => {
      mapRef?.off('click', DETECTORS_CLUSTER_CIRCLE_LAYER, handleClickCluster);

      mapRef?.off('click', handleClickResetDetailCard);
      mapRef?.off('click', [DETECTORS_CLUSTER_ICON_LAYER, DETECTORS_SELECTED_ICON_LAYER], handleClickDetailCard);
    };
  }, [handleClickCluster, handleClickDetailCard, handleClickResetDetailCard, map]);

  return (
    <>
      <Source
        type="geojson"
        id={DETECTORS_CLUSTER_SOURCE}
        data={unselectedDetectors}
        promoteId="id"
        cluster
        clusterMaxZoom={14}
        clusterRadius={50}
      >
        <Layer
          id={DETECTORS_CLUSTER_CIRCLE_LAYER}
          type="circle"
          filter={['has', 'point_count']}
          layout={{ visibility: isDrawing ? 'none' : 'visible' }}
          paint={{
            'circle-color': theme.palette.neutral.main,
            'circle-radius': ['step', ['get', 'point_count'], 16, 10, 20, 100, 24, 250, 28, 500, 32],
          }}
        />
        <Layer
          id={DETECTORS_CLUSTER_COUNT_LAYER}
          type="symbol"
          filter={['has', 'point_count']}
          layout={{
            visibility: isDrawing ? 'none' : 'visible',
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-allow-overlap': true,
          }}
          paint={{
            'text-color': theme.palette.neutral.contrastText,
          }}
        />
        <Layer
          id={DETECTORS_CLUSTER_ICON_LAYER}
          type="symbol"
          filter={['!', ['has', 'point_count']]}
          layout={{
            visibility: isDrawing ? 'none' : 'visible',
            'icon-image': DETECTORS_CLUSTER_SOURCE,
            'icon-size': ['interpolate', ['linear'], ['zoom'], 0, 0, 10, 0.125, 15, 1],
            'icon-allow-overlap': true,
          }}
        />
      </Source>

      <Source type="geojson" id={DETECTORS_SELECTED_SOURCE} data={isDrawing ? data : selectedDetectors} promoteId="id">
        <Layer
          id={DETECTORS_SELECTED_ICON_LAYER}
          type="symbol"
          layout={{
            'icon-image': DETECTORS_CLUSTER_SOURCE,
            'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.375, 15, 1],
            'icon-allow-overlap': true,
          }}
        />
        <Layer
          id={DETECTORS_SELECTED_CIRCLE_LAYER}
          type="circle"
          beforeId={DETECTORS_SELECTED_ICON_LAYER}
          // Filter is necessary while drawing is ongoing
          filter={['in', ['get', 'id'], ['literal', selectedDetectorIds]]}
          paint={{
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 12, 15, 32],
            'circle-color': [
              'match',
              ['get', 'id'],
              ...(detectorGroups.features.length > 0 && selectedDetectorIds.length > 0
                ? detectorGroups.features.flatMap((group, index) =>
                    group.properties.detectors.features.flatMap((detector) => [
                      detector.properties.id,
                      ROUTE_COLOR[index] ?? '#000000',
                    ]),
                  )
                : ['', '#000000']),
              '#000000',
            ],
            'circle-opacity': 0.5,
          }}
        />
      </Source>
    </>
  );
}
