import { Button, Dialog, DialogContent, Stack, TextField, Typography, MenuItem } from '@mui/material';
import { InvalidateQueryFilters, useMutation } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { Point } from 'geojson';

import { supabase } from '@/setup/supabaseClient';
import { DialogTitleWithClose } from '@/components/dialog/DialogTitleWithClose';
import { Database } from '@/types/Supabase';
import { NumberFormat } from '@/modules/NumberFormat';
import { RouteFeature } from '@/types/RouteFeature';
import { DialogGridComponent } from '@/components/dialog/DialogGridComponent';
import { queryClient } from '@/setup/query-client';
import { DetectorGroupFeature } from '@/types/DetectorGroupFeature';

type DetectorData = {
  name: string;
};

type RouteData = {
  name: string;
  route_category: Database['public']['Enums']['route_category'];
};
type SaveDialogProps = {
  onClose: () => void;
  title: string;
  mutationEndpoint: string;
  initialValues?: RouteFeature | DetectorGroupFeature;
  queryKey: string[];
  additionalData?: { length: number | null | undefined; routing_points: Point[] } | { detectors: string[] | undefined };
};

export function SaveDialog({
  onClose,
  title,
  mutationEndpoint,
  initialValues,
  queryKey,
  additionalData,
}: SaveDialogProps) {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<RouteData | DetectorData>();

  const { mutate } = useMutation({
    mutationFn: async (data: RouteData | DetectorData) =>
      supabase
        .from(mutationEndpoint)
        .insert([
          {
            ...data,
            ...(additionalData || {}),
          },
        ])
        .select()
        .throwOnError(),
    onSuccess: async () => {
      queryClient.invalidateQueries(queryKey as InvalidateQueryFilters);
      onClose();
    },
    onError: (error: { code: string }) => {
      if (error.code === '23505') {
        setError('name', {
          type: 'server',
          message: `${title}name bereits vorhanden.`,
        });
      }
    },
  });

  const onSubmit = async (data: RouteData | DetectorData) => {
    await mutate(data);
  };

  return (
    <Dialog open={!!initialValues} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitleWithClose onClose={onClose}>{title} speichern</DialogTitleWithClose>
      <DialogContent sx={{ padding: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label={`${title}name`}
            defaultValue={
              (initialValues as RouteFeature)?.properties?.name ||
              (initialValues as DetectorGroupFeature)?.properties.name
            }
            fullWidth
            margin="normal"
            {...register('name', { required: true })}
          />
          {(initialValues as RouteFeature)?.properties.routeLocations ? (
            <>
              <Controller
                control={control}
                name="route_category"
                render={() => (
                  <TextField
                    variant="outlined"
                    select
                    fullWidth
                    margin="normal"
                    label="Kategorie"
                    defaultValue="Teststrecke"
                    {...register('route_category', { required: true })}
                  >
                    {(
                      [
                        'Teststrecke',
                        'Strategie',
                        'Referenzstrecke',
                        'Sonstiges',
                      ] as Database['public']['Enums']['route_category'][]
                    ).map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <DialogGridComponent
                data={[
                  { label: 'Länge', value: NumberFormat.lengthKm((initialValues as RouteFeature)?.properties.length) },
                ]}
              />
            </>
          ) : (
            <DialogGridComponent />
          )}

          {errors && (
            <Typography color="error" mb={2}>
              {errors?.name?.message}
            </Typography>
          )}

          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button color="secondary" variant="outlined" onClick={onClose}>
              Abbrechen
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Speichern
            </Button>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}
