import { useMutation } from '@tanstack/react-query';

import { Database } from '@/types/Supabase';
import { DeleteDialog } from '@/components/dialog/DeleteDialog';
import { DialogGridComponent } from '@/components/dialog/DialogGridComponent';
import { supabase } from '@/setup/supabaseClient';

type RouteDeleteDialogProps = {
  route: Database['public']['Tables']['user_query_route']['Row'] | undefined;
  onClose: () => void;
  onDeleteSuccess: () => void;
};

export function RouteDeleteDialog({ route, onDeleteSuccess, onClose }: RouteDeleteDialogProps) {
  const { name, route_category: category, length, route_id: id } = route || {};

  const { mutate } = useMutation({
    mutationFn: async (data: string) => {
      await supabase.from('user_query_route').delete().eq('route_id', data);
    },
    onSuccess: () => {
      onDeleteSuccess();
    },
  });

  const handleMutate = async () => {
    if (id) {
      mutate(id);
    }
  };

  return (
    <DeleteDialog open={!!route} onClose={onClose} title="Strecke" onSubmit={handleMutate}>
      <DialogGridComponent
        data={[
          { label: 'Streckenname', value: name },
          { label: 'Kategorie', value: category },
          { label: 'Länge', value: `${length} km` },
        ]}
      />
    </DeleteDialog>
  );
}
