import { forwardRef, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { PlotData } from 'plotly.js';
import { CircularProgress } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';

import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { DurationType } from '@/types/DurationType';
import { Plot } from '@/components/plot/Plot';
import { getFcdParameterTypeFormatOptionsForTimeRangePlot } from '@/fcd-analysis/modules/FcdParameterTypeFormatOptions';
import { PlotRef } from '@/types/PlotRef';
import { useExportToImageConfig } from '@/hooks/useExportToImageConfig';
import { TimeRangePlot } from '@/modules/TimeRangePlot';
import { usePlotTitle } from '@/hooks/usePlotTitle';
import { useExportData } from '@/hooks/useExportData';
import { getDetectorParameterTypeFormatOptionsForTimeRangePlot } from '@/detector-analysis/modules/DetectorParameterTypeFormatOptions';
import { AnalysisType } from '@/types/AnalysisType';
import { AnalysisPlotComposition } from '@/types/AnalysisPlotComposition';
import { DetectorResultValue, FcdResultValue } from '@/modules/api';

type SingleTimeRangePlotProps = {
  composition: AnalysisPlotComposition;
  durationType: DurationType;
  setDiagramVisibleDateRange: (diagramVisibleDateRange: DateRange<Dayjs> | undefined) => void;
  resetDiagramVisibleDateRange: () => void;
};

export const SingleTimeRangePlot = forwardRef<PlotRef, SingleTimeRangePlotProps>(
  ({ composition, durationType, setDiagramVisibleDateRange, resetDiagramVisibleDateRange }, ref) => {
    const { analysisType, diagramParameters, queryResult } = composition;

    const config = useExportToImageConfig(composition);

    const { decimals, unit, multiplier } =
      analysisType === AnalysisType.FCD
        ? getFcdParameterTypeFormatOptionsForTimeRangePlot({
            parameterType: diagramParameters.parameterType,
            durationType,
          })
        : getDetectorParameterTypeFormatOptionsForTimeRangePlot({
            parameterType: diagramParameters.parameterType,
            interval: diagramParameters.interval,
          });
    const title = usePlotTitle({ composition, unit });

    const data = useMemo<Partial<PlotData>[] | undefined>(
      () =>
        queryResult.data?.results?.map((result, index) => ({
          x: (
            Object.values(Object.values(Object.values(result.values!)[0])) as
              | Required<FcdResultValue>[]
              | Required<DetectorResultValue>[]
          ).map((value) => dayjs(value.timestamp!).tz('Europe/Berlin').format('YYYY-MM-DDTHH:mm:ss')),
          y: (
            Object.values(Object.values(Object.values(result.values!)[0])) as
              | Required<FcdResultValue>[]
              | Required<DetectorResultValue>[]
          ).map((value) => Math.round(value.value! * multiplier * 10 ** decimals) / 10 ** decimals || value.value),
          mode: 'lines',
          type: 'scatter',
          name:
            analysisType === AnalysisType.FCD
              ? diagramParameters.routes.features[index].properties.name
              : diagramParameters.detectorGroups.features[index].properties.name,
          line: { color: ROUTE_COLOR[index] },
          hovertemplate: `%{x}<br />%{y} ${unit || ''}`,
          yhoverformat: `.${decimals}f`,
          xhoverformat: '%d.%m. %H:%M',
        })),
      [analysisType, decimals, diagramParameters, multiplier, queryResult.data?.results, unit],
    );

    const levelOfServicePlotLayout = useMemo(
      () => TimeRangePlot.getLevelOfServiceLayout({ parameterType: diagramParameters.parameterType, data }),
      [data, diagramParameters.parameterType],
    );

    useExportData({ ref, data });

    return !queryResult.isLoading && data ? (
      <Plot
        data={data}
        layout={{
          ...levelOfServicePlotLayout,
          uirevision: JSON.stringify(queryResult.data),
          title: {
            text: title,
          },
          yaxis: {
            ...levelOfServicePlotLayout?.yaxis,
          },
        }}
        config={config}
        onRelayout={(event) => {
          if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
            setDiagramVisibleDateRange([
              dayjs(event['xaxis.range[0]']).clone(),
              dayjs(event['xaxis.range[1]']).clone(),
            ]);
          } else if (event.autosize || event['xaxis.autorange']) {
            resetDiagramVisibleDateRange();
          }
        }}
      />
    ) : (
      <CircularProgress />
    );
  },
);
