import { useEffect, useRef } from 'react';
import ReactMapGl, { MapGeoJSONFeature } from 'react-map-gl';
import { useStore } from 'zustand';
import { useLocation, useMatch } from 'react-router-dom';
import { Box } from '@mui/material';

import { FcdLayerType } from '@/types/FcdLayerType';
import { MapBackground } from '@/modules/MapBackground';
import { ObstructionFeature } from '@/types/ObstructionFeature';
import { TrafficLightFeature } from '@/types/TrafficLightFeature';
import { FcdFeature } from '@/types/FcdFeature';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { MapSide } from '@/types/MapSide';
import { MapContext } from '@/stores/MapContext';
import { createMapStore } from '@/stores/createMapStore';
import { FCD_LAYER } from '@/types/FcdLayer';
import { MapChildren } from '@/map/components/MapChildren';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { useMapStore } from '@/stores/useMapStore';
import { StrategyFeature } from '@/map/types/StrategyFeature';
import { FeatureSelectMenuPopup } from '@/map/components/feature-layers/FeatureSelectMenuPopup';
import { useHotspotsStore } from '@/stores/useHotspotsStore';
import { HOTSPOTS_LAYER } from '@/hotspots/components/HotspotsLayer';
import { HotspotFeature } from '@/types/HotspotFeature';
import { RouteFeature } from '@/types/RouteFeature';
import { ANALYSIS_ROUTES_LAYER } from '@/fcd-analysis/components/AnalysisRoutesLayer';
import { DetectorFeature } from '@/types/DetectorFeature';
import {
  DETECTORS_CLUSTER_ICON_LAYER,
  DETECTORS_SELECTED_ICON_LAYER,
  DETECTORS_SELECTED_SOURCE,
  DETECTORS_CLUSTER_SOURCE,
  DETECTORS_CLUSTER_CIRCLE_LAYER,
} from '@/detector-analysis/components/DetectorsLayer';

type MonitoringMapProps = {
  side: MapSide;
};

export function Map({ side }: MonitoringMapProps) {
  const location = useLocation();

  const store = useRef(createMapStore({ side })).current;

  const viewState = useMapStore((state) => state.viewState);
  const sideBySideMaps = useMapStore((state) => state.sideBySideMaps);
  const {
    setActiveSide,
    setViewState,
    setCursorCoordinates,
    setFcdHighlightId,
    setStrategyDetailCardFeature,
    setObstructionPopupFeature,
  } = useMapStore((state) => state.actions);

  const { setHotspotHighlightId, setHotspotFeature, setHotspotEventFeature } = useHotspotsStore(
    (state) => state.actions,
  );
  const map = useMapStore((state) => state.map);
  const mapLayers = useStore(store, (state) => state.layers);
  const styleLoaded = useStore(store, (state) => state.styleLoaded);
  const mapBackgroundLayer = useStore(store, (state) => state.backgroundLayer);
  const {
    setStyleLoaded,
    setFeatureSelectMenu,
    setFcdDetailCardFeature,
    setTrafficLightPopupFeature,
    setRouteLengthPopupFeature,
    setRoutePopupPoint,
    setDetectorDetailCardFeature,
    setDetectorPopupFeature,
    setDetectorClusterPopup,
  } = useStore(store, (state) => state.actions);

  useEffect(() => {
    setFeatureSelectMenu(undefined);
    setFcdDetailCardFeature(undefined);
    setStrategyDetailCardFeature(undefined);
    setTrafficLightPopupFeature(undefined);
    setRouteLengthPopupFeature(undefined);
    setObstructionPopupFeature(undefined);
    setRoutePopupPoint(undefined);
    setDetectorPopupFeature(undefined);
    setDetectorDetailCardFeature(undefined);
    setDetectorClusterPopup(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const analysisMatch = useMatch('/karte/fcd-verkehrsanalyse');
  const hotspotMatch = useMatch('/karte/hotspots');
  const detectorMatch = useMatch('/karte/detektoranalyse');

  const interactiveLayerIds = [
    ...(mapLayers.fcd !== FcdLayerType.NONE && mapLayers.fcd !== FcdLayerType.ROUTING_NET ? [FCD_LAYER] : []),
    ...Object.values(FeatureLayerType).filter((layer) => mapLayers.features[layer]),
    ...(analysisMatch ? [ANALYSIS_ROUTES_LAYER] : []),
    ...(hotspotMatch ? [HOTSPOTS_LAYER] : []),
    ...(detectorMatch
      ? [DETECTORS_CLUSTER_CIRCLE_LAYER, DETECTORS_CLUSTER_ICON_LAYER, DETECTORS_SELECTED_ICON_LAYER]
      : []),
  ];

  const { setRouteHighlightId } = useFcdAnalysisStore((state) => state.actions);

  const elementRef = useRef(null);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      map.current?.resize();
    });

    resizeObserver.observe(elementRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, [map]);

  useEffect(() => {
    map.current?.resize();
  }, [map, sideBySideMaps]);

  const handleFeatureSelect = (feature: MapGeoJSONFeature) => {
    feature.properties = {
      ...feature.properties,
      ...map.current?.getFeatureState(feature),
      ...{ gid: feature.id },
    };

    if (feature.source === ANALYSIS_ROUTES_LAYER && typeof feature.id === 'string') {
      setRouteHighlightId(feature.id);
    } else if (feature.source === FeatureLayerType.OBSTRUCTION) {
      setObstructionPopupFeature(feature as unknown as ObstructionFeature);
    } else if (
      feature.source === FCD_LAYER &&
      mapLayers.fcd !== FcdLayerType.NONE &&
      mapLayers.fcd !== FcdLayerType.ROUTING_NET
    ) {
      setFcdDetailCardFeature(feature as unknown as FcdFeature);
    } else if (feature.source === FeatureLayerType.STRATEGY) {
      setStrategyDetailCardFeature(feature as unknown as StrategyFeature);
    } else if (feature.source === HOTSPOTS_LAYER) {
      setHotspotFeature(feature as unknown as HotspotFeature);
    }
  };

  return (
    <Box ref={elementRef} sx={{ width: '100%', height: '100%' }}>
      <MapContext.Provider value={store}>
        <ReactMapGl
          key={mapBackgroundLayer}
          ref={map}
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          {...viewState}
          logoPosition="top-left"
          mapStyle={MapBackground.id[mapBackgroundLayer].styleData}
          interactiveLayerIds={styleLoaded ? interactiveLayerIds : []}
          onLoad={() => {
            setStyleLoaded(true);

            map.current?.on('style.load', () => {
              setStyleLoaded(true);
            });
          }}
          onMove={(event) => setViewState(event.viewState)}
          onMouseOver={() => setActiveSide(side)}
          onMouseMove={(event) => {
            setCursorCoordinates(event.point);

            const sourceId = event.features?.[0]?.source;
            const layerId = event.features?.[0]?.layer.id;

            setRouteLengthPopupFeature(
              sourceId === ANALYSIS_ROUTES_LAYER
                ? {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: [event.lngLat.lng, event.lngLat.lat],
                    },
                    properties: {
                      length: (event.features?.[0] as unknown as RouteFeature).properties.length,
                    },
                  }
                : undefined,
            );

            setTrafficLightPopupFeature(
              sourceId === FeatureLayerType.TRAFFIC_LIGHT
                ? (event.features?.[0] as unknown as TrafficLightFeature)
                : undefined,
            );

            setDetectorPopupFeature(
              layerId && [DETECTORS_CLUSTER_ICON_LAYER, DETECTORS_SELECTED_ICON_LAYER].includes(layerId)
                ? (event.features?.[0] as unknown as DetectorFeature)
                : undefined,
            );

            const newId = event.features?.[0]?.properties?.id || event.features?.[0]?.properties?.cluster_id;
            setFcdHighlightId(sourceId === FCD_LAYER ? newId : undefined);
            setHotspotHighlightId(sourceId === HOTSPOTS_LAYER ? newId : undefined);

            if (
              newId &&
              sourceId &&
              [
                FCD_LAYER,
                HOTSPOTS_LAYER,
                DETECTORS_CLUSTER_SOURCE,
                DETECTORS_SELECTED_SOURCE,
                FeatureLayerType.OBSTRUCTION,
                FeatureLayerType.STRATEGY,
              ].includes(sourceId)
            ) {
              event.target.getCanvas().style.cursor = 'pointer';
            } else {
              event.target.getCanvas().style.removeProperty('cursor');
            }
          }}
          onDrag={(event) => {
            if (event.originalEvent && 'layerX' in event.originalEvent && 'layerY' in event.originalEvent) {
              setCursorCoordinates({
                x: event.originalEvent.layerX as number,
                y: event.originalEvent.layerY as number,
              });
            }
          }}
          onMouseOut={() => {
            setActiveSide();
            setCursorCoordinates();
          }}
          onRender={(event) => event.target.resize()}
          onClick={(event) => {
            setFcdDetailCardFeature(undefined);
            setStrategyDetailCardFeature(undefined);
            setHotspotFeature(undefined);
            setHotspotEventFeature(undefined);

            if (event.features && event.features.length > 0) {
              const { features } = event;

              const filteredFeatures = features[0].properties?.cluster
                ? []
                : features.filter(
                    (feature) =>
                      JSON.stringify(feature.geometry) === JSON.stringify(features[0].geometry) ||
                      (feature.source === HOTSPOTS_LAYER && features[0].source === HOTSPOTS_LAYER),
                  );

              if (filteredFeatures.length === 1) {
                handleFeatureSelect(filteredFeatures[0]);
              } else if (filteredFeatures.length > 1) {
                setFeatureSelectMenu({
                  features: filteredFeatures,
                  point: { type: 'Point', coordinates: event.lngLat.toArray() },
                });
              }
            }
          }}
          onContextMenu={(event) => {
            const [feature] = event.features || [];

            if (analysisMatch) {
              if (feature?.source === ANALYSIS_ROUTES_LAYER && typeof feature.id === 'string') {
                setRouteHighlightId(feature.id);
              }

              setRoutePopupPoint({ type: 'Point', coordinates: [event.lngLat.lng, event.lngLat.lat] });
            }
          }}
        >
          <FeatureSelectMenuPopup onSelect={(feature) => handleFeatureSelect(feature)} />

          <MapChildren />
        </ReactMapGl>
      </MapContext.Provider>
    </Box>
  );
}
