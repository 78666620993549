import { IconButton, styled, css, Grid, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';

import { FCD_LEGEND } from '@/map/modules/FcdLegend';
import { ValueColorCircle } from '@/map/components/fcd/ValueColorCircle';
import CopyIcon from '@/map/assets/copy.svg?react';
import { FcdLayerType } from '@/types/FcdLayerType';
import { useMapContext } from '@/stores/useMapContext';
import { DetailCard } from '@/map/components/detail-card/DetailCard';
import { DetailCardTable } from '@/map/components/detail-card/DetailCardTable';
import { NumberFormat } from '@/modules/NumberFormat';

const StyledGrid = styled(Grid)(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `,
);

export function FcdDetailCard() {
  const { setFcdDetailCardFeature } = useMapContext((state) => state.actions);
  const feature = useMapContext((state) => state.fcdDetailCardFeature);

  const [copiedSegmentId, setCopiedSegmentId] = useState(false);

  if (!feature) {
    return null;
  }

  const onClose = () => setFcdDetailCardFeature(undefined);

  const {
    id,
    gid,
    timestamp,
    los,
    confidence,
    timeloss,
    traveltimehere,
    traveltimeself,
    slowdown,
    speeding,
    v,
    vfree,
    maxspeed,
  } = feature.properties;

  const legendLos = FCD_LEGEND[FcdLayerType.LEVEL_OF_SERVICE].find(({ value }) => value === undefined || value >= los);

  const legendConfidence = FCD_LEGEND[FcdLayerType.CONFIDENCE_VALUE].find(
    ({ value }) => value === undefined || confidence >= value,
  );

  return (
    <DetailCard title="Kanteninformation" onClose={onClose}>
      <Grid container alignItems="center">
        <StyledGrid item xs={4}>
          <ValueColorCircle color={legendLos?.color}>{legendLos ? legendLos.label : '–'}</ValueColorCircle>
        </StyledGrid>
        <Tooltip title="(length / (free_flow_speed / 3,6 ) - (length / (speed_avg / 3,6 )">
          <StyledGrid item xs={4}>
            <ValueColorCircle>{NumberFormat.durationS(timeloss, '–')}</ValueColorCircle>
          </StyledGrid>
        </Tooltip>

        <StyledGrid item xs={4}>
          <ValueColorCircle color={legendConfidence?.color}>
            {legendConfidence?.value ? `${Math.round(confidence * 100)} %` : '–'}
          </ValueColorCircle>
        </StyledGrid>

        <StyledGrid item xs={4}>
          <Typography>LOS</Typography>
        </StyledGrid>
        <Tooltip title="Differenz zwischen Reisezeit im Free-Flow und aktueller Reisezeit">
          <StyledGrid item xs={4}>
            <Typography>Verlustzeit</Typography>
          </StyledGrid>
        </Tooltip>

        <StyledGrid item xs={4}>
          <Typography>Vertrauens&shy;kennwert</Typography>
        </StyledGrid>
      </Grid>

      <DetailCardTable
        data={[
          {
            label: 'Segment-ID',
            value: (
              <Tooltip
                title={copiedSegmentId ? 'Segment-ID wurde kopiert' : 'Segment-ID in Zwischenablage kopieren'}
                placement="right"
              >
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(id);
                    setCopiedSegmentId(true);
                  }}
                  onMouseLeave={() =>
                    setTimeout(() => {
                      setCopiedSegmentId(false);
                    }, 500)
                  }
                >
                  <CopyIcon style={{ width: 20, height: 'auto' }} />
                </IconButton>
              </Tooltip>
            ),
          },
          { label: 'Zuletzt aktualisiert', value: timestamp && dayjs(timestamp).format('L LT') },
        ]}
        additionalData={[
          { label: 'speed_avg', value: NumberFormat.oneMaximumFractionDigits(v) },
          { label: 'free_flow_speed', value: NumberFormat.oneMaximumFractionDigits(vfree) },
          { label: 'max_speed', value: NumberFormat.oneMaximumFractionDigits(maxspeed) },
          { label: 'los', value: NumberFormat.oneMaximumFractionDigits(los) },
          { label: 'confidence', value: NumberFormat.twoMaximumFractionDigits(confidence) },
          {
            label: 'traveltimehere',
            value: NumberFormat.oneMaximumFractionDigits(traveltimehere),
            tooltipLabel: 'Aktuelle Reisezeit auf dem HERE-Link auf Basis der mittleren aktuellen Geschwindigkeit',
          },
          {
            label: 'traveltimeself',
            value: NumberFormat.oneMaximumFractionDigits(traveltimeself),
            tooltipLabel: 'Aktuelle Reisezeit der HERE Daten auf das OSM-Zielnetz-Segment gematched',
            tooltipValue: 'length / (speed_avg / 3,6)',
          },
          {
            label: 'speeding',
            value: NumberFormat.oneMaximumFractionDigits(speeding),
            tooltipLabel: 'In Echtzeit auf Basis der aktuellen Durchschnittsgeschwindigkeit',
            tooltipValue: 'speed_avg / max_speed',
          },
          {
            label: 'slowdown',
            value: NumberFormat.oneMaximumFractionDigits(slowdown),
            tooltipLabel:
              'Geschwindigkeitsunterschreitungen im unbel. Netz (Zul. Geschwindigkeit / Freiflussgeschwindigkeit)',
            tooltipValue: 'max_speed / free_flow_speed',
          },
          {
            label: 'gid',
            value: gid,
          },
          {
            label: 'speed_free_flow',
            value: NumberFormat.oneMaximumFractionDigits(vfree / v),
            tooltipLabel: 'In Echtzeit auf Basis der aktuellen Durchschnittsgeschwindigkeit & Freiflussgeschwindigkeit',
            tooltipValue: 'free_flow_speed / speed_avg',
          },
        ]}
      />
    </DetailCard>
  );
}
