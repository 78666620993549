import { Typography } from '@mui/material';
import { Popup } from 'react-map-gl';

import { PopupContent } from '@/components/PopupContent';
import { useMapContext } from '@/stores/useMapContext';

export function TrafficLightPopup() {
  const feature = useMapContext((state) => state.trafficLightPopupFeature);

  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;
  const { lsa_number: number, location } = feature.properties;

  return (
    <Popup
      key={number}
      longitude={longitude}
      latitude={latitude}
      offset={16}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
    >
      <PopupContent>
        <Typography fontWeight="bold" color="text.primary" mb={1} sx={{ fontSie: '0.75rem' }}>
          {`${number}: ${location}`}
        </Typography>
      </PopupContent>
    </Popup>
  );
}
