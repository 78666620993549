import { ReactNode } from 'react';
import { ListItemButton, ListItemText, Tooltip, css, styled } from '@mui/material';

import { transientOptions } from '@/utils/transient-options';

const StyledListItemButton = styled(
  ListItemButton,
  transientOptions,
)<{ $open?: boolean }>(
  ({ theme, $open }) => css`
    transition: ${theme.transitions.create('border-bottom')};
    border-bottom: 1px solid ${theme.palette.divider};
    text-transform: uppercase;
    flex-grow: 0;

    ${$open &&
    css`
      border-bottom: 3px solid ${theme.palette.primary.main};
    `}
  `,
);

type SidebarListItemButtonProps = {
  onClick?: () => void;
  children: ReactNode;
  chip?: ReactNode;
  open?: boolean;
  toolTip?: ReactNode;
};

export function SidebarListItemButton({ onClick, children, chip, open, toolTip }: SidebarListItemButtonProps) {
  return (
    <Tooltip title={toolTip} placement="right" arrow>
      <StyledListItemButton $open={open} onClick={onClick}>
        <ListItemText
          primary={children}
          sx={{ '.MuiTypography-root': { fontFamily: (theme) => theme.typography.fontFamily } }}
        />
        {chip}
      </StyledListItemButton>
    </Tooltip>
  );
}
