import { Box, css, Grid, Stack, styled } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { GeocodingSearchInput } from '@/fcd-analysis/components/GeocodingSearchInput';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { RouteLocationType } from '@/types/RouteLocationType';
import { StyledSmallTypography } from '@/components/StyledSmallTypography';
import { RouteIcon } from '@/fcd-analysis/components/RouteIcon';
import { RouteLoadDialog } from '@/fcd-analysis/components/RouteLoadDialog';
import { RouteSaveDialog } from '@/fcd-analysis/components/RouteSaveDialog';
import { RouteLocationFeature } from '@/types/RouteLocationFeature';
import { NumberFormat } from '@/modules/NumberFormat';
import { EditName } from '@/components/form/EditName';
import { DataActionButtons } from '@/components/form/DataActionButtons';
import { FormWrapper } from '@/components/form/FormWrapper';
import { AnalysisType } from '@/types/AnalysisType';

const StyledGrid = styled(Grid)(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
);

type RouteFormProps = { index: number };

export function RouteForm({ index }: RouteFormProps) {
  const { t } = useTranslation();
  const routes = useFcdAnalysisStore((state) => state.routes);
  const route = routes.features[index];

  const { updateRoute, removeRoute, resetRoutes } = useFcdAnalysisStore((state) => state.actions);

  const [routeSaveDialog, setRouteSaveDialog] = useState(false);
  const [routeLoadDialog, setRouteLoadDialog] = useState(false);

  const routeHighlightId = useFcdAnalysisStore((state) => state.routeHighlightId);

  const handleSaveDialog = () => {
    setRouteSaveDialog(true);
  };

  const handleLoadDialog = () => {
    setRouteLoadDialog(true);
  };

  const handleRemove = () => {
    if (routes.features.length === 1) {
      resetRoutes();
    } else {
      removeRoute(index);
    }
  };

  const updateName = (name: string) => {
    updateRoute({ ...route, properties: { ...route.properties, name } });
  };

  return (
    <FormWrapper index={index} active={route.id === routeHighlightId}>
      <>
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Box sx={{ marginLeft: 8 }}>
              <EditName name={route.properties.name} updateName={updateName} />
            </Box>
            <StyledSmallTypography>{NumberFormat.lengthKm(route.properties.length)}</StyledSmallTypography>
          </Stack>

          {[RouteLocationType.ORIGIN, RouteLocationType.VIA, RouteLocationType.DESTINATION].map((routeLocationType) => {
            const routeLocations: (RouteLocationFeature | null)[] = route.properties.routeLocations.features.filter(
              ({ properties: { routeLocationType: featureRouteLocationType } }) =>
                featureRouteLocationType === routeLocationType,
            );

            if (
              routeLocations.length === 0 &&
              [RouteLocationType.ORIGIN, RouteLocationType.DESTINATION].includes(routeLocationType)
            ) {
              routeLocations.push(null);
            }

            return routeLocations.map((routeLocation) => (
              <Grid
                container
                key={routeLocation?.id || routeLocationType}
                sx={{ backgroundColor: 'common.white', marginBottom: 1 }}
              >
                <StyledGrid item xs={2}>
                  <RouteIcon
                    type={routeLocationType}
                    color={ROUTE_COLOR[index]}
                    width={routeLocationType === RouteLocationType.VIA ? 12 : 16}
                    height="auto"
                  />
                </StyledGrid>
                <Grid item xs={10}>
                  <GeocodingSearchInput
                    label={t(`RouteLocationType.${routeLocationType}`)}
                    routeId={route.id}
                    routeLocationType={routeLocationType}
                    routeLocation={routeLocation}
                    routeLocationIndex={
                      routeLocation ? route.properties.routeLocations.features.indexOf(routeLocation) : undefined
                    }
                  />
                </Grid>
              </Grid>
            ));
          })}
        </Box>

        <DataActionButtons
          index={index}
          onSaveDialog={handleSaveDialog}
          onLoadDialog={handleLoadDialog}
          onRemove={handleRemove}
          analysisType={AnalysisType.FCD}
        />

        <RouteSaveDialog route={routeSaveDialog ? route : undefined} onClose={() => setRouteSaveDialog(false)} />
        <RouteLoadDialog route={routeLoadDialog ? route : undefined} onClose={() => setRouteLoadDialog(false)} />
      </>
    </FormWrapper>
  );
}
