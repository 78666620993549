import FileSaver from 'file-saver';
import { Button, Tooltip } from '@mui/material';
import { RefObject } from 'react';
import { Datum } from 'plotly.js';

import { PlotRef } from '@/types/PlotRef';
import { useExportToImageConfig } from '@/hooks/useExportToImageConfig';
import { AnalysisPlotComposition } from '@/types/AnalysisPlotComposition';

const convertToCSV = (data: Record<string, Datum | Datum[] | undefined>[]) => {
  const propertyNames = Object.keys(data[0]);

  const headerRow = propertyNames.map((name) => name.replaceAll('<br />', ''.replaceAll('<br />', ''))).join(';');

  const csvRows = data.map((item: any) => {
    const values = propertyNames.map((propertyName) => item[propertyName]);

    return values
      .map((value) => {
        if (typeof value === 'number') {
          return value.toString().replace('.', ',');
        }

        return value;
      })
      .join(';');
  });

  return [headerRow, ...csvRows].join('\n');
};

type ExportButtonProps = {
  disabled?: boolean;
  plotRef: RefObject<PlotRef>;
  composition: AnalysisPlotComposition;
};

export function ExportButton({ disabled, plotRef, composition }: ExportButtonProps) {
  const config = useExportToImageConfig(composition);

  const handleExportClick = () => {
    const data = plotRef.current?.exportData() || [];
    const csvContent = convertToCSV(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, `${config.toImageButtonOptions?.filename}.csv`);
  };

  return (
    <Tooltip title="Diagrammdaten als CSV (.csv) exportieren und herunterladen">
      <span>
        <Button
          disabled={disabled}
          variant="outlined"
          color="secondary"
          onClick={handleExportClick}
          sx={{ height: 44 }}
        >
          Export
        </Button>
      </span>
    </Tooltip>
  );
}
