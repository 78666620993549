import { Typography, useTheme } from '@mui/material';
import { Layer, Source } from 'react-map-gl';
import dayjs from 'dayjs';

import { RouteSegmentsFeatureCollection } from '@/map/types/RouteSegmentFeature';
import { StrategyLineType } from '@/map/types/StrategyLineType';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { useMapImages } from '@/map/hooks/useMapImages';
import { MapIcon } from '@/map/types/MapIcon';
import DestinationPattern from '@/map/assets/destination-pattern.svg?url';
import { Strategy } from '@/map/modules/Strategy';
import { DetailCard } from '@/map/components/detail-card/DetailCard';
import { PolygonBorderLayer } from '@/map/components/feature-layers/PolygonBorderLayer';
import { MapBefore } from '@/map/types/MapBefore';
import { DetailCardTable } from '@/map/components/detail-card/DetailCardTable';
import { useMapStore } from '@/stores/useMapStore';

export const STRATEGIES_HIGHLIGHT_CIRCLE_LAYER = `${FeatureLayerType.STRATEGY}_HIGHLIGHT_CIRCLE_LAYER`;
export const STRATEGIES_HIGHLIGHT_ICON_LAYER = `${FeatureLayerType.STRATEGY}_HIGHLIGHT_ICON_LAYER`;

const displayDateTime = (value?: string) => value && dayjs(value).format('L LT');

export function StrategyDetailCard() {
  const feature = useMapStore((state) => state.strategyDetailCardFeature);

  const { setStrategyDetailCardFeature } = useMapStore((state) => state.actions);

  const theme = useTheme();

  useMapImages({
    images: [{ name: MapIcon.DESTINATION_PATTERN, url: DestinationPattern, width: 32, height: 32 }],
  });

  if (!feature) {
    return null;
  }

  const comments: string[] = Array.isArray(feature.properties.comments)
    ? feature.properties.comments!
    : JSON.parse(feature.properties.comments || '[]');
  const geometries = Strategy.getGeometries(feature);

  const { routeAvoid, routePreferred, avoidedArea, originArea, destinationArea } = geometries;

  const routeSegments: RouteSegmentsFeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  if (routeAvoid) {
    routeSegments.features.push({
      type: 'Feature',
      geometry: routeAvoid,
      properties: {
        routeLineType: StrategyLineType.AVOID,
      },
    });
  }

  if (routePreferred) {
    routeSegments.features.push({
      type: 'Feature',
      geometry: routePreferred,
      properties: {
        routeLineType: StrategyLineType.PREFERRED,
      },
    });
  }

  return (
    <>
      <DetailCard title="Strategie" onClose={() => setStrategyDetailCardFeature(undefined)}>
        <Typography variant="h2" mb={1}>
          {feature.properties.name && feature.properties.name.replace(`${feature.properties.type}: `, '')}
        </Typography>
        <Typography variant="h3" mb={2}>
          {feature.properties.type}
        </Typography>

        {comments.map((comment) => (
          <Typography key={comment}>{comment}</Typography>
        ))}

        <DetailCardTable
          data={[
            {
              label: 'Gültig ab',
              value: displayDateTime(feature.properties.valid_from),
            },
            { label: 'Gültig bis', value: displayDateTime(feature.properties.valid_to) },
            { label: 'Zuletzt aktualisiert', value: displayDateTime(feature.properties.update_time) },
          ]}
          additionalData={[
            { label: 'Status', value: feature.properties.status },
            {
              label: 'Compliance Option',
              value: feature.properties.compliance_option,
            },
            {
              label: 'Occurrence Probability',
              value: feature.properties.occurrence_probability,
            },
          ]}
        />
      </DetailCard>

      <Layer
        key={STRATEGIES_HIGHLIGHT_CIRCLE_LAYER}
        id={STRATEGIES_HIGHLIGHT_CIRCLE_LAYER}
        filter={['==', 'id', feature.id!]}
        source={FeatureLayerType.STRATEGY}
        type="circle"
        paint={{
          'circle-color': 'white',
          'circle-radius': 24,
        }}
      />
      <Layer
        key={STRATEGIES_HIGHLIGHT_ICON_LAYER}
        id={STRATEGIES_HIGHLIGHT_ICON_LAYER}
        filter={['==', 'id', feature.id as string]}
        source={FeatureLayerType.STRATEGY}
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'icon-image': MapIcon.STRATEGY,
        }}
      />

      <PolygonBorderLayer geometry={avoidedArea} color={theme.palette.error.main} />
      <PolygonBorderLayer geometry={originArea} color={theme.palette.info.main} />
      <PolygonBorderLayer
        geometry={destinationArea}
        color={theme.palette.error.main}
        pattern={MapIcon.DESTINATION_PATTERN}
      />

      <Source type="geojson" data={routeSegments}>
        <Layer
          beforeId={MapBefore.CONTENT}
          type="line"
          layout={{
            'line-cap': 'round',
          }}
          paint={{
            'line-color': 'white',
            'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 10, 18, 8],
          }}
        />
        <Layer
          beforeId={MapBefore.CONTENT}
          type="line"
          layout={{
            'line-cap': 'round',
          }}
          paint={{
            'line-color': [
              'case',
              ['==', ['get', 'routeLineType'], StrategyLineType.AVOID],
              theme.palette.error.main,
              theme.palette.success.main,
            ],
            'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 6, 18, 4],
          }}
        />
      </Source>
    </>
  );
}
