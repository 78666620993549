import {
  GridToolbar,
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  gridPaginationRowRangeSelector,
  useGridApiContext,
  useGridApiEventHandler,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { memo, useEffect } from 'react';

import { useHotspotsStore } from '@/stores/useHotspotsStore';
import { HotspotFeature } from '@/types/HotspotFeature';

export const HotspotsGridToolbar = memo(() => {
  const hotspotFeature = useHotspotsStore((state) => state.hotspotFeature);
  const { setVisibleHotspotsFeatureCollection, setDataGridInitialState } = useHotspotsStore((state) => state.actions);

  const apiRef = useGridApiContext();

  useGridApiEventHandler(apiRef, 'unmount', () => {
    const state = apiRef.current?.exportState();
    if (state) {
      setDataGridInitialState(state);
    }
  });

  useEffect(() => {
    if (hotspotFeature && apiRef?.current) {
      const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);
      const pageRowIndex = gridPaginationRowRangeSelector(apiRef);
      const rowIndex = allRows.findIndex((row) => row.id === hotspotFeature.properties.id);

      if (typeof pageRowIndex?.firstRowIndex === 'number' && rowIndex >= 0) {
        setTimeout(() => {
          apiRef.current?.scrollToIndexes({ rowIndex: rowIndex + pageRowIndex.firstRowIndex });
        }, 0);
      }
    }
  }, [apiRef, hotspotFeature]);

  const paginatedVisibleSortedGridRowEntries = useGridSelector(
    apiRef,
    gridPaginatedVisibleSortedGridRowEntriesSelector,
  );

  useEffect(() => {
    setVisibleHotspotsFeatureCollection({
      type: 'FeatureCollection',
      features: paginatedVisibleSortedGridRowEntries.map(({ model }) => model) as HotspotFeature[],
    });
  }, [paginatedVisibleSortedGridRowEntries, setVisibleHotspotsFeatureCollection]);

  return <GridToolbar />;
});
