import { HotspotFeature, HotspotsFeatureCollection } from '@/types/HotspotFeature';
import { HotspotEventsFeatureCollection } from '@/types/HotspotEventFeature';
import { PG_FEATURESERV } from '@/setup/pg-featureserv';

export class HotspotsService {
  public static async getHotspots(): Promise<HotspotsFeatureCollection> {
    const response = await fetch(`${PG_FEATURESERV}/collections/frontend.hotspot/items.json`);

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    return response.json();
  }

  public static async getHotspotsPaginated(
    page: number,
    rowsPerPage: number,
  ): Promise<{ paginatedHotspots: HotspotFeature[]; count: number }> {
    const data = await HotspotsService.getHotspots();
    const count = data.features.length;

    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage - 1;

    const paginatedHotspots = data.features.slice(startIndex, endIndex + 1);

    return { paginatedHotspots, count };
  }

  public static async getHotspotEvents({ ids }: { ids?: number[] } = {}): Promise<HotspotEventsFeatureCollection> {
    const urlSearchParams = new URLSearchParams();

    if (ids) {
      urlSearchParams.append('filter', `id IN (${ids.join(',')})`);
    }

    const url = `${PG_FEATURESERV}/collections/frontend.hotspot_event/items.json?${urlSearchParams}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    return response.json();
  }
}
