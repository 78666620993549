import { IconButton, Slider, Stack, css, styled } from '@mui/material';
import dayjs from 'dayjs';
import { SyntheticEvent, useState } from 'react';

import AngleLeftIcon from '@/monitoring/assets/angle-left.svg?react';
import AngleRightIcon from '@/monitoring/assets/angle-right.svg?react';
import { useMonitoringStore } from '@/stores/useMonitoringStore';
import { MonitoringMode } from '@/types/MonitoringMode';
import { transientOptions } from '@/utils/transient-options';
import { useFcdLiveQuery } from '@/map/queries/useFcdLiveQuery';
import { isNonNullable } from '@/utils/isNonNullable';

const StyledSlider = styled(
  Slider,
  transientOptions,
)<{
  $mode: MonitoringMode;
}>(
  ({ theme, $mode }) => css`
    .MuiSlider-markLabel {
      top: ${theme.spacing(2)};
    }

    ${theme.breakpoints.down('md')} {
      .MuiSlider-valueLabelOpen {
        height: ${theme.spacing(3)};
      }
    }

    ${$mode === MonitoringMode.LIVE
      ? css`
          & .MuiSlider-valueLabel {
            background-color: ${theme.palette.live.main};
            color: ${theme.palette.common.white};
          }

          .MuiSlider-thumb {
            background-color: #bdbdbd;
            opacity: 1;
          }
        `
      : undefined}
  `,
);

const StyledIconButton = styled(
  IconButton,
  transientOptions,
)<{
  $mode: MonitoringMode;
}>(
  ({ theme, $mode }) => css`
    border-radius: 50%;
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};

    ${theme.breakpoints.up('md')} {
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
    }

    ${$mode === MonitoringMode.HISTORICAL &&
    css`
      background-color: ${theme.palette.primary.main};
    `}
  `,
);

export function HistoricalSlider() {
  const dateTime = useMonitoringStore((state) => state.dateTime);
  const mode = useMonitoringStore((state) => state.mode);
  const { updateTime, setDateTime } = useMonitoringStore((state) => state.actions);
  const [valueHistorical, setValueHistorical] = useState(dateTime.diff(dateTime.startOf('day'), 'minutes'));

  const max = { [MonitoringMode.HISTORICAL]: 1425, [MonitoringMode.LIVE]: 1439 }[mode];

  const { data } = useFcdLiveQuery();

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValueHistorical(newValue as number);
  };

  const handleChangeCommitted = (event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    updateTime(newValue as number);
  };

  const handleAddTime = (minutes: number) => {
    const newDateTime = dateTime.add(minutes, 'minutes');

    setDateTime(newDateTime);
    setValueHistorical(newDateTime.diff(newDateTime.startOf('day'), 'minutes'));
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center" pt={2} pb={2}>
      <StyledIconButton $mode={mode} onClick={() => handleAddTime(-15)} disabled={mode === MonitoringMode.LIVE}>
        <AngleLeftIcon />
      </StyledIconButton>

      <StyledSlider
        $mode={mode}
        valueLabelDisplay="on"
        valueLabelFormat={(sliderValue) => dayjs().startOf('day').add(sliderValue, 'minutes').format('HH:mm')}
        value={
          {
            [MonitoringMode.HISTORICAL]: valueHistorical,
            [MonitoringMode.LIVE]: dayjs(
              data?.results && Object.keys(Object.values(data?.results).filter(isNonNullable)[0])[0],
            ).diff(dayjs().startOf('day'), 'minutes'),
          }[mode]
        }
        disabled={mode === MonitoringMode.LIVE}
        min={0}
        max={max}
        step={{ [MonitoringMode.HISTORICAL]: 15, [MonitoringMode.LIVE]: 1 }[mode]}
        marks={
          {
            [MonitoringMode.HISTORICAL]: [
              { value: 0, label: '0:00' },
              { value: 360, label: '6:00' },
              { value: 720, label: '12:00' },
              { value: 1080, label: '18:00' },
              { value: 1425, label: '23:45' },
            ],
            [MonitoringMode.LIVE]: [
              { value: 0, label: '0:00' },
              { value: 360, label: '6:00' },
              { value: 720, label: '12:00' },
              { value: 1080, label: '18:00' },
              { value: 1439, label: '23:59' },
            ],
          }[mode]
        }
        slotProps={{
          input: {
            onKeyDown: (event) => {
              if (valueHistorical === 0 && event.key === 'ArrowLeft') {
                handleAddTime(-15);
                event.preventDefault();
              }

              if (valueHistorical === max && event.key === 'ArrowRight') {
                handleAddTime(15);
                event.preventDefault();
              }
            },
          },
        }}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />

      <StyledIconButton $mode={mode} onClick={() => handleAddTime(15)} disabled={mode === MonitoringMode.LIVE}>
        <AngleRightIcon />
      </StyledIconButton>
    </Stack>
  );
}
