import { useMutation } from '@tanstack/react-query';

import { Database } from '@/types/Supabase';
import { DeleteDialog } from '@/components/dialog/DeleteDialog';
import { DialogGridComponent } from '@/components/dialog/DialogGridComponent';
import { supabase } from '@/setup/supabaseClient';

type DetectorDeleteDialogProps = {
  detectorGroup: Database['public']['Tables']['user_query_detector_group']['Row'] | undefined;
  onClose: () => void;
  onDeleteSuccess: () => void;
};

export function DetectorDeleteDialog({ detectorGroup, onDeleteSuccess, onClose }: DetectorDeleteDialogProps) {
  const name = detectorGroup?.name;

  const { mutate } = useMutation({
    mutationFn: async (data: string) => {
      await supabase.from('user_query_detector_group').delete().eq('name', data);
    },
    onSuccess: () => {
      onDeleteSuccess();
    },
  });

  const handleMutate = async () => {
    if (name) {
      mutate(name);
    }
  };

  return (
    <DeleteDialog open={!!detectorGroup} onClose={onClose} title="Detektorgruppe" onSubmit={handleMutate}>
      <DialogGridComponent data={[{ label: 'Detektorgruppename', value: name }]} />
    </DeleteDialog>
  );
}
