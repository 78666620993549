import { Button, Stack, Tooltip, Typography } from '@mui/material';

import ArrowRightIcon from '@/assets/icons/analysis/arrow-right.svg?react';
import CheckIcon from '@/assets/icons/analysis/check.svg?react';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { DetectorAnalysisState } from '@/types/DetectorAnalysisState';
import { ValidationStatus } from '@/types/ValidationStatus';
import { useAnalysisStatus } from '@/hooks/useAnalysisStatus';
import { StyledIconButton } from '@/components/StyledIconButton';

const analysisSelector = (state: DetectorAnalysisState) => ({
  diagramParameters: state.diagramParameters,
  analysisParameters: {
    detectorGroups: state.detectorGroups,
    parameterType: state.parameterType,
    interval: state.interval,
    dateRanges: state.dateRanges,
    dayCategories: state.dayCategories,
    timeRange: state.timeRange,
    showContexts: state.showContexts,
    contexts: state.contexts,
  },
});

type DetectorActionButtonsProps = {
  validation: Record<AnalysisFormStep, { status: ValidationStatus; count: string | number }>;
};

export function DetectorActionButtons({ validation }: DetectorActionButtonsProps) {
  const {
    submitDetectorDiagramParameters,
    resetDetectorForm,
    setShowDetectorDiagram,
    setDetectorFormStep,
    setValidateDetectorForm,
  } = useDetectorAnalysisStore((state) => state.actions);

  const { diagramParameters, analysisParameters } = useDetectorAnalysisStore(analysisSelector);

  const analysisStatus = useAnalysisStatus({
    diagramParameters,
    analysisParameters,
    validation,
  });

  const { tooltipTitle, analysisPossible, buttonColor } = analysisStatus;

  return (
    <Stack direction="row" justifyContent="space-between" p={1}>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={() => {
          resetDetectorForm();
        }}
      >
        Neu
      </Button>

      <Tooltip title={<Typography>{tooltipTitle}</Typography>} placement="right" arrow>
        <span>
          <StyledIconButton
            onClick={() => {
              if (analysisPossible) {
                submitDetectorDiagramParameters();
                setShowDetectorDiagram(true);
              } else {
                const firstInvalidFormStep = [
                  AnalysisFormStep.LOCATION,
                  AnalysisFormStep.DATE_TIME,
                  AnalysisFormStep.PARAMETER,
                ].find((formStep) => validation[formStep].status !== 'success');

                if (firstInvalidFormStep !== undefined) {
                  setDetectorFormStep(firstInvalidFormStep);
                  setValidateDetectorForm(true);
                }
              }
            }}
            $color={buttonColor}
          >
            {buttonColor === 'success' ? <CheckIcon /> : <ArrowRightIcon />}
          </StyledIconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}
