import { Geometry } from 'geojson';
import { Layer, Source } from 'react-map-gl';

import { MapBefore } from '@/map/types/MapBefore';

type PolygonBorderLayerProps = { geometry?: Geometry; color: string; pattern?: string };

export function PolygonBorderLayer({ geometry, color, pattern }: PolygonBorderLayerProps) {
  return geometry ? (
    <Source type="geojson" data={geometry}>
      <Layer
        beforeId={MapBefore.CONTENT}
        type="line"
        paint={{
          'line-opacity': 0.5,
          'line-color': color,
          'line-width': ['interpolate', ['exponential', 0.5], ['zoom'], 10, 6, 18, 4],
          'line-offset': ['interpolate', ['exponential', 0.5], ['zoom'], 10, -3, 18, -2],
        }}
      />
      <Layer
        beforeId="tunnel-minor-case"
        type="fill"
        paint={{
          'fill-opacity': 0.25,
          ...(pattern
            ? {
                'fill-pattern': pattern,
                'fill-antialias': false,
              }
            : {
                'fill-color': color,
              }),
        }}
      />
    </Source>
  ) : null;
}
