import { Box, Button, css, FormGroup, Link as MuiLink, styled, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { supabase } from '@/setup/supabaseClient';
import { useSession } from '@/authentication/hooks/useSession';

const StyledFormGroup = styled(FormGroup)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
  `,
);

type ForgotPasswordData = { email: string };

export function ForgotPasswordPage() {
  const { data: session } = useSession();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordData>();

  const { mutateAsync, error } = useMutation({
    mutationFn: async ({ email }: ForgotPasswordData) => {
      const { data, error: supabaseError } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/profil/passwort`,
      });

      if (supabaseError) {
        return Promise.reject(supabaseError);
      }

      return Promise.resolve(data);
    },
  });

  if (session) {
    return <Navigate to="/" />;
  }

  return (
    <form
      onSubmit={handleSubmit(async (loginData) => {
        await mutateAsync(loginData);
        navigate('/');
      })}
    >
      <Box sx={{ textAlign: 'left', fontWeight: 'bold', marginBottom: 3 }}>
        <MuiLink component={Link} to="/">
          Zurück zum Login
        </MuiLink>
      </Box>

      <StyledFormGroup>
        <TextField
          label="E-Mail"
          type="email"
          size="small"
          error={!!error || !!errors.email}
          {...register('email', { required: true })}
        />
      </StyledFormGroup>

      <Button variant="contained" type="submit" fullWidth sx={{ marginBottom: 3 }}>
        Zurücksetzen
      </Button>
    </form>
  );
}
