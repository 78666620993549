/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeParameter } from './TimeParameter';
export type DetectorAnalysisRequest = {
  analysisType: DetectorAnalysisRequest.analysisType;
  valueTypes: Array<'v' | 'num_vehicles'>;
  mode: DetectorAnalysisRequest.mode;
  interval: DetectorAnalysisRequest.interval;
  aggregate?: boolean;
  timeParameters?: Array<TimeParameter>;
  detectors: Array<Array<string>>;
};
export namespace DetectorAnalysisRequest {
  export enum analysisType {
    DETECTOR_ANALYSIS = 'detectorAnalysis',
  }
  export enum mode {
    AVG = 'avg',
    SUM = 'sum',
    MEDIAN = 'median',
  }
  export enum interval {
    MIN_3 = 'min_3',
    MIN_15 = 'min_15',
    HOUR_1 = 'hour_1',
    DAY_1 = 'day_1',
    WEEK_1 = 'week_1',
    MONTH_1 = 'month_1',
    YEAR_1 = 'year_1',
    UNKNOWN = 'unknown',
  }
}
