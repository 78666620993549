import { Box, Divider, css, styled } from '@mui/material';
import { ReactElement } from 'react';

import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { transientOptions } from '@/utils/transient-options';

const StyledBox = styled(
  Box,
  transientOptions,
)<{
  $active: boolean;
  $index: number;
}>(
  ({ theme, $active, $index }) => css`
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: ${theme.spacing(2)};
      left: 0;
      width: ${theme.spacing(1)};
      transition: ${theme.transitions.create('opacity')};
      opacity: 0;
      background-color: ${ROUTE_COLOR[$index]};

      ${$active &&
      css`
        opacity: 1;
      `}
    }
  `,
);

type FormWrapperProps = { index: number; active: boolean; children: ReactElement };

export function FormWrapper({ index, active, children }: FormWrapperProps) {
  return (
    <StyledBox $index={index} $active={active} mb={2}>
      {children}
      <Divider sx={{ marginLeft: 2 }} />
    </StyledBox>
  );
}
