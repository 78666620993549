import { Popup } from 'react-map-gl';

import { PopupContent } from '@/components/PopupContent';
import { useMapContext } from '@/stores/useMapContext';
import { NumberFormat } from '@/modules/NumberFormat';

export function RouteLengthPopup() {
  const feature = useMapContext((state) => state.routeLengthPopupFeature);

  if (!feature || !feature.properties.length) {
    return null;
  }

  return (
    <Popup
      longitude={feature.geometry.coordinates[0]}
      latitude={feature.geometry.coordinates[1]}
      offset={18}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
    >
      <PopupContent>{NumberFormat.lengthKm(feature.properties.length)}</PopupContent>
    </Popup>
  );
}
