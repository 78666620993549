import { alpha, createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import { deDE as coreLocaleDeDE } from '@mui/material/locale';
import { deDE as dataGridLocaleDeDE } from '@mui/x-data-grid-pro/locales';
import { deDE as datePickersLocaleDeDE } from '@mui/x-date-pickers-pro/locales';
import '@fontsource-variable/montserrat';
import '@fontsource/source-sans-pro/latin.css';
import { css } from '@mui/material';

import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    live: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    live: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true;
  }
}

let themeBuilder = createTheme(
  {
    palette: {
      primary: {
        main: '#ffba00',
        contrastText: '#3a3a3a',
      },

      secondary: {
        main: '#c6d4d6',
        contrastText: '#ffffff',
      },

      neutral: {
        main: '#3a3a3a',
        contrastText: '#ffffff',
      },

      live: {
        main: '#2fa7bd',
        contrastText: '#ffffff',
      },

      success: {
        main: '#b9ce00',
      },

      error: {
        main: '#fb8849',
      },

      text: {
        primary: '#3a3a3a',
        secondary: '#86888c',
      },

      background: {
        default: '#3a3a3a',
      },

      divider: '#dfdfdf',
    },
    typography: {
      fontSize: 16,
      fontFamily: ['"Montserrat Variable"', 'sans-serif'].join(','),

      allVariants: {
        fontSize: '1rem',
      },

      h1: {
        fontSize: '2rem',
      },

      h2: {
        fontSize: '1rem',
        fontWeight: 400,
      },

      h3: {
        fontSize: '0.75rem',
        fontWeight: 700,
      },

      body1: {
        fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
      },

      body2: {
        fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
      },
    },
    shape: {
      borderRadius: 4,
    },
    shadows: [
      'none',
      '0px 3px 12px #00000029',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
  },
  coreLocaleDeDE,
  dataGridLocaleDeDE,
  datePickersLocaleDeDE,
);

themeBuilder = createTheme(themeBuilder, {
  components: {
    MuiCssBaseline: {
      styleOverrides: () => css`
        body {
          height: 100vh;
          overflow: hidden;
        }

        .mapboxgl-popup-content {
          padding: 0;
          background: #ffffff;
          border-radius: ${themeBuilder.shape.borderRadius};
          box-shadow: ${themeBuilder.shadows[1]};
        }

        .mapboxgl-ctrl-group:not(:empty) {
          box-shadow: ${themeBuilder.shadows[1]};
        }

        button.mapboxgl-ctrl-compass {
          margin-top: ${themeBuilder.spacing(6)};
          width: ${themeBuilder.spacing(6)};
          height: ${themeBuilder.spacing(6)};
          border-start-start-radius: 0 !important;
          border-start-end-radius: 0 !important;
        }

        .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
          margin: ${themeBuilder.spacing(1)} ${themeBuilder.spacing(1)} 0 0;
        }

        .mapboxgl-ctrl-group .mapbox-gl-draw_polygon {
          width: ${themeBuilder.spacing(6)};
          height: ${themeBuilder.spacing(6)};
          background-size: 75%;
        }
      `,
    },
    MuiAppBar: {
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: css`
          .MuiAutocomplete-groupLabel {
            line-height: 1.5;
            font-family: ${themeBuilder.typography.body1.fontFamily};
          }
        `,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: css`
          border-radius: 0;
        `,
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: css`
          border-bottom: 3px solid ${themeBuilder.palette.primary.main};
          text-transform: uppercase;
          padding-top: ${themeBuilder.spacing(1)};
          padding-bottom: ${themeBuilder.spacing(1)};
          min-height: 51px;
        `,
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: css`
          --DataGrid-containerBackground: ${themeBuilder.palette.common.white};
        `,
        panelWrapper: css`
          border: 2px solid ${themeBuilder.palette.primary.main};
        `,
      },
    },

    MuiSlider: {
      styleOverrides: {
        root: {
          color: themeBuilder.palette.primary.main,
          height: 8,
          padding: '0px',
        },
        thumb: {
          height: 16,
          width: 16,
          backgroundColor: themeBuilder.palette.primary.main,
          border: '2px solid currentColor',

          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: `3px ${themeBuilder.palette.common.white}`,
          },
          '&:before': {
            display: 'none',
          },
        },
        valueLabel: {
          lineHeight: 1.2,
          fontSize: 18,
          background: themeBuilder.palette.primary.main,
          padding: 0,
          width: 50,
          height: 40,
          borderRadius: themeBuilder.shape.borderRadius,
          backgroundColor: themeBuilder.palette.primary.main,
          color: themeBuilder.palette.text.primary,
        },
        track: {
          height: 4,
          borderRadius: 4,
        },
        rail: {
          height: 4,
          borderRadius: 4,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: css`
          ${themeBuilder.breakpoints.up('sm')} {
            min-height: ${themeBuilder.spacing(6)};
          }
        `,
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: css`
          color: ${themeBuilder.palette.text.primary};
        `,
        text: css`
          font-weight: bold;
        `,
        outlinedSecondary: css`
          border-color: ${themeBuilder.palette.secondary.main};

          &:hover {
            background-color: ${alpha(themeBuilder.palette.secondary.main, 0.25)};
          }
        `,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: css`
          color: ${themeBuilder.palette.text.primary};
          border-radius: ${themeBuilder.shape.borderRadius}px;
        `,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: css`
          border: 1px solid ${themeBuilder.palette.secondary.main};
        `,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: css`
          color: ${themeBuilder.palette.text.primary};
          border-color: ${themeBuilder.palette.secondary.main};
          font-size: 1rem;

          &:hover {
            background-color: ${alpha(themeBuilder.palette.secondary.main, 0.25)};
          }

          &.MuiToggleButton-primary {
            color: ${themeBuilder.palette.text.primary};

            &.Mui-selected {
              background-color: ${themeBuilder.palette.primary.main};
              border-color: ${themeBuilder.palette.primary.main};
            }
          }

          &.MuiToggleButton-secondary {
            color: ${themeBuilder.palette.text.primary};

            &.Mui-selected {
              background-color: ${themeBuilder.palette.secondary.main};
            }
          }
        `,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: css`
          background-color: ${themeBuilder.palette.text.primary};
        `,
        arrow: css`
          color: ${themeBuilder.palette.text.primary};
        `,
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: css`
          box-shadow: ${themeBuilder.shadows[1]};
        `,
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 1,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
} as ThemeOptions);

themeBuilder = responsiveFontSizes(themeBuilder);

export const theme = themeBuilder;
