import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

type LayerControlCheckboxProps = {
  value: string;
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  i18nKey: string;
  tooltip?: string;
};
export function LayerControlCheckbox({ value, checked, onChange, i18nKey, tooltip }: LayerControlCheckboxProps) {
  const { t } = useTranslation();

  const checkbox = (
    <FormControlLabel
      label={t(`${i18nKey}.${value}`)}
      control={<Checkbox checked={checked} onChange={(event, newChecked) => onChange(newChecked)} size="small" />}
    />
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="left">
      {checkbox}
    </Tooltip>
  ) : (
    checkbox
  );
}
