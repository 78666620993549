import { useMemo, useState } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';

import { EditName } from '@/components/form/EditName';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { DataActionButtons } from '@/components/form/DataActionButtons';
import { DetectorSaveDialog } from '@/detector-analysis/components/DetectorSaveDialog';
import { DetectorLoadDialog } from '@/detector-analysis/components/DetectorLoadDialog';
import { FormWrapper } from '@/components/form/FormWrapper';
import { useDetectorsQuery } from '@/detector-analysis/queries/useDetectorsQuery';
import { AnalysisType } from '@/types/AnalysisType';
import { DetectorFeature, DetectorsFeatureCollection } from '@/types/DetectorFeature';

type DetectorGroupFormProps = { index: number };

export function DetectorGroupForm({ index }: DetectorGroupFormProps) {
  const detectorGroups = useDetectorAnalysisStore((state) => state.detectorGroups);
  const detectorGroup = detectorGroups.features[index];
  const detectorGroupHighlightId = useDetectorAnalysisStore((state) => state.detectorGroupHighlightId);

  const { updateDetectorGroup, resetDetectorGroups, removeDetectorGroup, setDetectorGroupHighlightId } =
    useDetectorAnalysisStore((state) => state.actions);

  const { data } = useDetectorsQuery();

  const detectorsList = useMemo(() => {
    if (data) {
      return data.features.map((detector) => detector.properties.id);
    }

    return undefined;
  }, [data]);

  const handleChange = (value: DetectorsFeatureCollection | string[]) => {
    updateDetectorGroup({ properties: { detectors: value as DetectorsFeatureCollection } }, index);
    setDetectorGroupHighlightId(detectorGroup.properties.id);
  };

  const updateName = (newName: string) => {
    updateDetectorGroup({ properties: { name: newName } }, index);
  };

  const [detectorSaveDialog, setDetectorSaveDialog] = useState(false);
  const [detectorLoadDialog, setDetectorLoadDialog] = useState(false);

  const handleSaveDialog = () => {
    setDetectorSaveDialog(true);
  };

  const handleLoadDialog = () => {
    setDetectorLoadDialog(true);
  };

  const handleRemove = () => {
    if (detectorGroups.features.length === 1) {
      resetDetectorGroups();
    } else {
      removeDetectorGroup(index);
    }
  };

  return (
    <FormWrapper index={index} active={detectorGroup.id === detectorGroupHighlightId}>
      <>
        <Box sx={{ marginLeft: 4 }}>
          <EditName name={detectorGroups.features[index].properties.name} updateName={updateName} />
          <Autocomplete
            multiple
            groupBy={(id) =>
              data?.features.find((feature) => feature.properties.id === id)?.properties.location_description ||
              'Unbekannter Standort'
            }
            renderTags={(value) => (
              <span className="MuiAutocomplete-tag MuiAutocomplete-tagSizeMedium">
                {value.length} Detektor{value.length !== 1 ? 'en' : ''}
              </span>
            )}
            autoComplete
            value={detectorGroups.features[index].properties.detectors.features.map(
              (detector) => detector.properties.id,
            )}
            onChange={(event, value) => {
              const updatedDetectors: DetectorsFeatureCollection = {
                type: 'FeatureCollection',
                features: value
                  .map((id) => data?.features.find((feature) => feature.properties.id === id))
                  .filter((feature): feature is DetectorFeature => feature !== undefined),
              };
              handleChange(updatedDetectors);
            }}
            autoSelect
            options={detectorsList || []}
            onKeyDown={(event) => {
              if (
                detectorsList &&
                event.key === 'Enter' &&
                event.currentTarget.querySelector('input') === document.activeElement
              ) {
                handleChange([detectorsList[0]]);
                event.currentTarget.querySelector('input')?.blur();
              }
            }}
            onFocus={() => {
              setDetectorGroupHighlightId(detectorGroup.properties.id);
            }}
            renderInput={(params) => (
              <TextField
                placeholder={
                  detectorGroups.features[index].properties.detectors.features.length === 0
                    ? 'Detektoren auswählen'
                    : undefined
                }
                {...params}
              />
            )}
            sx={{ marginTop: 1, marginBottom: 3 }}
          />
        </Box>

        <DataActionButtons
          index={index}
          onSaveDialog={handleSaveDialog}
          onLoadDialog={handleLoadDialog}
          onRemove={handleRemove}
          analysisType={AnalysisType.DETECTOR}
        />

        <DetectorSaveDialog
          detectorGroup={detectorSaveDialog ? detectorGroup : undefined}
          onClose={() => setDetectorSaveDialog(false)}
        />
        <DetectorLoadDialog
          detectorGroup={detectorLoadDialog ? detectorGroup : undefined}
          onClose={() => setDetectorLoadDialog(false)}
          index={index}
        />
      </>
    </FormWrapper>
  );
}
