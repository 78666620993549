import { Box, css, Slider, Stack, styled } from '@mui/material';
import { useState } from 'react';

import SunIcon from '@/assets/icons/analysis/sun.svg?react';
import MoonIcon from '@/assets/icons/analysis/moon.svg?react';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)} ${theme.spacing(6)} ${theme.spacing(1)} };
  `,
);

const StyledSlider = styled(Slider)(({ theme, value }) => {
  const thumbsAreTooClose = (value as number[])[1] - (value as number[])[0] < 300;

  return css`
    .MuiSlider-valueLabelOpen,
    .MuiSlider-thumb,
    .MuiSlider-track {
      background-color: ${theme.palette.secondary.main};
      border: 1px solid ${theme.palette.secondary.main};
    }

    .MuiSlider-rail {
      background-color: ${theme.palette.secondary.main};
      border: 1px solid ${theme.palette.secondary.main};
    }

    .MuiSlider-thumb.Mui-focusVisible,
    .MuiSlider-thumb:hover {
      box-shadow: 0 0 0 12px #f5f5f5;
    }

    .MuiSlider-thumb .MuiSlider-valueLabel {
      transition: ${theme.transitions.create(['border-radius', 'transform'])};

      &::before {
        display: none;
      }
    }

    .MuiSlider-thumb[data-index='0'] {
      .MuiSlider-valueLabel {
        ${thumbsAreTooClose &&
        css`
          border-bottom-right-radius: 0;
          transform: translate(-50%, -100%);
        `}
      }
    }

    .MuiSlider-thumb[data-index='1'] {
      .MuiSlider-valueLabel {
        ${thumbsAreTooClose &&
        css`
          border-bottom-left-radius: 0;
          transform: translate(50%, -100%);
        `}
      }
    }
  `;
});

const MINIMUM_VALUE_DIFFERENCE = 60;
const MAXIMUM_VALUE = 1440;

type TimeRangeSliderProps = { timeRange: number[]; onSetTimeRange: (value: number[]) => void };

export function TimeRangeSlider({ timeRange, onSetTimeRange }: TimeRangeSliderProps) {
  const [value, setValue] = useState<number[]>(timeRange);

  const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
    let safeValues = newValue as number[];
    const values = newValue as number[];

    if (values[1] - values[0] < MINIMUM_VALUE_DIFFERENCE) {
      if (activeThumb === 0) {
        if (values[0] + MINIMUM_VALUE_DIFFERENCE > MAXIMUM_VALUE) {
          safeValues = [MAXIMUM_VALUE - MINIMUM_VALUE_DIFFERENCE, MAXIMUM_VALUE];
        } else {
          safeValues = [values[0], values[0] + MINIMUM_VALUE_DIFFERENCE];
        }
      } else if (activeThumb === 1) {
        if (values[1] - MINIMUM_VALUE_DIFFERENCE < 0) {
          safeValues = [0, MINIMUM_VALUE_DIFFERENCE];
        } else {
          safeValues = [values[1] - MINIMUM_VALUE_DIFFERENCE, values[1]];
        }
      }
    }
    setValue(safeValues);
  };

  const handleChangeCommitted = (event: Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {
    onSetTimeRange(newValue as number[]);
  };

  return (
    <>
      <StyledBox>
        <StyledSlider
          valueLabelDisplay="on"
          valueLabelFormat={(sliderValue) => {
            const hours = Math.floor(sliderValue / 60);
            const minutes = sliderValue % 60;

            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
          }}
          value={value}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          step={15}
          min={0}
          max={1439}
          disableSwap
        />
      </StyledBox>
      <Stack direction="row" justifyContent="space-between" mt={1} px={2}>
        <SunIcon />
        <MoonIcon />
      </Stack>
    </>
  );
}
