import { Layer, Source } from 'react-map-gl';

import { GEOSERVER_MUNICH } from '@/modules/GeoServer';
import { FeatureLayerType } from '@/types/FeatureLayerType';

export function MunichConstructionsLayer() {
  return (
    <Source
      id={FeatureLayerType.MUNICH_CONSTRUCTIONS}
      type="raster"
      tileSize={256}
      tiles={[
        GEOSERVER_MUNICH.wms({
          layers: 'gsm:baustellen_6_weeks',
        }),
      ]}
    >
      <Layer id={FeatureLayerType.MUNICH_CONSTRUCTIONS} type="raster" />
    </Source>
  );
}
