/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FcdResultValue = {
  timestamp?: string;
  type?: FcdResultValue.type;
  value?: number;
};
export namespace FcdResultValue {
  export enum type {
    V = 'v',
    LOS = 'los',
    TIMELOSS = 'timeloss',
    TRAVELTIMEHERE = 'traveltimehere',
    TRAVELTIMESELF = 'traveltimeself',
    CONFIDENCE = 'confidence',
    VFREE = 'vfree',
  }
}
