import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { DurationType } from '@/types/DurationType';

type DurationToggleButtonGroupProps = {
  value: DurationType;
  onChange: (value: DurationType) => void;
};

export function DurationToggleButtonGroup({ value, onChange }: DurationToggleButtonGroupProps) {
  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      onChange={(event, newValue) => {
        if (newValue) {
          onChange(newValue);
        }
      }}
      value={value}
      exclusive
    >
      <ToggleButton value={DurationType.SEC}>Sekunden</ToggleButton>
      <ToggleButton value={DurationType.MIN}>Minuten</ToggleButton>
    </ToggleButtonGroup>
  );
}
