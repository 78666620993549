import { useState } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography, css, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

import { DayCategory } from '@/types/DayCategory';
import { DateRangeLength } from '@/modules/DateRangeLength';
import { ToggleButtonGroupWithMargin } from '@/components/ToggleButtonGroupWithMargin';

const StyledBox = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid #dfdfdf;
    padding-top: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
  `,
);

const DAY_CATEGORIES = [
  {
    value: [
      DayCategory.MONDAY,
      DayCategory.TUESDAY,
      DayCategory.WEDNESDAY,
      DayCategory.THURSDAY,
      DayCategory.FRIDAY,
    ].sort(),
    label: 'Mo-Fr',
  },
  { value: [DayCategory.SATURDAY, DayCategory.SUNDAY].sort(), label: 'Sa-So' },
  {
    value: Object.values(DayCategory).sort(),
    label: 'Mo-So',
  },
];

type DayCategoryToggleButtonProps = {
  dayCategories: DayCategory[];
  onSetDayCategories: (detectorDayCategories: DayCategory[]) => void;
  dateRanges: DateRange<Dayjs>[];
};

export function DayCategoryToggleButtons({
  dayCategories,
  onSetDayCategories,
  dateRanges,
}: DayCategoryToggleButtonProps) {
  const { t } = useTranslation();

  const [customDayCategories, setCustomDayCategories] = useState(
    !DAY_CATEGORIES.some(({ value }) => value.toString() === dayCategories.toString()),
  );

  if (DateRangeLength.get(dateRanges[0], 'day')! <= 7) {
    return null;
  }

  return (
    <StyledBox>
      <Typography mb={2}>Tagesauswahl</Typography>
      <ToggleButtonGroupWithMargin
        color="secondary"
        size="small"
        fullWidth
        value={customDayCategories ? 'CUSTOM' : dayCategories.toString()}
        onChange={(event, value) => {
          if (value === 'CUSTOM') {
            setCustomDayCategories(true);
          } else {
            setCustomDayCategories(false);
            onSetDayCategories(value.split(','));
          }
        }}
        exclusive
      >
        {DAY_CATEGORIES.map(({ label, value }) => (
          <ToggleButton key={value.toString()} value={value.toString()}>
            {label}
          </ToggleButton>
        ))}

        <ToggleButton value="CUSTOM">Manuell</ToggleButton>
      </ToggleButtonGroupWithMargin>

      {customDayCategories && (
        <ToggleButtonGroup
          color="secondary"
          size="small"
          fullWidth
          sx={{ mt: 1 }}
          value={dayCategories}
          onChange={(event, value) => onSetDayCategories(value)}
        >
          {Object.values(DayCategory).map((day) => (
            <ToggleButton key={day} value={day}>
              {t(`DayCategory.${day}`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </StyledBox>
  );
}
