import { css, Grid, Paper, styled, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { HistoricalSlider } from '@/monitoring/components/HistoricalSlider';
import { HistoricalControl } from '@/monitoring/components/HistoricalControl';
import { useMonitoringStore } from '@/stores/useMonitoringStore';
import { MonitoringMode } from '@/types/MonitoringMode';
import { useMapContext } from '@/stores/useMapContext';
import { FcdLayerType } from '@/types/FcdLayerType';
import { LiveTooltip } from '@/monitoring/components/LiveTooltip';
import { HistoricalTooltip } from '@/monitoring/components/HistoricalTooltip';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { FeatureLayers } from '@/map/components/feature-layers/FeatureLayers';
import { BottomControls } from '@/monitoring/components/BottomControls';
import { FcdLegend } from '@/map/components/fcd/FcdLegend';
import { AnalysisType } from '@/types/AnalysisType';
import { HistoricalDateRanges } from '@/modules/HistoricalDateRanges';

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: ${theme.spacing(1)};
    max-width: ${theme.spacing(100)};
    padding: ${theme.spacing(2)};
  `,
);

type MonitoringMapContentProps = {
  controls?: boolean;
};

export function MonitoringMapContent({ controls }: MonitoringMapContentProps) {
  const { setLayers } = useMapContext((state) => state.actions);
  const date = useMonitoringStore((state) => state.dateTime);
  const mode = useMonitoringStore((state) => state.mode);
  const tooltip = useMonitoringStore((state) => state.tooltip);
  const { updateDate } = useMonitoringStore((state) => state.actions);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLayers({ fcd: FcdLayerType.LEVEL_OF_SERVICE });
  }, [setLayers]);

  return (
    <>
      <FeatureLayers types={Object.values(FeatureLayerType)} />

      <BottomControls>
        {controls ? (
          <Tooltip
            open={tooltip}
            title={{ [MonitoringMode.LIVE]: <LiveTooltip />, [MonitoringMode.HISTORICAL]: <HistoricalTooltip /> }[mode]}
            placement={({ [MonitoringMode.LIVE]: 'top-start', [MonitoringMode.HISTORICAL]: 'top-end' } as const)[mode]}
          >
            <StyledPaper>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md="auto">
                  <HistoricalControl />
                  <DatePicker
                    open={open}
                    disabled={mode !== MonitoringMode.HISTORICAL}
                    disableOpenPicker={mode !== MonitoringMode.HISTORICAL}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    format="DD.MM.YYYY"
                    onChange={(newDate: Dayjs | null) => {
                      if (newDate) {
                        updateDate(newDate);
                      }
                    }}
                    slotProps={{
                      textField: {
                        variant: 'outlined',
                        label: 'Datum',
                        fullWidth: true,
                        size: 'small',
                        margin: 'none',
                        onClick: () => setOpen(true),
                      },
                    }}
                    value={mode === MonitoringMode.HISTORICAL ? date : dayjs()}
                    {...(mode === MonitoringMode.HISTORICAL && {
                      maxDate: dayjs().startOf('day').subtract(1, 'day'),
                    })}
                    shouldDisableYear={(year) =>
                      !HistoricalDateRanges.isYearAvailable({ type: AnalysisType.FCD, year })
                    }
                    shouldDisableDate={(day) =>
                      !HistoricalDateRanges.isDateAvailable({ type: AnalysisType.FCD, date: day })
                    }
                  />
                </Grid>

                <Grid item xs={12} md="auto" flexGrow={{ md: 1 }}>
                  <HistoricalSlider />
                </Grid>
              </Grid>
            </StyledPaper>
          </Tooltip>
        ) : null}

        <FcdLegend />
      </BottomControls>
    </>
  );
}
