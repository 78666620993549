import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, css, lighten, styled } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';

import { HotspotFeature } from '@/types/HotspotFeature';
import { useHotspotEventsQuery } from '@/hotspots/queries/useHotspotEventsQuery';
import { HotspotEventFeature } from '@/types/HotspotEventFeature';
import { useHotspotsStore } from '@/stores/useHotspotsStore';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { NumberFormat } from '@/modules/NumberFormat';

const COLUMNS: GridColDef<HotspotEventFeature>[] = [
  {
    field: 'id',
    headerName: 'ID',
    valueGetter: (value, feature) => feature.properties.id,
    width: 120,
  },
  {
    field: 'starttime',
    headerName: 'Beginn',
    valueGetter: (value, feature) => dayjs(feature.properties.starttime),
    valueFormatter: (value: Dayjs) => value.format('DD.MM.YYYY HH:mm'),
    width: 136,
  },
  {
    field: 'endtime',
    headerName: 'Ende',
    valueGetter: (value, feature) => dayjs(feature.properties.endtime),
    valueFormatter: (value: Dayjs) => value.format('DD.MM.YYYY HH:mm'),
    width: 136,
  },
  {
    field: 'avg_length',
    headerName: 'Ø Länge',
    type: 'number',
    valueGetter: (value, feature) => feature.properties.avg_length / 1000,
    valueFormatter: (value) => NumberFormat.lengthKm(value),
    minWidth: 80,
    maxWidth: 144,
    flex: 1,
  },
  {
    field: 'duration',
    headerName: 'Dauer',
    type: 'number',
    valueGetter: (value, feature) => {
      const [hours, minutes] = feature.properties.duration.split(':').map(Number);

      return hours * 60 + minutes;
    },
    valueFormatter: (value) => `${value} min`,
    minWidth: 80,
    maxWidth: 144,
    flex: 1,
  },
];

const StyledDataGridPro = styled(DataGridPro)(
  () => css`
    .MuiDataGrid-row.Mui-selected {
      &,
      &.Mui-hovered,
      &:hover {
        background-color: ${lighten(ROUTE_COLOR[1], 0.85)};
      }
    }

    .MuiDataGrid-cell:focus,
    .MuiDataGrid-cell:focus-within {
      outline: none;
    }
  `,
) as typeof DataGridPro;

type HotspotsDataGridDetailProps = { feature: HotspotFeature };

export function HotspotsDataGridDetail({ feature }: HotspotsDataGridDetailProps) {
  const hotspotEventFeature = useHotspotsStore((state) => state.hotspotEventFeature);
  const { setHotspotFeature, setHotspotEventFeature } = useHotspotsStore((state) => state.actions);

  const { data, isLoading } = useHotspotEventsQuery({ ids: feature.properties.event_ids });

  useEffect(
    () => () => {
      setHotspotEventFeature(undefined);
    },
    [setHotspotEventFeature],
  );

  return (
    <Box
      sx={{
        backgroundColor: 'common.white',
        paddingLeft: '50px',
      }}
    >
      <Box
        sx={{
          borderLeft: (theme) => `2px solid ${theme.palette.primary.main}`,
          borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`,
        }}
      >
        <StyledDataGridPro
          loading={isLoading}
          density="compact"
          columns={COLUMNS}
          rows={data?.features || []}
          autoHeight
          getRowId={(row) => row.properties.id}
          rowSelectionModel={
            hotspotEventFeature &&
            data &&
            data.features.find(({ properties: { id } }) => id === hotspotEventFeature.properties.id)
              ? [hotspotEventFeature.properties.id]
              : []
          }
          onRowSelectionModelChange={(selection) => {
            setHotspotFeature(feature);
            setHotspotEventFeature(data?.features.find(({ properties: { id } }) => id === selection[0]));
          }}
        />
      </Box>
    </Box>
  );
}
