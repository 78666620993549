import { FormGroup } from '@mui/material';

import { FeatureLayerType } from '@/types/FeatureLayerType';
import { LayerControlCheckbox } from '@/monitoring/components/LayerControlCheckbox';
import { useMapContext } from '@/stores/useMapContext';

type FeatureLayerTypeControlProps = { types: FeatureLayerType[] };

export function FeatureLayerTypeControl({ types }: FeatureLayerTypeControlProps) {
  const layers = useMapContext((state) => state.layers);
  const { setLayers } = useMapContext((state) => state.actions);

  return (
    <FormGroup>
      {types.map((layer) => (
        <LayerControlCheckbox
          key={layer}
          value={layer}
          checked={layers.features[layer]}
          onChange={(checked) => {
            setLayers({ features: { ...layers.features, [layer]: checked } });
          }}
          i18nKey="FeatureLayerType"
          tooltip={
            {
              [FeatureLayerType.STRATEGY]: undefined,
              [FeatureLayerType.OBSTRUCTION]: undefined,
              [FeatureLayerType.MUNICH_CONSTRUCTIONS]: 'Baustellen in den nächsten 6 Wochen',
              [FeatureLayerType.TRAFFIC_LIGHT]: undefined,
            }[layer]
          }
        />
      ))}
    </FormGroup>
  );
}
