import { Layer } from 'react-map-gl';

import { MapBefore } from '@/map/types/MapBefore';

const BUILDING_EXTRUSION_LAYER_MIN_ZOOM = 17;

export function BuildingExtrusionLayer() {
  return (
    <Layer
      id="EXTRUSION"
      type="fill-extrusion"
      beforeId={MapBefore.CONTENT}
      source="composite"
      source-layer="building"
      filter={['==', 'extrude', 'true']}
      minzoom={BUILDING_EXTRUSION_LAYER_MIN_ZOOM}
      paint={{
        'fill-extrusion-color': '#fddc80',
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          BUILDING_EXTRUSION_LAYER_MIN_ZOOM,
          0,
          BUILDING_EXTRUSION_LAYER_MIN_ZOOM + 0.05,
          ['get', 'height'],
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'],
          ['zoom'],
          BUILDING_EXTRUSION_LAYER_MIN_ZOOM,
          0,
          BUILDING_EXTRUSION_LAYER_MIN_ZOOM + 0.05,
          ['get', 'min_height'],
        ],
        'fill-extrusion-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          BUILDING_EXTRUSION_LAYER_MIN_ZOOM,
          0,
          BUILDING_EXTRUSION_LAYER_MIN_ZOOM + 0.05,
          0.6,
        ],
      }}
    />
  );
}
