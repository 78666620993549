import dayjs from 'dayjs';
import { Dash, PlotData } from 'plotly.js';
import { DurationUnitType } from 'dayjs/plugin/duration';
import { forwardRef, useMemo } from 'react';
import { CircularProgress } from '@mui/material';

import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { DurationType } from '@/types/DurationType';
import { Plot } from '@/components/plot/Plot';
import { getFcdParameterTypeFormatOptionsForTimeRangePlot } from '@/fcd-analysis/modules/FcdParameterTypeFormatOptions';
import { PlotRef } from '@/types/PlotRef';
import { useExportToImageConfig } from '@/hooks/useExportToImageConfig';
import { TimeRangePlot } from '@/modules/TimeRangePlot';
import { usePlotTitle } from '@/hooks/usePlotTitle';
import { useExportData } from '@/hooks/useExportData';
import { getDetectorParameterTypeFormatOptionsForTimeRangePlot } from '@/detector-analysis/modules/DetectorParameterTypeFormatOptions';
import { AnalysisType } from '@/types/AnalysisType';
import { AnalysisPlotComposition } from '@/types/AnalysisPlotComposition';

const slotTypes = [
  ['solid', 'dash', 'dot'],
  ['solid', 'dash', 'dot'],
  ['solid', 'dash', 'dot'],
];

const durationRange = [
  { max: 8 * 60, step: 60, suffix: ':00', unit: 'hours' }, // bis 8 Stunden, 1h steps
  { max: 12 * 60, step: 3 * 60, suffix: ':00', unit: 'hours' }, // bis 12 stunden, 3h steps
  { max: 24 * 60, step: 6 * 60, suffix: ':00', unit: 'hours' }, // bis 1 Tag, 6h steps
  { max: 2 * 24 * 60, step: 12 * 60, suffix: ':00', unit: 'hours' }, // bis 2 Tage, 12h steps
  { max: 8 * 24 * 60, step: 24 * 60, suffix: ' Tag', pluralSuffix: 'e', unit: 'days' }, // bis 8 Tage, 1d steps
  { max: 16 * 24 * 60, step: 2 * 24 * 60, suffix: ' Tage', unit: 'days' }, // bis 16 Tage, 2d steps
  { max: 32 * 24 * 60, step: 7 * 24 * 60, suffix: ' Woche', pluralSuffix: 'n', unit: 'weeks' }, // bis 16 Tage, 1w steps
  { max: 64 * 24 * 60, step: 14 * 24 * 60, suffix: ' Wochen', unit: 'weeks' }, // bis 16 Tage, 2w steps
  { max: 128 * 24 * 60, step: 28 * 24 * 60, suffix: ' Wochen', unit: 'weeks' }, // bis 16 Tage, 4w steps
  { max: 256 * 24 * 60, step: 56 * 24 * 60, suffix: ' Wochen', unit: 'weeks' }, // bis 16 Tage, 8w steps
];

type MultiTimeRangePlotProps = {
  composition: AnalysisPlotComposition;
  durationType: DurationType;
};

export const MultiTimeRangePlot = forwardRef<PlotRef, MultiTimeRangePlotProps>(({ composition, durationType }, ref) => {
  const { analysisType, diagramParameters, queryResult } = composition;

  const config = useExportToImageConfig(composition);

  const { decimals, unit, multiplier } =
    analysisType === AnalysisType.FCD
      ? getFcdParameterTypeFormatOptionsForTimeRangePlot({
          parameterType: diagramParameters.parameterType,
          durationType,
        })
      : getDetectorParameterTypeFormatOptionsForTimeRangePlot({
          parameterType: diagramParameters.parameterType,
          interval: diagramParameters.interval,
        });

  const title = usePlotTitle({ composition, unit });

  const data = useMemo<Partial<PlotData>[] | undefined>(() => {
    if (!queryResult.data?.request?.timeParameters) {
      return undefined;
    }

    return queryResult.data?.request?.timeParameters
      ?.map((slot) => {
        const start = dayjs(slot.start);
        const end = dayjs(slot.end);

        return queryResult.data?.results!.map((result) => {
          const resultValue = Object.values(Object.values(result.values!)[0]);

          return resultValue
            .filter(
              (value: any) => dayjs(value.timestamp).isBetween(start, end) || dayjs(value.timestamp).isSame(start),
            )
            .map((value: any) => ({
              ...value,
              duration: dayjs.duration(dayjs(value.timestamp).diff(start)).toISOString(),
            }))
            .sort((a, b) => dayjs.duration(a.duration).asMilliseconds() - dayjs.duration(b.duration).asMilliseconds());
        });
      })
      .map((slot, indexSlot): Partial<PlotData>[] =>
        slot!.map((innerSlot, indexInnerSlot) => ({
          x: innerSlot?.map((v) => dayjs.duration(v.duration!).asMinutes()),
          y: innerSlot?.map((v) => Math.round(v.value! * multiplier * 10 ** decimals) / 10 ** decimals || v.value),
          mode: 'lines',
          type: 'scatter',
          name: `${
            analysisType === AnalysisType.FCD
              ? diagramParameters.routes.features[indexInnerSlot].properties.name
              : diagramParameters.detectorGroups.features[indexInnerSlot].properties.name
          }}: ${dayjs(queryResult.data.request?.timeParameters![indexSlot].start)
            .tz('Europe/Berlin')
            .format('DD.MM.YYYY')}-<br />${dayjs(queryResult.data.request?.timeParameters![indexSlot].end)
            .tz('Europe/Berlin')
            .format('DD.MM.YYYY')}`,
          line: { color: ROUTE_COLOR[indexInnerSlot], dash: slotTypes[indexInnerSlot][indexSlot] as Dash },

          hovertemplate: `%{customdata}<br />%{y} ${unit || ''}`,
          yhoverformat: `.${decimals}f`,
          customdata: innerSlot?.map((v) => dayjs(v.timestamp!).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm')),
        })),
      )
      .flat();
  }, [
    analysisType,
    decimals,
    diagramParameters,
    multiplier,
    queryResult.data?.request?.timeParameters,
    queryResult.data?.results,
    unit,
  ]);

  const xaxisTicks = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const maxDuration = Math.max(...(data.map((v) => v.x).flat() as number[]));
    const { step, suffix, pluralSuffix, unit: durationUnit } = durationRange.find((v) => maxDuration < v.max)!;

    const tickvals = [step];
    const ticktext = [`${dayjs.duration(step, 'minutes').as(durationUnit as DurationUnitType)}${suffix}`];

    for (let i = 0; maxDuration > tickvals[i]; i += 1) {
      tickvals.push((i + 2) * step);
      ticktext.push(
        `${dayjs.duration((i + 2) * step, 'minutes').as(durationUnit as DurationUnitType)}${suffix}${
          pluralSuffix || ''
        }`,
      );
    }

    return { tickvals, ticktext };
  }, [data]);

  const levelOfServicePlotLayout = useMemo(
    () => TimeRangePlot.getLevelOfServiceLayout({ parameterType: diagramParameters.parameterType, data }),
    [data, diagramParameters.parameterType],
  );

  useExportData({ ref, data });

  return !queryResult.isLoading && data ? (
    <Plot
      data={data}
      layout={{
        ...levelOfServicePlotLayout,
        title: { text: title },
        xaxis: {
          ...xaxisTicks,
        },
        yaxis: {
          ...levelOfServicePlotLayout?.yaxis,
        },
      }}
      config={config}
    />
  ) : (
    <CircularProgress />
  );
});
