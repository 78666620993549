import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/modules/QueryKey';
import { HotspotsService } from '@/modules/HotspotsService';

export const useHotspotsQuery = () =>
  useQuery({
    queryKey: QueryKey.hotspots.all,
    queryFn: () => HotspotsService.getHotspots(),
    refetchOnWindowFocus: false,
  });
