import { useMap } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

type UseMapImagesProps = {
  images: {
    name: string;
    url: string;
    width?: number;
    height?: number;
    options?: Partial<Parameters<InstanceType<typeof mapboxgl.Map>['addImage']>[2]>;
  }[];
};

export const useMapImages = ({ images }: UseMapImagesProps) => {
  const { current: map } = useMap();

  if (map) {
    images.forEach(({ name, url, width, height, options }) => {
      if (!map.hasImage(name)) {
        const extension = url.split('.').pop();

        if (extension === 'svg') {
          const img = new Image(2 * width!, 2 * height!);
          img.onload = () => !map.hasImage(name) && map.addImage(name, img, { pixelRatio: 2, ...options });
          img.src = url;
        } else {
          map.loadImage(url, (error, image) => {
            if (error) throw error;
            if (image && !map.hasImage(name)) {
              map.addImage(name, image, options);
            }
          });
        }
      }
    });
  }
};
