import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';

import { FeatureLayerType } from '@/types/FeatureLayerType';
import { ObstructionType } from '@/types/ObstructionType';
import { useMapImages } from '@/map/hooks/useMapImages';
import AbnormalTrafficIcon from '@/map/assets/traffic-report/abnormal-traffic.svg?url';
import AccidentIcon from '@/map/assets/traffic-report/accident.svg?url';
import ConstructionWorksIcon from '@/map/assets/traffic-report/construction-works.svg?url';
import ConditionsIcon from '@/map/assets/traffic-report/conditions.svg?url';
import PublicEventIcon from '@/map/assets/traffic-report/public-event.svg?url';
import ReroutingManagementIcon from '@/map/assets/traffic-report/rerouting-management.svg?url';
import WarningIcon from '@/map/assets/traffic-report/warning.svg?url';
import EnvironmentalObstructionIcon from '@/map/assets/traffic-report/environmental-obstruction.svg?url';
import RoadOrCarriagewayOrLaneManagementIcon from '@/map/assets/traffic-report/road-or-carriageway-or-lane-management.svg?url';
import { useMonitoringFeatureServSearchParams } from '@/monitoring/hooks/useMonitoringFeatureServSearchParams';
import { PG_FEATURESERV } from '@/setup/pg-featureserv';

const ICONS: Record<ObstructionType, string> = {
  [ObstructionType.ABNORMAL_TRAFFIC]: AbnormalTrafficIcon,
  [ObstructionType.ACCIDENT]: AccidentIcon,
  [ObstructionType.CONDITIONS]: ConditionsIcon,
  [ObstructionType.CONSTRUCTION_WORKS]: ConstructionWorksIcon,
  [ObstructionType.ENVIRONMENTAL_OBSTRUCTION]: EnvironmentalObstructionIcon,
  [ObstructionType.MAINTENANCE_WORKS]: ConstructionWorksIcon,
  [ObstructionType.PUBLIC_EVENT]: PublicEventIcon,
  [ObstructionType.REROUTING_MANAGEMENT]: ReroutingManagementIcon,
  [ObstructionType.ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT]: RoadOrCarriagewayOrLaneManagementIcon,
};
const ICON_DIMENSIONS = { width: 36, height: 36 };
const TRAFFIC_REPORT_FALLBACK_ICON = 'TRAFFIC_REPORT_FALLBACK_ICON';

export function ObstructionLayer() {
  const [timestamp, setTimestamp] = useState<string>(dayjs().toISOString());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(dayjs().toISOString());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useMapImages({
    images: [
      ...Object.values(ObstructionType).map((name) => ({
        name,
        url: ICONS[name],
        ...ICON_DIMENSIONS,
      })),

      { name: TRAFFIC_REPORT_FALLBACK_ICON, url: WarningIcon, ...ICON_DIMENSIONS },
    ],
  });

  const { urlSearchParams } = useMonitoringFeatureServSearchParams();
  urlSearchParams.append('timestamp', timestamp);
  const url = `${PG_FEATURESERV}/collections/frontend.obstruction/items.json?${urlSearchParams}`;

  return (
    <Source type="geojson" id={FeatureLayerType.OBSTRUCTION} data={url} promoteId="id">
      <Layer
        id={FeatureLayerType.OBSTRUCTION}
        type="symbol"
        filter={['!', ['has', 'point_count']]}
        layout={{
          'icon-image': [
            'match',
            ['get', 'type'],
            'ConstructionWorks',
            ObstructionType.CONSTRUCTION_WORKS,
            'AbnormalTraffic',
            ObstructionType.ABNORMAL_TRAFFIC,
            'Accident',
            ObstructionType.ACCIDENT,
            'MaintenanceWorks',
            ObstructionType.MAINTENANCE_WORKS,
            'ReroutingManagement',
            ObstructionType.REROUTING_MANAGEMENT,
            'Conditions',
            ObstructionType.ENVIRONMENTAL_OBSTRUCTION,
            'PublicEvent',
            ObstructionType.PUBLIC_EVENT,
            TRAFFIC_REPORT_FALLBACK_ICON,
          ],
          'icon-allow-overlap': true,
        }}
      />
    </Source>
  );
}
