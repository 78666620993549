import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { ApiError, FcdAnalysisRequest, FcdAnalysisResponse, TempusService } from '@/modules/api';
import { QueryKey } from '@/modules/QueryKey';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { DiagramType } from '@/types/DiagramType';
import { FcdParameterType } from '@/types/FcdParameterType';
import { AnalysisValueTypes } from '@/types/AnalysisValueTypes';
import { DayCategory } from '@/types/DayCategory';
import { isNonNullable } from '@/utils/isNonNullable';

const transformMinutesToUtcTimeString = (minutes: number) =>
  dayjs().startOf('day').add(minutes, 'minutes').tz('UTC').format('HH:mm');

export const useFcdAnalysis = () => {
  const diagramType = useFcdAnalysisStore((state) => state.diagramType);
  const diagramParameters = useFcdAnalysisStore((state) => state.diagramParameters);

  const fcdRequest = useMemo<FcdAnalysisRequest | undefined>(() => {
    if (
      !diagramParameters?.dateRanges.every((dateRanges) =>
        dateRanges.every((date) => isNonNullable(date) && date.isValid()),
      )
    ) {
      return undefined;
    }

    return {
      ...(diagramParameters.routes.features.length > 1 && { diagramType: DiagramType.GRAPH }),

      routesLength: diagramParameters.routes.features.length,
      analysisType: [FcdParameterType.TIMELOSS, FcdParameterType.TRAVELTIMESELF].includes(
        diagramParameters.parameterType,
      )
        ? FcdAnalysisRequest.analysisType.FCD_TIME
        : FcdAnalysisRequest.analysisType.FCD_ANALYSIS,
      valueTypes: [diagramParameters.parameterType.toLowerCase()] as AnalysisValueTypes,
      mode: FcdAnalysisRequest.mode.AVG,
      interval: diagramParameters.interval,
      aggregate: false,
      timeParameters: diagramParameters.dateRanges.map((dateRange) => ({
        start: dateRange[0]!.startOf('day').toISOString(),
        end: dateRange[1]!.endOf('day').toISOString(),
        ...(!Object.values(DayCategory).every((elem) => diagramParameters.dayCategories.includes(elem)) && {
          dayCategories: diagramParameters.dayCategories,
        }),
        ...(!(diagramParameters.timeRange[0] === 0 && diagramParameters.timeRange[1] === 1439) && {
          timeRanges: [
            {
              from: transformMinutesToUtcTimeString(diagramParameters.timeRange[0]),
              to: transformMinutesToUtcTimeString(diagramParameters.timeRange[1]),
            },
          ],
        }),
      })),
      routes:
        diagramType === DiagramType.WAYTIME &&
        diagramParameters.dateRanges.length === 1 &&
        diagramParameters.routes.features.length === 1
          ? (diagramParameters.routes.features
              .map((route) => route.geometry && route.properties.lineStringProperties?.map(({ hashId }) => [hashId]))
              .flat() as string[][])
          : (diagramParameters.routes.features.map(
              (route) => route.geometry && route.properties.lineStringProperties?.map(({ hashId }) => hashId),
            ) as string[][]),
    };
  }, [diagramParameters, diagramType]);

  return useQuery<FcdAnalysisResponse, ApiError, FcdAnalysisResponse>({
    queryKey: QueryKey.fcdAnalysis.getFcd(fcdRequest!),
    queryFn: () =>
      TempusService.getFcd({
        requestBody: fcdRequest!,
      }),

    refetchOnWindowFocus: false,
    retry: false,
    gcTime: 6000000,
    staleTime: 6000000,
    enabled: !!fcdRequest,
  });
};
