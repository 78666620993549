import { FcdLayerType } from '@/types/FcdLayerType';
import { LegendControl } from '@/monitoring/components/LegendControl';
import { useMapContext } from '@/stores/useMapContext';

export const HOTSPOTS_LEGEND = [
  {
    value: 1,
    label: '1',
    color: '#f6b48f',
  },
  {
    value: 2,
    label: '2',
    color: '#f37651',
  },
  {
    value: 3,
    label: '3',
    color: '#e13342',
  },
  {
    value: 4,
    label: '4',
    color: '#ad1759',
  },
  {
    value: 5,
    label: '5',
    color: '#701f57',
  },
  {
    value: 6,
    label: '≥ 6',
    color: '#35193e',
  },
];

export function HotspotsLegend() {
  const mapLayers = useMapContext((state) => state.layers);

  return mapLayers.fcd !== FcdLayerType.NONE ? <LegendControl title="Hotspots" legend={HOTSPOTS_LEGEND} /> : null;
}
