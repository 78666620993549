import dayjs from 'dayjs';

import { useMonitoringStore } from '@/stores/useMonitoringStore';
import { MonitoringMode } from '@/types/MonitoringMode';

export const useMonitoringFeatureServSearchParams = () => {
  const mode = useMonitoringStore((state) => state.mode);
  const dateTime = useMonitoringStore((state) => state.dateTime);

  const filterDateTime = mode === MonitoringMode.LIVE ? dayjs() : dateTime;

  return {
    urlSearchParams: new URLSearchParams({
      filter: `(valid_from IS NULL OR valid_from <= '${filterDateTime}') AND (valid_to IS NULL OR valid_to >= '${filterDateTime}')`,
    }),
  };
};
