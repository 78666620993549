import { Typography } from '@mui/material';

import { TooltipList } from '@/monitoring/components/TooltipList';
import { TooltipListItem } from '@/monitoring/components/TooltipListItem';

export function LiveTooltip() {
  return (
    <>
      <Typography>Monitoring Modus</Typography>
      <TooltipList>
        <TooltipListItem>LIVE Ansicht des aktuellen Verkehrsgeschehens.</TooltipListItem>
        <TooltipListItem>
          Historische Ansicht des Verkehrsgeschehens manuell anwählbar. Anschließende Datumsauswahl erforderlich.
        </TooltipListItem>
      </TooltipList>
    </>
  );
}
