import { FormControl, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LayerControlRadio } from '@/monitoring/components/LayerControlRadio';
import { SidebarItemContainer } from '@/components/sidebar/SidebarItemContainer';
import { AnalysisType } from '@/types/AnalysisType';
import { DetectorParameterType } from '@/types/DetectorParameterType';
import { FcdParameterType } from '@/types/FcdParameterType';

type ParameterFormProps = {
  parameterType: string;
  i18nKey: string;
  selectedParameter: FcdParameterType | DetectorParameterType | undefined;
  onSetParameterType: (parameterType: FcdParameterType | DetectorParameterType) => void;
};

export function ParameterForm({ parameterType, i18nKey, selectedParameter, onSetParameterType }: ParameterFormProps) {
  const { t } = useTranslation();

  return (
    <SidebarItemContainer>
      <FormControl>
        <RadioGroup value={selectedParameter || null}>
          {Object.values(parameterType === AnalysisType.DETECTOR ? DetectorParameterType : FcdParameterType).map(
            (item: FcdParameterType | DetectorParameterType) => (
              <LayerControlRadio
                key={item}
                value={item}
                checked={item === selectedParameter}
                onChange={(checked) => {
                  if (checked) {
                    onSetParameterType(item);
                  }
                }}
                i18nKey={i18nKey}
                tooltip={parameterType === AnalysisType.DETECTOR ? undefined : t(`FcdParameterTypeDescription.${item}`)}
              />
            ),
          )}
        </RadioGroup>
      </FormControl>
    </SidebarItemContainer>
  );
}
