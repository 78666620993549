import { create } from 'zustand';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';
import { createRef } from 'react';

import { DetectorAnalysisState } from '@/types/DetectorAnalysisState';
import { DayCategory } from '@/types/DayCategory';
import { DetectorAnalysisRequest } from '@/modules/api';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { DetectorGroupFeature } from '@/types/DetectorGroupFeature';

export const createDetectorGroupFeature = (index: number = 0): DetectorGroupFeature => {
  const id = uuidv4();

  return {
    id,
    type: 'Feature',
    geometry: null,
    properties: {
      id,
      name: `Detektorgruppe ${index + 1}`,
      detectors: {
        type: 'FeatureCollection',
        features: [],
      },
    },
  };
};

export const useDetectorAnalysisStore = create<DetectorAnalysisState>()((set, get) => {
  const initialDetectorGroup: DetectorGroupFeature = createDetectorGroupFeature();

  return {
    detectorStep: AnalysisFormStep.LOCATION,
    detectorGroups: { type: 'FeatureCollection', features: [initialDetectorGroup] },
    parameterType: undefined,
    detectorGroupHighlightId: initialDetectorGroup.id,
    dateRanges: [[null, null]],
    dayCategories: Object.values(DayCategory).sort(),
    interval: DetectorAnalysisRequest.interval.MIN_15,
    timeRange: [0, 1439],
    showContexts: false,
    contexts: { type: 'FeatureCollection', features: [] },
    validateDetectorForm: false,
    diagramParameters: undefined,
    detectorDiagramVisibleDateRange: undefined,
    showDiagram: false,

    mapboxDraw: createRef<MapboxDraw>(),
    isDrawing: false,
    drawnPolygon: undefined,

    actions: {
      setDetectorFormStep: (detectorStep) => set({ detectorStep }),

      setDetectorParameterType: (parameterType) => set({ parameterType }),

      addDetectors: (detectors) =>
        set((state) => {
          const { detectorGroupHighlightId } = state;

          const groupToUpdateIndex = state.detectorGroups.features.findIndex(
            (group) => group.id === detectorGroupHighlightId,
          );
          if (groupToUpdateIndex === -1) {
            return {};
          }

          const updatedGroup = { ...state.detectorGroups.features[groupToUpdateIndex] };
          if (!updatedGroup.properties.detectors.features) {
            updatedGroup.properties.detectors.features = [];
          }

          if (detectors) {
            updatedGroup.properties.detectors.features = [...updatedGroup.properties.detectors.features, ...detectors];
          }

          const updatedFeatures = [...state.detectorGroups.features];
          updatedFeatures.splice(groupToUpdateIndex, 1, updatedGroup);

          return { detectorGroups: { ...state.detectorGroups, features: updatedFeatures } };
        }),

      removeDetectors: (detectors) =>
        set((state) => {
          const { detectorGroupHighlightId } = state;

          const groupToUpdateIndex = state.detectorGroups.features.findIndex(
            (group) => group.id === detectorGroupHighlightId,
          );
          if (groupToUpdateIndex === -1) {
            return {};
          }

          const updatedGroup = { ...state.detectorGroups.features[groupToUpdateIndex] };

          const detectorIds = detectors.map(({ properties: { id } }) => id);
          updatedGroup.properties.detectors.features = updatedGroup.properties.detectors.features.filter(
            ({ properties: { id } }) => !detectorIds.includes(id),
          );

          const updatedFeatures = [...state.detectorGroups.features];

          updatedFeatures.splice(groupToUpdateIndex, 1, updatedGroup);

          return { detectorGroups: { ...state.detectorGroups, features: updatedFeatures } };
        }),

      updateDetectorGroup: (update, index) =>
        set((state) => {
          const updatedFeatures = [...state.detectorGroups.features];
          const groupToUpdate = updatedFeatures[index];

          if (update.properties) {
            groupToUpdate.properties = { ...groupToUpdate.properties, ...update.properties };
          }

          updatedFeatures[index] = groupToUpdate;

          return {
            detectorGroups: { ...state.detectorGroups, features: updatedFeatures },
          };
        }),

      addDetectorGroup: () =>
        set(({ detectorGroups }) => {
          const detectorGroup = createDetectorGroupFeature(detectorGroups.features.length);

          return {
            detectorGroups: { ...detectorGroups, features: [...detectorGroups.features, detectorGroup] },
            detectorStep: AnalysisFormStep.LOCATION,
            detectorGroupHighlightId: detectorGroup.id,
          };
        }),

      removeDetectorGroup: (removeIndex) => {
        set(({ detectorGroups, detectorGroupHighlightId }) => {
          const features = [...detectorGroups.features];
          features.splice(removeIndex, 1);

          return {
            detectorGroups: { ...detectorGroups, features },
            detectorStep: AnalysisFormStep.LOCATION,
            detectorGroupHighlightId: features.some(({ id }) => id === detectorGroupHighlightId)
              ? detectorGroupHighlightId
              : features[0].id,
          };
        });
      },

      resetDetectorGroups: () =>
        set(({ detectorGroups }) => {
          const detectorGroup = createDetectorGroupFeature();

          return {
            detectorGroups: { ...detectorGroups, features: [detectorGroup] },
            detectorGroupHighlightId: detectorGroup.id,
            detectorStep: AnalysisFormStep.LOCATION,
          };
        }),

      setDetectorGroupHighlightId: (detectorGroupHighlightId) => set({ detectorGroupHighlightId }),

      setDetectorDateRange: (dateRange, index) =>
        set((state) => {
          const dateRanges = [...state.dateRanges];
          dateRanges.splice(index, 1, dateRange);

          return { dateRanges };
        }),

      removeDetectorDateRange: (index) =>
        set((state) => {
          const dateRanges = [...state.dateRanges];
          dateRanges.splice(index, 1);

          return { dateRanges };
        }),

      setDetectorDayCategories: (dayCategories) => set({ dayCategories: dayCategories.sort() }),

      setDetectorInterval: (interval) => set({ interval }),

      setDetectorTimeRange: (timeRange) => set({ timeRange }),

      setShowDetectorContexts: (showContexts) => set({ showContexts }),

      setDetectorContexts: (contexts) => set({ contexts }),

      setValidateDetectorForm: (validateDetectorForm) => set({ validateDetectorForm }),

      setDetectorDiagramVisibleDateRange: (detectorDiagramVisibleDateRange) => set({ detectorDiagramVisibleDateRange }),

      resetDetectorDiagramVisibleDateRange: () =>
        set(({ diagramParameters }) => ({
          detectorDiagramVisibleDateRange:
            diagramParameters?.dateRanges[0][0] && diagramParameters?.dateRanges[0][1]
              ? [
                  diagramParameters.dateRanges[0][0].startOf('day').add(diagramParameters.timeRange[0], 'minutes'),
                  diagramParameters.dateRanges[0][1].startOf('day').add(diagramParameters.timeRange[1], 'minutes'),
                ]
              : undefined,
        })),

      submitDetectorDiagramParameters: () => {
        set(
          ({
            detectorGroups,
            parameterType,
            interval,
            dateRanges,
            dayCategories,
            timeRange,
            showContexts,
            contexts,
          }) => ({
            validateForm: false,
            diagramParameters:
              parameterType &&
              cloneDeep({
                detectorGroups,
                parameterType,
                interval,
                dateRanges,
                dayCategories,
                timeRange,
                showContexts,
                contexts,
              }),
          }),
        );

        get().actions.resetDetectorDiagramVisibleDateRange();
      },

      resetDetectorForm: () => {
        const detectorGroup = createDetectorGroupFeature();

        return set({
          detectorStep: AnalysisFormStep.LOCATION,

          detectorGroups: { type: 'FeatureCollection', features: [detectorGroup] },
          parameterType: undefined,
          dateRanges: [[null, null]],
          dayCategories: Object.values(DayCategory).sort(),
          interval: DetectorAnalysisRequest.interval.MIN_15,
          timeRange: [0, 1439],

          detectorGroupHighlightId: detectorGroup.id,
          validateDetectorForm: false,
        });
      },

      setShowDetectorDiagram: (showDiagram) => set({ showDiagram }),

      setIsDrawing: (isDrawing) => set({ isDrawing }),

      setDrawnPolygon: (drawnPolygon) => set({ drawnPolygon }),
    },
  };
});
