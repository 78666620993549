import { useRef } from 'react';

import { PlotRef } from '@/types/PlotRef';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { useDetectorAnalysis } from '@/detector-analysis/hooks/useDetectorAnalysis';
import { AnalysisResults } from '@/components/AnalysisResults';
import { AnalysisType } from '@/types/AnalysisType';

export function DetectorAnalysisResults() {
  const plotRef = useRef<PlotRef>(null);

  const queryResult = useDetectorAnalysis();

  const diagramParameters = useDetectorAnalysisStore((state) => state.diagramParameters);
  const showDiagram = useDetectorAnalysisStore((state) => state.showDiagram);
  const diagramVisibleDateRange = useDetectorAnalysisStore((state) => state.detectorDiagramVisibleDateRange);

  const { setShowDetectorDiagram } = useDetectorAnalysisStore((state) => state.actions);

  const hasMultipleTimeParameters = diagramParameters && diagramParameters.dateRanges.length > 1;

  const { setDetectorDiagramVisibleDateRange, resetDetectorDiagramVisibleDateRange } = useDetectorAnalysisStore(
    (state) => state.actions,
  );

  if (!showDiagram || !diagramParameters) {
    return null;
  }

  return (
    <AnalysisResults
      plotRef={plotRef}
      plotComposition={{
        analysisType: AnalysisType.DETECTOR,
        queryResult,
        diagramParameters,
      }}
      hasMultipleTimeParameters={hasMultipleTimeParameters}
      onShowDiagram={() => setShowDetectorDiagram(false)}
      showDiagram={showDiagram}
      setDiagramVisibleDateRange={setDetectorDiagramVisibleDateRange}
      resetDiagramVisibleDateRange={resetDetectorDiagramVisibleDateRange}
      diagramVisibleDateRange={diagramVisibleDateRange}
    />
  );
}
