import { DateTimeForm } from '@/components/form/DateTimeForm';
import { LocationsTooltip } from '@/components/tooltip/LocationsTooltip';
import { DateTooltip } from '@/components/tooltip/DateTooltip';
import { ParameterTooltip } from '@/components/tooltip/ParameterTooltip';
import { SidebarChip } from '@/components/sidebar/SidebarChip';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { ContextForm } from '@/components/form/ContextForm';
import { ContextTooltip } from '@/components/tooltip/ContextTooltip';
import { DetectorGroupForms } from '@/detector-analysis/components/DetectorGroupForms';
import { CollapsibleSidebar } from '@/components/sidebar/CollapsibleSidebar';
import { AnalysisType } from '@/types/AnalysisType';
import { ParameterForm } from '@/components/form/ParameterForm';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { useValidation } from '@/hooks/useValidation';
import { DetectorActionButtons } from '@/detector-analysis/components/DetectorActionButtons';
import { DetectorParameterType } from '@/types/DetectorParameterType';
import { FcdParameterType } from '@/types/FcdParameterType';

export function DetectorSidebar() {
  const step = useDetectorAnalysisStore((state) => state.detectorStep);
  const dateRanges = useDetectorAnalysisStore((state) => state.dateRanges);
  const interval = useDetectorAnalysisStore((state) => state.interval);
  const dayCategories = useDetectorAnalysisStore((state) => state.dayCategories);
  const timeRange = useDetectorAnalysisStore((state) => state.timeRange);
  const validateForm = useDetectorAnalysisStore((state) => state.validateDetectorForm);
  const detectorGroups = useDetectorAnalysisStore((state) => state.detectorGroups);
  const parameterType = useDetectorAnalysisStore((state) => state.parameterType);
  const showContexts = useDetectorAnalysisStore((state) => state.showContexts);
  const contexts = useDetectorAnalysisStore((state) => state.contexts);

  const {
    setDetectorFormStep,
    setDetectorDayCategories,
    setDetectorInterval,
    setDetectorTimeRange,
    setDetectorDateRange,
    setDetectorParameterType,
    setDetectorContexts,
    setShowDetectorContexts,
    removeDetectorDateRange,
  } = useDetectorAnalysisStore((state) => state.actions);

  const validation = useValidation({
    dateRanges,
    parameterType,
    showContexts,
    validateForm,
    detectorGroups,
    contexts,
  });

  const items = [
    {
      title: 'Raum',
      Component: <DetectorGroupForms />,
      Tooltip: <LocationsTooltip analysisType={AnalysisType.DETECTOR} />,
      chip: (
        <SidebarChip
          label={validation[AnalysisFormStep.LOCATION].count}
          color={validation[AnalysisFormStep.LOCATION].status}
        />
      ),
    },
    {
      title: 'Zeit',
      Component: (
        <DateTimeForm
          analysisType={AnalysisType.DETECTOR}
          dateRanges={dateRanges}
          interval={interval}
          dayCategories={dayCategories}
          timeRange={timeRange}
          onSetDateRange={setDetectorDateRange}
          onRemoveDateRange={removeDetectorDateRange}
          onSetDayCategories={setDetectorDayCategories}
          onSetInterval={setDetectorInterval}
          onSetTimeRange={setDetectorTimeRange}
        />
      ),
      Tooltip: <DateTooltip />,
      chip: (
        <SidebarChip
          label={validation[AnalysisFormStep.DATE_TIME].count}
          color={validation[AnalysisFormStep.DATE_TIME].status}
        />
      ),
    },
    {
      title: 'Kennwert',
      Component: (
        <ParameterForm
          parameterType={AnalysisType.DETECTOR}
          i18nKey="DetectorParameterType"
          selectedParameter={parameterType}
          onSetParameterType={
            setDetectorParameterType as (paramaterType: DetectorParameterType | FcdParameterType) => void
          }
        />
      ),
      Tooltip: <ParameterTooltip />,
      chip: (
        <SidebarChip
          label={validation[AnalysisFormStep.PARAMETER].count}
          color={validation[AnalysisFormStep.PARAMETER].status}
        />
      ),
    },
    {
      title: 'Kontext',
      Component: (
        <ContextForm
          dateRanges={dateRanges}
          showContexts={showContexts}
          selectedContexts={contexts}
          onSetShowContexts={setShowDetectorContexts}
          onSetContexts={setDetectorContexts}
        />
      ),
      Tooltip: <ContextTooltip />,
      chip: validation[AnalysisFormStep.CONTEXTS].status !== 'error' && (
        <SidebarChip
          label={validation[AnalysisFormStep.CONTEXTS].count}
          color={validation[AnalysisFormStep.CONTEXTS].status}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleSidebar step={step} setFormStep={setDetectorFormStep} items={items} />
      <DetectorActionButtons validation={validation} />
    </>
  );
}
