import { useRef } from 'react';

import { useFcdAnalysis } from '@/fcd-analysis/hooks/useFcdAnalysis';
import { AnalysisType } from '@/types/AnalysisType';
import { AnalysisResults } from '@/components/AnalysisResults';
import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import { PlotRef } from '@/types/PlotRef';

export function FcdAnalysisResults() {
  const plotRef = useRef<PlotRef>(null);
  const queryResult = useFcdAnalysis();
  const diagramParameters = useFcdAnalysisStore((state) => state.diagramParameters);
  const showDiagram = useFcdAnalysisStore((state) => state.showDiagram);
  const nlpFcdRequest = useFcdAnalysisStore((state) => state.nlpFcdRequest);
  const diagramVisibleDateRange = useFcdAnalysisStore((state) => state.diagramVisibleDateRange);
  const { setShowDiagram } = useFcdAnalysisStore((state) => state.actions);

  const { setDiagramVisibleDateRange, resetDiagramVisibleDateRange } = useFcdAnalysisStore((state) => state.actions);

  const hasMultipleRoutes =
    (diagramParameters && diagramParameters.routes.features.length > 1) ||
    (nlpFcdRequest && nlpFcdRequest.routesLength > 1);
  const hasMultipleTimeParameters =
    (diagramParameters && diagramParameters.dateRanges.length > 1) ||
    (nlpFcdRequest?.timeParameters && nlpFcdRequest.timeParameters.length > 1);

  if (!diagramParameters) {
    return null;
  }

  return (
    <AnalysisResults
      plotRef={plotRef}
      plotComposition={{
        analysisType: AnalysisType.FCD,
        diagramParameters,
        queryResult,
      }}
      hasMultipleData={hasMultipleRoutes}
      hasMultipleTimeParameters={hasMultipleTimeParameters}
      onShowDiagram={() => setShowDiagram(false)}
      showDiagram={showDiagram}
      setDiagramVisibleDateRange={setDiagramVisibleDateRange}
      resetDiagramVisibleDateRange={resetDiagramVisibleDateRange}
      diagramVisibleDateRange={diagramVisibleDateRange}
    />
  );
}
