import { Box, css, styled } from '@mui/material';
import { Route } from 'react-router-dom';

import { Map } from '@/map/components/Map';
import { transientOptions } from '@/utils/transient-options';
import { MapSide } from '@/types/MapSide';
import { useMapStore } from '@/stores/useMapStore';
import { SentryRoutes } from '@/setup/sentry';
import { HotspotsDataGrid } from '@/hotspots/HotspotsDataGrid';
import { FcdAnalysisResults } from '@/fcd-analysis/FcdAnalysisResults';
import { DetectorAnalysisResults } from '@/detector-analysis/components/DetectorAnalysisResults';

const Root = styled(Box)(
  ({ theme }) => css`
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    background-color: ${theme.palette.common.white};
  `,
);

const Maps = styled(
  Box,
  transientOptions,
)<{ $sideBySideMaps: boolean }>(
  ({ $sideBySideMaps }) => css`
    position: relative;
    display: flex;
    flex: 1 0 auto;

    ${$sideBySideMaps &&
    css`
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    `}
  `,
);

export function MapLayout() {
  const sideBySideMaps = useMapStore((state) => state.sideBySideMaps);

  return (
    <Root>
      <Maps $sideBySideMaps={sideBySideMaps}>
        <Map side={MapSide.LEFT} />

        {sideBySideMaps && <Map side={MapSide.RIGHT} />}
      </Maps>

      <SentryRoutes>
        <Route path="monitoring" element={null} />
        <Route path="fcd-verkehrsanalyse" element={<FcdAnalysisResults />} />
        <Route path="detektoranalyse" element={<DetectorAnalysisResults />} />
        <Route path="hotspots" element={<HotspotsDataGrid />} />

        <Route path="nlp/fcd-verkehrsanalyse" element={<FcdAnalysisResults />} />
      </SentryRoutes>
    </Root>
  );
}
