import { FcdAnalysisRequest } from '@/modules/api';
import { BackgroundLayerType } from '@/types/BackgroundLayerType';
import { FcdLayerType } from '@/types/FcdLayerType';
import { ObstructionType } from '@/types/ObstructionType';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { FcdParameterType } from '@/types/FcdParameterType';
import { DayCategory } from '@/types/DayCategory';
import { ContextType } from '@/types/ContextType';
import { RouteLocationType } from '@/types/RouteLocationType';
import { DetectorParameterType } from '@/types/DetectorParameterType';

export const de = {
  BackgroundLayerType: {
    [BackgroundLayerType.CITY_MAP]: 'Stadtkarte',
    [BackgroundLayerType.DARK_MAP]: 'Dark',
    [BackgroundLayerType.LIGHT_MAP]: 'Light',
    [BackgroundLayerType.SATELLITE_MAP]: 'Luftbild',
  },

  FeatureLayerType: {
    [FeatureLayerType.STRATEGY]: 'Strategien',
    [FeatureLayerType.OBSTRUCTION]: 'Verkehrsmeldungen',
    [FeatureLayerType.MUNICH_CONSTRUCTIONS]: 'Baustellen (Mobilitätsreferat)',
    [FeatureLayerType.TRAFFIC_LIGHT]: 'LSA',
  },

  FcdLayerType: {
    [FcdLayerType.NONE]: 'Keine',
    [FcdLayerType.CONFIDENCE_VALUE]: 'Vertrauens&shy;kennwert',
    [FcdLayerType.LEVEL_OF_SERVICE]: 'Verkehrslage (LOS)',
    [FcdLayerType.SPEEDING]: 'Geschwindigkeits&shy;überschreitungen',
    [FcdLayerType.SLOW_DOWN]: 'Geschwindigkeits&shy;unterschreitungen',
    [FcdLayerType.SPEEDING_LOS]: 'Geschwindigkeits&shy;überschreitungen Free Flow',
    [FcdLayerType.ROUTING_NET]: 'Routingfähiges&nbsp;Netz',
  },

  ObstructionType: {
    [ObstructionType.ABNORMAL_TRAFFIC]: 'Stau',
    [ObstructionType.ACCIDENT]: 'Unfall',
    [ObstructionType.CONDITIONS]: 'Umweltalarm',
    [ObstructionType.CONSTRUCTION_WORKS]: 'Bauarbeiten',
    [ObstructionType.ENVIRONMENTAL_OBSTRUCTION]: 'Naturereignis',
    [ObstructionType.MAINTENANCE_WORKS]: 'Wartungsarbeiten',
    [ObstructionType.PUBLIC_EVENT]: 'Veranstaltung',
    [ObstructionType.REROUTING_MANAGEMENT]: 'Umleitung',
    [ObstructionType.ROAD_OR_CARRIAGEWAY_OR_LANE_MANAGEMENT]: 'Sperrung/Änderung Verkehrsführung',
    misc: 'Sonstiges ({{type}})',
  },
  FcdParameterType: {
    [FcdParameterType.V]: 'Mittlere Geschwindigkeit',
    [FcdParameterType.VFREE]: 'Freiflussgeschwindigkeit',
    [FcdParameterType.LOS]: 'Verkehrslage (LOS)',
    [FcdParameterType.TIMELOSS]: 'Verlustzeit',
    [FcdParameterType.TRAVELTIMESELF]: 'Reisezeit berechnet',
    [FcdParameterType.CONFIDENCE]: 'Vertrauenskennwert',
  },
  DetectorParameterType: {
    [DetectorParameterType.NUM_VEHICLES]: 'Verkehrsstärke Σ',
    [DetectorParameterType.V]: 'Geschwindigkeit ⌀',
  },
  FcdParameterTypeDescription: {
    [FcdParameterType.V]: '',
    [FcdParameterType.VFREE]:
      'in Echtzeit auf Basis der aktuellen Durchschnittsgeschwindigkeit & Freiflussgeschwindigkeit',
    [FcdParameterType.LOS]: '',
    [FcdParameterType.TIMELOSS]: 'Differenz zwischen Reisezeit im Free-Flow und aktueller Reisezeit',
    [FcdParameterType.TRAVELTIMESELF]: 'Aktuelle Reisezeit der HERE Daten auf das OSM-Zielnetz-Segment gematched',
    [FcdParameterType.CONFIDENCE]: '',
  },
  IntervalType: {
    [FcdAnalysisRequest.interval.MIN_3]: '3 min',
    [FcdAnalysisRequest.interval.MIN_15]: '15 min',
    [FcdAnalysisRequest.interval.HOUR_1]: '1 h',
    [FcdAnalysisRequest.interval.DAY_1]: '1 Tag',
    [FcdAnalysisRequest.interval.WEEK_1]: '1 Woche',
    [FcdAnalysisRequest.interval.MONTH_1]: '1 Monat',
    [FcdAnalysisRequest.interval.YEAR_1]: '1 Jahr',
  },
  IntervalTypeShort: {
    [FcdAnalysisRequest.interval.MIN_3]: '3 m',
    [FcdAnalysisRequest.interval.MIN_15]: '15 m',
    [FcdAnalysisRequest.interval.HOUR_1]: '1 h',
    [FcdAnalysisRequest.interval.DAY_1]: '1 T',
    [FcdAnalysisRequest.interval.WEEK_1]: '1 W',
    [FcdAnalysisRequest.interval.MONTH_1]: '1 M',
    [FcdAnalysisRequest.interval.YEAR_1]: '1 J',
  },
  DayCategory: {
    [DayCategory.MONDAY]: 'Mo',
    [DayCategory.TUESDAY]: 'Di',
    [DayCategory.WEDNESDAY]: 'Mi',
    [DayCategory.THURSDAY]: 'Do',
    [DayCategory.FRIDAY]: 'Fr',
    [DayCategory.SATURDAY]: 'Sa',
    [DayCategory.SUNDAY]: 'So',
  },
  RouteLocationType: {
    [RouteLocationType.ORIGIN]: 'Start',
    [RouteLocationType.DESTINATION]: 'Ziel',
    [RouteLocationType.VIA]: 'Via',
  },
  ContextType: {
    [ContextType.CALENDAR]: 'Kalendereintrag',
    [ContextType.CONSTRUCTION]: 'Baustelle',
    [ContextType.HOLIDAY]: 'Ferienkalender',
    [ContextType.NOTIFICATION]: 'Maßnahme',
    [ContextType.STRATEGY]: 'Strategie',
    [ContextType.OBSTRUCTION]: 'Verkehrsmeldung',
  },
  ContextTypePlural: {
    [ContextType.CALENDAR]: 'Kalendereinträge',
    [ContextType.CONSTRUCTION]: 'Baustellen',
    [ContextType.HOLIDAY]: 'Ferienkalender',
    [ContextType.NOTIFICATION]: 'Maßnahmen',
    [ContextType.STRATEGY]: 'Strategien',
    [ContextType.OBSTRUCTION]: 'Verkehrsmeldungen',
  },
};
