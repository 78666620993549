import { Card, CardContent, CardHeader, IconButton, css, styled } from '@mui/material';
import { ReactNode } from 'react';

import CrossCircleIcon from '@/assets/icons/analysis/cross-circle.svg?react';

export const DETAIL_CARD_MAX_WIDTH = 400;

const StyledCard = styled(Card)(
  ({ theme }) => css`
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    min-width: ${theme.spacing(40)};
    max-width: ${DETAIL_CARD_MAX_WIDTH}px;
    border-left: 1px solid ${theme.palette.divider};
  `,
);

type DetailCardProps = { title: string; onClose: () => void; children: ReactNode };

export function DetailCard({ title, onClose, children }: DetailCardProps) {
  return (
    <StyledCard>
      <CardHeader
        title={title}
        action={
          <IconButton aria-label="Schließen" onClick={onClose}>
            <CrossCircleIcon />
          </IconButton>
        }
      />
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
}
