import { RouteLocationType } from '@/types/RouteLocationType';
import RouteOriginIcon from '@/fcd-analysis/assets/route-origin.svg?react';
import RouteViaIcon from '@/fcd-analysis/assets/route-via.svg?react';
import RouteDestinationIcon from '@/fcd-analysis/assets/route-destination.svg?react';

type RouteIconProps = { type: RouteLocationType; color: string; width?: number | string; height?: number | string };

export function RouteIcon({ type, color, width, height }: RouteIconProps) {
  const Icon = {
    [RouteLocationType.ORIGIN]: RouteOriginIcon,
    [RouteLocationType.VIA]: RouteViaIcon,
    [RouteLocationType.DESTINATION]: RouteDestinationIcon,
  }[type];

  return <Icon style={{ color, width, height }} />;
}
