import { Box, Button } from '@mui/material';

import { DetectorGroupForm } from '@/detector-analysis/components/DetectorGroupForm';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { PlusIcon } from '@/components/PlusIcon';
import { FormsWrapper } from '@/components/form/FormsWrapper';

export function DetectorGroupForms() {
  const detectorGroups = useDetectorAnalysisStore((state) => state.detectorGroups);

  const { addDetectorGroup } = useDetectorAnalysisStore((state) => state.actions);

  return (
    <FormsWrapper>
      <>
        {detectorGroups.features.map((detectorGroup, index) => (
          <DetectorGroupForm index={index} key={detectorGroup.properties.id} />
        ))}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'align-left',
          }}
        >
          {detectorGroups.features.length < 3 && (
            <Button
              startIcon={<PlusIcon />}
              size="small"
              color="neutral"
              sx={{ marginBottom: 2, marginLeft: 3 }}
              onClick={() => {
                addDetectorGroup();
              }}
            >
              Detektorgruppe hinzufügen
            </Button>
          )}
        </Box>
      </>
    </FormsWrapper>
  );
}
