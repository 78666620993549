import { Popup } from 'react-map-gl';
import { Box } from '@mui/material';

import { useMapContext } from '@/stores/useMapContext';
import { DetectorPopupActions } from '@/detector-analysis/components/DetectorPopupActions';

export function DetectorClusterPopup() {
  const detectorFeatures = useMapContext((state) => state.detectorClusterPopupFeature);

  const { setDetectorClusterPopup } = useMapContext((state) => state.actions);

  if (!detectorFeatures) {
    return null;
  }

  const longitude = detectorFeatures.geometry.coordinates[0];
  const latitude = detectorFeatures.geometry.coordinates[1];

  const { detectors } = detectorFeatures.properties;

  const onClose = () => setDetectorClusterPopup(undefined);

  let offset = 16;

  if (detectors.length >= 10) {
    offset = 20;
  } else if (detectors.length >= 100) {
    offset = 24;
  } else if (detectors.length >= 250) {
    offset = 28;
  } else if (detectors.length >= 500) {
    offset = 32;
  }
  // 10, 20, 100, 24, 250, 28, 500, 32

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      offset={offset}
      closeButton={false}
      onClose={onClose}
      closeOnClick
      closeOnMove
      anchor="top"
      maxWidth="75%"
    >
      <Box padding={2}>
        <DetectorPopupActions detectors={detectors} />
      </Box>
    </Popup>
  );
}
