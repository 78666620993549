import { Alert, Autocomplete, Box, Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

import HolidayIcon from '@/assets/icons/analysis/holidays.svg?react';
import StrategyIcon from '@/assets/icons/analysis/strategy.svg?react';
import TrafficMsgIcon from '@/assets/icons/analysis/traffic_message.svg?react';
import { SidebarItemContainer } from '@/components/sidebar/SidebarItemContainer';
import { ContextService } from '@/modules/ContextService';
import { QueryKey } from '@/modules/QueryKey';
import { ContextType } from '@/types/ContextType';
import { ContextName } from '@/modules/ContextName';
import { ContextsFeatureCollection } from '@/types/ContextFeature';

type ContextFormProps = {
  dateRanges: DateRange<Dayjs>[];
  showContexts: boolean;

  selectedContexts: ContextsFeatureCollection;
  onSetShowContexts: (showContexts: boolean) => void;
  onSetContexts: (contexts: ContextsFeatureCollection) => void;
};

export function ContextForm({
  dateRanges,
  showContexts,
  selectedContexts,
  onSetShowContexts,
  onSetContexts,
}: ContextFormProps) {
  const { t } = useTranslation();

  const filter = { from: dateRanges[0][0], to: dateRanges[0][1] };
  const dataRangeValid = filter.from?.isValid() && filter.to?.isValid();

  const optionsByType = [
    {
      type: ContextType.HOLIDAY,
      icon: HolidayIcon,
      options: useQuery({
        queryKey: QueryKey.holidays.getHolidaysFiltered(filter),
        queryFn: () => ContextService.getContexts(ContextType.HOLIDAY, filter),
      }).data?.features,
    },
    {
      type: ContextType.STRATEGY,
      icon: StrategyIcon,
      options: useQuery({
        queryKey: QueryKey.strategies.getStategiesFiltered(filter),
        queryFn: () => ContextService.getContexts(ContextType.STRATEGY, filter),
      }).data?.features,
    },
    {
      type: ContextType.OBSTRUCTION,
      icon: TrafficMsgIcon,
      options: useQuery({
        queryKey: QueryKey.trafficMessages.getTrafficMessagesFiltered(filter),
        queryFn: () => ContextService.getContexts(ContextType.OBSTRUCTION, filter),
      }).data?.features,
    },
  ];

  return (
    <SidebarItemContainer>
      {dateRanges.length > 1 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Kontextauswahl ist nur für einen Zeitraum möglich.
        </Alert>
      )}

      {dateRanges.length === 1 && !dataRangeValid && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Es muss zuerst einen Zeitraum gewählt werden.
        </Alert>
      )}

      {dateRanges.length === 1 && dataRangeValid && (
        <>
          <FormControlLabel
            control={<Checkbox checked={showContexts} onChange={(event, value) => onSetShowContexts(value)} />}
            label="Kontextleiste anzeigen"
            sx={{ mb: 2, flexDirection: 'row-reverse', ml: 0, width: '100%', justifyContent: 'space-between' }}
          />

          {showContexts && (
            <>
              {selectedContexts.features.length === 5 && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  Es wurde die Maximalanzahl von fünf Kontexten erreicht. Um einen weiteren Kontext auszuwählen, muss
                  zuerst ein anderer entfernt werden.
                </Alert>
              )}

              {optionsByType.map(({ type, icon: Icon, options }) => (
                <Box key={type}>
                  <Stack
                    direction="row"
                    mb={4}
                    columnGap={2}
                    alignItems="flex-start"
                    flexWrap="nowrap"
                    sx={{ width: '100%' }}
                  >
                    <Box
                      sx={{
                        marginTop: 1.5,
                        width: 32,
                        height: 32,
                        flexShrink: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon style={{ width: 32, height: 'auto' }} />
                    </Box>

                    <Autocomplete
                      sx={{ flex: 1, minWidth: 0 }}
                      multiple
                      value={selectedContexts.features.filter((item) => item.properties.contextType === type)}
                      isOptionEqualToValue={(option, item) =>
                        !!option.properties?.id && !!item.properties?.id && option.properties.id === item.properties.id
                      }
                      options={options || []}
                      getOptionLabel={(option) => ContextName.get(option)}
                      getOptionDisabled={() => selectedContexts.features.length >= 5}
                      filterSelectedOptions
                      renderInput={(params) => <TextField {...params} label={t(`ContextTypePlural.${type}`)} />}
                      renderOption={(params, option) => (
                        <li {...params} key={option.properties?.id}>
                          {ContextName.get(option)}
                        </li>
                      )}
                      onChange={(event, value) => {
                        const newSelectedContexts = [
                          ...selectedContexts.features.filter((feature) => feature.properties.contextType !== type),
                          ...value,
                        ];

                        if (newSelectedContexts.length <= 5) {
                          onSetContexts({
                            type: 'FeatureCollection',
                            features: newSelectedContexts,
                          });
                        }
                      }}
                    />
                  </Stack>
                </Box>
              ))}
            </>
          )}
        </>
      )}
    </SidebarItemContainer>
  );
}
