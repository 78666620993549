import dayjs from 'dayjs';

import type { TimeParameter } from '@/modules/api';

export class PlotTitle {
  public static formatDateTime(timeParameters?: TimeParameter[]) {
    if (!timeParameters) {
      return '';
    }

    return PlotTitle.formatDate(timeParameters) + PlotTitle.formatTime(timeParameters);
  }

  private static formatDate(timeParameters: TimeParameter[]) {
    return timeParameters.length <= 1
      ? ` | ${dayjs(timeParameters[0].start).tz('Europe/Berlin').format('DD.MM.YYYY')} - ${dayjs(timeParameters[0].end)
          .tz('Europe/Berlin')
          .format('DD.MM.YYYY')}`
      : '';
  }

  private static formatTime(timeParameters: TimeParameter[]) {
    return timeParameters.length > 0 && timeParameters[0].timeRanges && timeParameters[0].timeRanges.length > 0
      ? ` von ${dayjs(`${timeParameters[0].timeRanges[0].from}Z`, 'HH:mm:ssZ')
          .tz('Europe/Berlin')
          .format('HH:mm')} - ${dayjs(`${timeParameters[0].timeRanges[0].to}Z`, 'HH:mm:ssZ')
          .tz('Europe/Berlin')
          .format('HH:mm')}`
      : '';
  }
}
