import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/modules/QueryKey';
import { DetectorsService } from '@/modules/DetectorsService';
import { DetectorsFeatureCollection } from '@/types/DetectorFeature';

export const useDetectorsQuery = () =>
  useQuery({
    queryKey: QueryKey.detectors.all,
    queryFn: async (): Promise<DetectorsFeatureCollection> => {
      const results = await DetectorsService.getDetectors();

      const detectorGroups = results.features
        .sort(({ properties: { id: a } }, { properties: { id: b } }) => parseInt(a, 10) - parseInt(b, 10))
        .sort(({ properties: { location_description: a } }, { properties: { location_description: b } }) => {
          if (a === b) {
            return 0;
          }

          if (!a) {
            return 1;
          }

          if (!b) {
            return -1;
          }

          return a.localeCompare(b);
        });

      return {
        type: 'FeatureCollection',
        features: detectorGroups,
      };
    },
    refetchOnWindowFocus: false,
  });
