import { Button, Stack, Tooltip, Typography } from '@mui/material';

import { useFcdAnalysisStore } from '@/stores/useFcdAnalysisStore';
import ArrowRightIcon from '@/assets/icons/analysis/arrow-right.svg?react';
import CheckIcon from '@/assets/icons/analysis/check.svg?react';
import { FcdAnalysisState } from '@/fcd-analysis/types/FcdAnalysisState';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { ValidationStatus } from '@/types/ValidationStatus';
import { useAnalysisStatus } from '@/hooks/useAnalysisStatus';
import { StyledIconButton } from '@/components/StyledIconButton';

const analysisSelector = (state: FcdAnalysisState) => ({
  diagramParameters: state.diagramParameters,
  analysisParameters: {
    routes: state.routes,
    parameterType: state.parameterType,
    interval: state.interval,
    dateRanges: state.dateRanges,
    dayCategories: state.dayCategories,
    timeRange: state.timeRange,
    showContexts: state.showContexts,
    contexts: state.contexts,
  },
});

type FcdActionButtonsProps = {
  validation: Record<AnalysisFormStep, { status: ValidationStatus; count: string | number }>;
};
export function FcdActionButtons({ validation }: FcdActionButtonsProps) {
  const { submitDiagramParameters, setNlpFcdRequest, resetForm, setShowDiagram, setFormStep, setValidateForm } =
    useFcdAnalysisStore((state) => state.actions);

  const { diagramParameters, analysisParameters } = useFcdAnalysisStore(analysisSelector);

  const analysisStatus = useAnalysisStatus({ diagramParameters, analysisParameters, validation });
  const { tooltipTitle, analysisPossible, buttonColor } = analysisStatus;

  return (
    <Stack direction="row" justifyContent="space-between" p={1}>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={() => {
          setNlpFcdRequest(undefined);
          resetForm();
        }}
      >
        Neu
      </Button>

      <Tooltip title={<Typography>{tooltipTitle}</Typography>} placement="right" arrow>
        <span>
          <StyledIconButton
            onClick={() => {
              if (analysisPossible) {
                submitDiagramParameters();
                setShowDiagram(true);
              } else {
                const firstInvalidFormStep = [
                  AnalysisFormStep.LOCATION,
                  AnalysisFormStep.DATE_TIME,
                  AnalysisFormStep.PARAMETER,
                ].find((formStep) => validation[formStep].status !== 'success');

                if (firstInvalidFormStep !== undefined) {
                  setFormStep(firstInvalidFormStep);
                  setValidateForm(true);
                }
              }
            }}
            $color={buttonColor}
          >
            {buttonColor === 'success' ? <CheckIcon /> : <ArrowRightIcon />}
          </StyledIconButton>
        </span>
      </Tooltip>
    </Stack>
  );
}
