type PlusIconProps = { color?: string };

export function PlusIcon({ color }: PlusIconProps) {
  return (
    <svg
      fill="none"
      stroke={color || 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      height="24"
      viewBox="0 0 22 24"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m16.4959 17.6569c3.0368-3.1242 3.0368-8.18956 0-11.31375-3.0368-3.1242-7.96048-3.1242-10.99728 0-3.0368 3.12419-3.0368 8.18955 0 11.31375 3.0368 3.1241 7.96048 3.1241 10.99728 0z" />
      <path d="m10.997 16.243v-8.48534" />
      <path d="m15.121 12.0003h-8.24795" />
    </svg>
  );
}
