// @ts-ignore
import Plotly from 'plotly.js/dist/plotly-tempus.min';
// @ts-ignore
import * as localeDictionary from 'plotly.js/lib/locales/de';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotParams } from 'react-plotly.js';
import { Box, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';
import merge from 'lodash/merge';

Plotly.register(localeDictionary);
// @ts-ignore
Plotly.setPlotConfig({ locale: 'de', responsive: true });
export const PlotlyPlot = createPlotlyComponent(Plotly);

export function Plot({ ...props }: PlotParams) {
  const elementRef = useRef(null);
  const plotRef = useRef<any>(null);
  const theme = useTheme();

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      plotRef.current.resizeHandler();
    });

    resizeObserver.observe(elementRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Box ref={elementRef} sx={{ width: '100%', height: '100%' }}>
      <PlotlyPlot
        // @ts-ignore
        ref={plotRef}
        useResizeHandler
        {...props}
        config={{ displaylogo: false, modeBarButtonsToRemove: ['autoScale2d'], ...props.config }}
        layout={merge(
          {
            autosize: true,
            font: {
              family: theme.typography.body1.fontFamily,
              color: theme.palette.text.primary,
            },
            title: {
              font: { size: 14 },
              y: 0.95,
            },
            margin: {
              t:
                ((typeof props?.layout?.title === 'object' && props.layout.title.text
                  ? props.layout.title.text
                  : ''
                ).split('<br />').length +
                  1) *
                18,
              r: 0,
              b: 0,
              l: 0,
            },
            xaxis: {
              linecolor: theme.palette.secondary.main,
              linewidth: 1,
              mirror: true,
              ticks: 'outside',
              showline: true,
              automargin: true,
            },
            yaxis: {
              linecolor: theme.palette.secondary.main,
              linewidth: 1,
              mirror: true,
              ticks: 'outside',
              showline: true,
              automargin: true,
            },
          },
          props.layout,
        )}
        style={{
          width: '100%',
          height: '100%',
          ...props.style,
        }}
      />
    </Box>
  );
}
