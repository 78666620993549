import { Typography } from '@mui/material';
import { Popup } from 'react-map-gl';

import { PopupContent } from '@/components/PopupContent';
import { useMapContext } from '@/stores/useMapContext';

export function DetectorPopup() {
  const feature = useMapContext((state) => state.detectorPopupFeature);

  if (!feature) {
    return null;
  }

  const [longitude, latitude] = feature.geometry.coordinates;
  const { type, id } = feature.properties;

  return (
    <Popup
      key={id}
      longitude={longitude}
      latitude={latitude}
      offset={16}
      maxWidth="min(480px, 75vw)"
      closeButton={false}
    >
      <PopupContent>
        <Typography fontWeight="bold" color="text.primary" mb={1} sx={{ fontSie: '0.75rem' }}>
          {`${type}: ${id}`}
        </Typography>
      </PopupContent>
    </Popup>
  );
}
