import { Button, Stack } from '@mui/material';
import { useMemo } from 'react';

import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { DetectorFeature } from '@/types/DetectorFeature';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { PlusIcon } from '@/components/PlusIcon';
import { TrashBinIcon } from '@/components/TrashBinIcon';

type DetectorPopupActionsProps = { detectors: DetectorFeature[]; onClick?: () => void };

export function DetectorPopupActions({ detectors, onClick }: DetectorPopupActionsProps) {
  const { addDetectors, removeDetectors } = useDetectorAnalysisStore((state) => state.actions);
  const detectorGroupHighlightId = useDetectorAnalysisStore((state) => state.detectorGroupHighlightId);

  const detectorGroups = useDetectorAnalysisStore((state) => state.detectorGroups);

  const highlightedGroup = useMemo(
    () => detectorGroups.features.find((group) => group.properties?.id === detectorGroupHighlightId),
    [detectorGroups, detectorGroupHighlightId],
  );
  const highlightedGroupIndex = useMemo(
    () => detectorGroups.features.findIndex((group) => group.properties?.id === detectorGroupHighlightId),
    [detectorGroups, detectorGroupHighlightId],
  );

  const { oldDetectors, newDetectors } = useMemo(
    () =>
      detectors.reduce(
        (sortedDetectors: { newDetectors: DetectorFeature[]; oldDetectors: DetectorFeature[] }, detector) => {
          if (
            highlightedGroup?.properties.detectors.features.find(
              ({ properties: { id } }) => id === detector.properties.id,
            )
          ) {
            sortedDetectors.oldDetectors.push(detector);
          } else {
            sortedDetectors.newDetectors.push(detector);
          }

          return sortedDetectors;
        },
        { oldDetectors: [], newDetectors: [] },
      ),
    [detectors, highlightedGroup?.properties.detectors.features],
  );

  return (
    <Stack direction="row" justifyContent="center" gap={1}>
      {oldDetectors.length > 0 && (
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            removeDetectors(oldDetectors);

            if (onClick) {
              onClick();
            }
          }}
          startIcon={<TrashBinIcon color={ROUTE_COLOR[highlightedGroupIndex]} />}
        >
          Entfernen{detectors.length > 1 ? ` (${oldDetectors.length})` : ''}
        </Button>
      )}
      {newDetectors.length > 0 && (
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            addDetectors(newDetectors);

            if (onClick) {
              onClick();
            }
          }}
          startIcon={<PlusIcon color={ROUTE_COLOR[highlightedGroupIndex]} />}
        >
          Hinzufügen{detectors.length > 1 ? ` (${newDetectors.length})` : ''}
        </Button>
      )}
    </Stack>
  );
}
