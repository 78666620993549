import { useQuery } from '@tanstack/react-query';

import { QueryKey } from '@/modules/QueryKey';
import { TempusService } from '@/modules/api';
import { MonitoringMode } from '@/types/MonitoringMode';
import { FcdLayerType } from '@/types/FcdLayerType';
import { useMonitoringStore } from '@/stores/useMonitoringStore';
import { useMapContext } from '@/stores/useMapContext';

export const useFcdLiveQuery = () => {
  const mode = useMonitoringStore((state) => state.mode);
  const layers = useMapContext((state) => state.layers);
  const enabled = mode === MonitoringMode.LIVE && ![FcdLayerType.NONE, FcdLayerType.ROUTING_NET].includes(layers.fcd);

  const queryResult = useQuery({
    queryKey: QueryKey.fcdLive.all,
    queryFn: () => TempusService.getFcdLive(),
    refetchOnWindowFocus: false,
    refetchInterval: 60000,
    enabled,
  });

  return { enabled, ...queryResult };
};
