import { Box, Typography, css, lighten, styled } from '@mui/material';
import { DataGridPro, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { useCallback } from 'react';

import { HotspotFeature } from '@/types/HotspotFeature';
import { useHotspotsQuery } from '@/hotspots/queries/useHotspotsQuery';
import { useHotspotsStore } from '@/stores/useHotspotsStore';
import { HotspotsGridToolbar } from '@/hotspots/components/HotspotsGridToolbar';
import { HotspotsDataGridDetail } from '@/hotspots/components/HotspotsDataGridDetail';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';
import { NumberFormat } from '@/modules/NumberFormat';
import { timeFilterOperators } from '@/hotspots/components/TimeFilter';
import { DayCategory } from '@/types/DayCategory';
import { de } from '@/i18n/de';
import { multiSelectFilterOperators } from '@/hotspots/components/MultiSelectFilter';

const StyledDataGridPro = styled(DataGridPro)(
  () => css`
    .MuiDataGrid-overlay {
      background-color: transparent;
    }

    .MuiDataGrid-cell[data-field='__detail_panel_toggle__'] {
      text-overflow: initial;
    }

    .MuiDataGrid-row.Mui-selected {
      &,
      &.Mui-hovered,
      &:hover {
        background-color: ${lighten(ROUTE_COLOR[0], 0.85)};
      }
    }

    .MuiDataGrid-cell:focus,
    .MuiDataGrid-cell:focus-within {
      outline: none;
    }
  `,
) as typeof DataGridPro;

const COLUMNS: GridColDef<HotspotFeature>[] = [
  {
    field: 'id',
    headerName: 'ID',
    valueGetter: (value, feature) => feature.properties.id,
    width: 64,
  },
  {
    field: 'hotspot_name',
    headerName: 'Kennung',
    valueGetter: (value, feature) => feature.properties.hotspot_name,
    minWidth: 136,
    flex: 1.5,
  },
  {
    field: 'day_category',
    headerName: 'Tag',
    valueGetter: (value, feature) =>
      feature.properties.day_category.split(',').map(
        (day) =>
          ({
            Montag: DayCategory.MONDAY,
            Dienstag: DayCategory.TUESDAY,
            Mittwoch: DayCategory.WEDNESDAY,
            Donnerstag: DayCategory.THURSDAY,
            Freitag: DayCategory.FRIDAY,
            Samstag: DayCategory.SATURDAY,
            Sonntag: DayCategory.SUNDAY,
          })[day],
      ),
    valueFormatter: (value: DayCategory[]) => value.map((day) => de.DayCategory[day]).join(' '),
    filterOperators: multiSelectFilterOperators({
      valueOptions: Object.values(DayCategory).map((value) => ({ value, label: de.DayCategory[value] })),
    }),
    minWidth: 136,
    maxWidth: 184,
  },
  {
    field: 'avg_length',
    headerName: 'Ø Länge',
    type: 'number',
    valueGetter: (value, feature) => feature.properties.avg_length / 1000,
    valueFormatter: (value) => NumberFormat.lengthKm(value),
    minWidth: 80,
    maxWidth: 144,
    flex: 1,
  },
  {
    field: 'avg_duration',
    headerName: 'Dauer',
    type: 'number',
    valueGetter: (value, feature) => {
      const [hours, minutes] = feature.properties.avg_duration.split(':').map(Number);

      return hours * 60 + minutes;
    },
    valueFormatter: (value) => `${value} min`,
    minWidth: 80,
    maxWidth: 144,
    flex: 1,
  },
  {
    field: 'from_datetime',
    headerName: 'Beginn',
    type: 'dateTime',
    valueGetter: (value, feature) => dayjs(feature.properties.from_datetime).toDate(),
    valueFormatter: (value) => dayjs(value).format('DD.MM.YYYY HH:mm'),
    width: 136,
  },
  {
    field: 'to_datetime',
    headerName: 'Ende',
    type: 'dateTime',
    valueGetter: (value, feature) => dayjs(feature.properties.to_datetime).toDate(),
    valueFormatter: (value) => dayjs(value).format('DD.MM.YYYY HH:mm'),
    width: 136,
  },
  {
    field: 'min_starttime',
    headerName: 'Frühester Beginn',
    valueGetter: (value, feature) => dayjs(feature.properties.min_starttime).format('HH:mm'),
    filterOperators: timeFilterOperators,
    minWidth: 96,
    maxWidth: 160,
    flex: 1,
  },
  {
    field: 'max_endtime',
    headerName: 'Spätestes Ende',
    valueGetter: (value, feature) => dayjs(feature.properties.max_endtime).format('HH:mm'),
    filterOperators: timeFilterOperators,
    minWidth: 96,
    maxWidth: 160,
    flex: 1,
  },
  {
    field: 'number_of_events',
    headerName: 'Einzelereignisse',
    type: 'number',
    valueGetter: (value, feature) => feature.properties.number_of_events,
    minWidth: 112,
    maxWidth: 184,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    valueGetter: (value, feature) => feature.properties.status,
    valueOptions: ['aktuell', 'abgelaufen'],
    minWidth: 80,
    maxWidth: 160,
    flex: 1,
  },
];

export function HotspotsDataGrid() {
  const hotspotFeature = useHotspotsStore((state) => state.hotspotFeature);
  const dataGridInitialState = useHotspotsStore((state) => state.dataGridInitialState);
  const detailPanelExpandedRowIds = useHotspotsStore((state) => state.detailPanelExpandedRowIds);
  const { setHotspotFeature, setHotspotEventFeature, setDetailPanelExpandedRowIds } = useHotspotsStore(
    (state) => state.actions,
  );

  const { data, isLoading } = useHotspotsQuery();

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams<HotspotFeature>) => <HotspotsDataGridDetail feature={row} />,
    [],
  );

  return (
    <Box sx={{ backgroundColor: 'common.white' }}>
      <Box sx={{ borderBottom: (theme) => `2px solid ${theme.palette.primary.main}`, padding: 2 }}>
        <Typography variant="h2" textTransform="uppercase">
          Hotspots
        </Typography>
      </Box>

      <Box sx={{ height: '50vh' }}>
        <StyledDataGridPro
          loading={isLoading}
          density="compact"
          columns={COLUMNS}
          rows={data?.features || []}
          getRowId={(row) => row.properties.id}
          slots={{
            toolbar: HotspotsGridToolbar,
          }}
          detailPanelExpandedRowIds={detailPanelExpandedRowIds}
          onDetailPanelExpandedRowIdsChange={(ids) => {
            setDetailPanelExpandedRowIds(ids);
            if (ids.length > detailPanelExpandedRowIds.length) {
              setHotspotFeature(data?.features.find(({ properties: { id } }) => id === ids[ids.length - 1]));
            }
          }}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          rowSelectionModel={hotspotFeature ? [hotspotFeature.properties.id] : []}
          onRowSelectionModelChange={(selection) => {
            setHotspotFeature(data?.features.find(({ properties: { id } }) => id === selection[0]));
            setHotspotEventFeature(undefined);
          }}
          pagination
          initialState={dataGridInitialState}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      </Box>
    </Box>
  );
}
