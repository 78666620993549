import { memo, useEffect } from 'react';

import { HotspotsLayer } from '@/hotspots/components/HotspotsLayer';
import { HotspotEventsLayer } from '@/hotspots/components/HotspotEventsLayer';
import { useMapContext } from '@/stores/useMapContext';
import { FcdLayerType } from '@/types/FcdLayerType';
import { BottomControls } from '@/monitoring/components/BottomControls';
import { HotspotsLegend } from '@/hotspots/components/HotspotsLegend';
import { FeatureLayers } from '@/map/components/feature-layers/FeatureLayers';
import { FeatureLayerType } from '@/types/FeatureLayerType';

export const HotspotsMapContent = memo(() => {
  const { setLayers } = useMapContext((state) => state.actions);

  useEffect(() => {
    setLayers({ fcd: FcdLayerType.ROUTING_NET });
  }, [setLayers]);

  return (
    <>
      <FeatureLayers types={[FeatureLayerType.TRAFFIC_LIGHT]} />

      <BottomControls>
        <HotspotsLegend />
      </BottomControls>

      <HotspotsLayer />
      <HotspotEventsLayer />
    </>
  );
});
