import { Chip, css, styled } from '@mui/material';

import { transientOptions } from '@/utils/transient-options';

export const SidebarChip = styled(
  Chip,
  transientOptions,
)(
  ({ theme }) => css`
    border-radius: ${theme.spacing(1)};
    text-transform: initial;
    font-family: ${theme.typography.body1.fontFamily};
    font-weight: 600;
    min-width: ${theme.spacing(3)};
  `,
);

SidebarChip.defaultProps = {
  ...SidebarChip.defaultProps,
  size: 'small',
  color: 'neutral',
};
