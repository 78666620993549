import { ReactNode } from 'react';
import { Box, css, styled, Typography } from '@mui/material';

import { transientOptions } from '@/utils/transient-options';

const Root = styled(
  Box,
  transientOptions,
)<{ $borderColor?: string }>(
  ({ theme, $borderColor }) => css`
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    margin-bottom: ${theme.spacing(1)};
    text-align: center;
    display: grid;
    place-items: center;

    ${$borderColor &&
    css`
      border-radius: 50%;
      border-width: ${theme.spacing(1)};
      border-style: solid;
      border-color: ${$borderColor};
    `}
  `,
);

type ValueColorCircleProps = { color?: string; children: ReactNode };

export function ValueColorCircle({ color, children }: ValueColorCircleProps) {
  return (
    <Root $borderColor={color}>
      <Typography fontWeight="bold">{children}</Typography>
    </Root>
  );
}
