/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DetectorResultValue = {
  timestamp?: string;
  type?: DetectorResultValue.type;
  value?: number;
};
export namespace DetectorResultValue {
  export enum type {
    V = 'v',
    NUM_VEHICLES = 'num_vehicles',
  }
}
