import { SaveDialog } from '@/components/dialog/SaveDialog';
import { QueryKey } from '@/modules/QueryKey';
import { RouteFeature } from '@/types/RouteFeature';
import { isNonNullable } from '@/utils/isNonNullable';

type RouteSaveDialogProps = {
  route?: RouteFeature;
  onClose: () => void;
};

export function RouteSaveDialog({ route, onClose }: RouteSaveDialogProps) {
  return (
    <SaveDialog
      onClose={onClose}
      title="Strecke"
      mutationEndpoint="user_query_route"
      initialValues={route}
      queryKey={QueryKey.routes.all}
      additionalData={{
        length: route?.properties.length,
        routing_points:
          route?.properties.routeLocations.features.map(({ geometry }) => geometry).filter(isNonNullable) || [],
      }}
    />
  );
}
