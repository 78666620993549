import { ListItem, ListItemText, Typography } from '@mui/material';

import { StyledList } from '@/components/StyledList';

export function ParameterTooltip() {
  return (
    <>
      <Typography>
        Interaktion in der <strong>Kennwertauswahl</strong>
      </Typography>
      <StyledList>
        <ListItem>
          <ListItemText>Einen Kennwert aus der Liste auswählen</ListItemText>
        </ListItem>
      </StyledList>
    </>
  );
}
