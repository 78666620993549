import { css, styled } from '@mui/material';
import { ReactElement } from 'react';

import { SidebarItemContainer } from '@/components/sidebar/SidebarItemContainer';

const StyledMenuItemContainer = styled(SidebarItemContainer)(
  () => css`
    padding-left: 0;
  `,
);

type FormsWrapperProps = { children: ReactElement };

export function FormsWrapper({ children }: FormsWrapperProps) {
  return <StyledMenuItemContainer>{children}</StyledMenuItemContainer>;
}
