import { Layer, Source } from 'react-map-gl';

import { useHotspotsStore } from '@/stores/useHotspotsStore';
import { ROUTING_NET_LINE_OFFSET } from '@/map/components/fcd/FcdLayers';
import { ROUTE_COLOR } from '@/types/ROUTE_COLOR';

export const HOTSPOT_EVENTS_LAYER = 'HOTSPOT_EVENTS';

export function HotspotEventsLayer() {
  const hotspotEventFeature = useHotspotsStore((state) => state.hotspotEventFeature);

  return (
    hotspotEventFeature && (
      <Source
        type="geojson"
        id={HOTSPOT_EVENTS_LAYER}
        key={hotspotEventFeature.properties.id}
        data={hotspotEventFeature}
      >
        <Layer
          id={HOTSPOT_EVENTS_LAYER}
          type="line"
          paint={{
            'line-width': 8,
            'line-color': ROUTE_COLOR[1],
            'line-offset': ROUTING_NET_LINE_OFFSET,
          }}
          layout={{ 'line-cap': 'round', 'line-join': 'round' }}
        />
      </Source>
    )
  );
}
