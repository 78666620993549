import { memo, useEffect } from 'react';

import { useMapContext } from '@/stores/useMapContext';
import { FcdLayerType } from '@/types/FcdLayerType';
import { FeatureLayers } from '@/map/components/feature-layers/FeatureLayers';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { DetectorsLayer } from '@/detector-analysis/components/DetectorsLayer';
import { ShowDiagramButton } from '@/components/ShowDiagramButton';
import { useDetectorAnalysisStore } from '@/stores/useDetectorAnalysisStore';
import { DetectorDiagramParameters } from '@/types/DiagramParameters';
import { DetectorDrawControl } from '@/detector-analysis/components/DetectorDrawControl';
import { DetectorDrawBottomControl } from '@/detector-analysis/components/DetectorDrawBottomControl';

export const DetectorAnalysisMapContent = memo(() => {
  const { setLayers } = useMapContext((state) => state.actions);

  const showDiagram = useDetectorAnalysisStore((state) => state.showDiagram);
  const isDrawing = useDetectorAnalysisStore((state) => state.isDrawing);
  const { setShowDetectorDiagram } = useDetectorAnalysisStore((state) => state.actions);
  const diagramParameters = useDetectorAnalysisStore((state) => state.diagramParameters);

  useEffect(() => {
    setLayers({ fcd: FcdLayerType.NONE });
  }, [setLayers]);

  return (
    <>
      <FeatureLayers types={[FeatureLayerType.TRAFFIC_LIGHT]} />
      <DetectorDrawControl />
      <DetectorsLayer />
      <ShowDiagramButton
        visible={showDiagram}
        onShowDiagram={() => setShowDetectorDiagram(true)}
        diagramParameters={diagramParameters as DetectorDiagramParameters}
      />
      {isDrawing && <DetectorDrawBottomControl />}
    </>
  );
});
