import { Layer } from 'react-map-gl';
import { Expression, StyleFunction } from 'mapbox-gl';

import { ROUTING_NET_LINE_OFFSET } from '@/map/components/fcd/FcdLayers';

type LineHighlightLayersProps = {
  source: string;
  highlightId?: string | number;
  sourceLayer?: string;
  beforeId?: string;
  lineWidthBorder: number | Expression | StyleFunction;
  lineWidthWhite: number | Expression | StyleFunction;
};

export function LineHighlightLayers({
  source,
  highlightId,
  sourceLayer,
  beforeId,
  lineWidthBorder,
  lineWidthWhite,
}: LineHighlightLayersProps) {
  if (!highlightId) {
    return null;
  }

  const borderId = `${source}_HIGHLIGHT_${highlightId}_BORDER`;
  const whiteId = `${source}_HIGHLIGHT_${highlightId}_WHITE`;

  return (
    <>
      <Layer
        key={borderId}
        id={borderId}
        type="line"
        source={source}
        {...(sourceLayer && { 'source-layer': sourceLayer })}
        beforeId={beforeId || source}
        filter={['==', ['get', 'id'], highlightId]}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': '#3a3a3a',
          'line-width': lineWidthBorder,
          'line-offset': ROUTING_NET_LINE_OFFSET,
        }}
      />
      <Layer
        key={whiteId}
        id={whiteId}
        type="line"
        source={source}
        {...(sourceLayer && { 'source-layer': sourceLayer })}
        beforeId={beforeId || source}
        filter={['==', ['get', 'id'], highlightId]}
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
        }}
        paint={{
          'line-color': 'white',
          'line-width': lineWidthWhite,
          'line-offset': ROUTING_NET_LINE_OFFSET,
        }}
      />
    </>
  );
}
