/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DetectorAnalysisRequest } from '../models/DetectorAnalysisRequest';
import type { DetectorAnalysisResponse } from '../models/DetectorAnalysisResponse';
import type { DetectorHistoryResponse } from '../models/DetectorHistoryResponse';
import type { DetectorLiveResponse } from '../models/DetectorLiveResponse';
import type { FcdAnalysisRequest } from '../models/FcdAnalysisRequest';
import type { FcdAnalysisResponse } from '../models/FcdAnalysisResponse';
import type { FcdHistoryResponse } from '../models/FcdHistoryResponse';
import type { FcdLiveResponse } from '../models/FcdLiveResponse';
import type { GenericHistoryRequest } from '../models/GenericHistoryRequest';
import type { HolidayRequest } from '../models/HolidayRequest';
import type { HolidayResponse } from '../models/HolidayResponse';
import type { RoutingRequest } from '../models/RoutingRequest';
import type { RoutingResponseGeojson } from '../models/RoutingResponseGeojson';
import type { RoutingResponseIds } from '../models/RoutingResponseIds';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TempusService {
  /**
   * calculates a route from an array of coordinates and returns the ids
   * This endpoint calculates a route from an array of coordinates. It can be used to calculate a route from a start to an end point, including points in between. The Response contains the hash_ids of the route segments.
   * @returns RoutingResponseIds OK
   * @throws ApiError
   */
  public static calculateRouteIds({
    requestBody,
  }: {
    requestBody: RoutingRequest;
  }): CancelablePromise<RoutingResponseIds> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/routing/ids',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * calculates a route from an array of coordinates and returns a GeoJSON FeatureCollection
   * This endpoint calculates a route from an array of coordinates. It can be used to calculate a route from a start to an end point, including points in between. The Response contains a GeoJSON FeatureCollection.
   * @returns RoutingResponseGeojson OK
   * @throws ApiError
   */
  public static calculateRouteGeojson({
    requestBody,
  }: {
    requestBody: RoutingRequest;
  }): CancelablePromise<RoutingResponseGeojson> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/routing/geojson',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * returns holidays, filtered by start_date and end_date
   * This endpoint returns the holidays that have been imported using the OpenHolidays API.
   * @returns HolidayResponse OK
   * @throws ApiError
   */
  public static getHolidays({ requestBody }: { requestBody: HolidayRequest }): CancelablePromise<HolidayResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/holidays',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * endpoint for historic fcd data
   * This endpoint provides a trimmed-down request/response scheme with limited functionality. This has been mainly done to conserve bandwith and speed up query performance. It always computes all segments and can be used for the historic monitoring of fcd data.
   * @returns FcdHistoryResponse OK
   * @throws ApiError
   */
  public static getFcdHistory({
    requestBody,
  }: {
    requestBody: GenericHistoryRequest;
  }): CancelablePromise<FcdHistoryResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/fcd/history',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * endpoint for fcd analysis across multiple segments
   * This fully-fledged endpoint can be used for all aspects of fcd analysis.
   * @returns FcdAnalysisResponse OK
   * @throws ApiError
   */
  public static getFcd({ requestBody }: { requestBody: FcdAnalysisRequest }): CancelablePromise<FcdAnalysisResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/fcd/analysis',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * endpoint for historic detector data
   * This endpoint provides historic detector data
   * @returns DetectorHistoryResponse OK
   * @throws ApiError
   */
  public static getDetectorHistory({
    requestBody,
  }: {
    requestBody: GenericHistoryRequest;
  }): CancelablePromise<DetectorHistoryResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/detector/history',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * endpoint for detector analysis
   * This fully-fledged endpoint can be used for all aspects of detector analysis.
   * @returns DetectorAnalysisResponse OK
   * @throws ApiError
   */
  public static getDetectorAnalysis({
    requestBody,
  }: {
    requestBody: DetectorAnalysisRequest;
  }): CancelablePromise<DetectorAnalysisResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/detector/analysis',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * test ping endpoint
   * This endpoint is used for testing purposes.
   * @returns string OK
   * @throws ApiError
   */
  public static ping(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/ping',
    });
  }
  /**
   * returns the current live fcd data
   * This endpoint provides the current FCD Live Data, including some calculated values like speeding, slowdown and LOS.
   * @returns FcdLiveResponse OK
   * @throws ApiError
   */
  public static getFcdLive(): CancelablePromise<FcdLiveResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/fcd/live',
    });
  }
  /**
   * returns the current/latest live detector data
   * This endpoint provides the current detector data
   * @returns DetectorLiveResponse OK
   * @throws ApiError
   */
  public static getDetectorLive(): CancelablePromise<DetectorLiveResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/detector/live',
    });
  }
}
