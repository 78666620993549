import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';

import { DetectorDiagramParameters, FcdDiagramParameters } from '@/types/DiagramParameters';
import { AnalysisFormStep } from '@/types/AnalysisFormStep';
import { ValidationStatus } from '@/types/ValidationStatus';

export const useAnalysisStatus = ({
  analysisParameters,
  diagramParameters,
  validation,
}: {
  analysisParameters?: Partial<FcdDiagramParameters> | Partial<DetectorDiagramParameters>;
  diagramParameters?: FcdDiagramParameters | DetectorDiagramParameters;
  validation: Record<AnalysisFormStep, { status: ValidationStatus; count: string | number }>;
}) => {
  const analysisPossible =
    validation[AnalysisFormStep.LOCATION].status === 'success' &&
    validation[AnalysisFormStep.DATE_TIME].status === 'success' &&
    validation[AnalysisFormStep.PARAMETER].status === 'success';

  const diagramUpToDate = useMemo(
    () => !!diagramParameters && !!analysisParameters && isEqual(diagramParameters, analysisParameters),
    [diagramParameters, analysisParameters],
  );

  let tooltipTitle: string;
  let buttonColor: 'primary' | 'success' | 'disabled';

  if (diagramUpToDate) {
    tooltipTitle = 'Analyse ist bereits aktuell';
    buttonColor = 'success';
  } else if (analysisPossible) {
    tooltipTitle = 'Analyse starten';
    buttonColor = 'primary';
  } else {
    tooltipTitle =
      'Eine Analyse kann erst gestartet werden, wenn mindestens eine Strecke, Zeitraum und Kennwert gewählt sind';
    buttonColor = 'disabled';
  }

  return { tooltipTitle, buttonColor, analysisPossible };
};
