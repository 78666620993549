import { Box, styled, css, FormGroup, RadioGroup, IconButton, Paper } from '@mui/material';
import { Route } from 'react-router-dom';

import { BackgroundLayerType } from '@/types/BackgroundLayerType';
import { LayerControlRadio } from '@/monitoring/components/LayerControlRadio';
import LayersIcon from '@/map/assets/layers.svg?react';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { useMapContext } from '@/stores/useMapContext';
import { SentryRoutes } from '@/setup/sentry';
import { FcdLayerTypeControl } from '@/monitoring/components/FcdLayerTypeControl';
import { FeatureLayerTypeControl } from '@/monitoring/components/FeatureLayerTypeControl';

const Root = styled(Box)(
  ({ theme }) => css`
    z-index: 10;
    position: absolute;
    top: ${theme.spacing(1)};
    right: ${theme.spacing(1)};

    &:hover {
      .layers-control-button {
        display: none;
      }

      .layers-control-paper {
        display: block;
      }
    }
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    background-color: ${theme.palette.common.white};
    border-bottom: 2px solid ${theme.palette.divider};
    border-end-start-radius: 0;
    border-end-end-radius: 0;
  `,
);

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    display: none;
    padding: ${theme.spacing(2)};
  `,
);

const Divider = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    height: 2px;
    background-color: ${theme.palette.divider};
    margin: ${theme.spacing(2)} 0;
  `,
);

export function LayersControl() {
  const backgroundLayer = useMapContext((state) => state.backgroundLayer);
  const { setBackgroundLayer } = useMapContext((state) => state.actions);

  return (
    <Root>
      <StyledIconButton className="layers-control-button">
        <LayersIcon />
      </StyledIconButton>

      <StyledPaper className="layers-control-paper">
        <FormGroup>
          <RadioGroup>
            {Object.values(BackgroundLayerType).map((layer) => (
              <LayerControlRadio
                key={layer}
                value={layer}
                checked={layer === backgroundLayer}
                onChange={(checked) => {
                  if (checked) {
                    setBackgroundLayer(layer);
                  }
                }}
                i18nKey="BackgroundLayerType"
              />
            ))}
          </RadioGroup>
        </FormGroup>

        <Divider />

        <SentryRoutes>
          <Route path="monitoring" element={<FcdLayerTypeControl />} />
        </SentryRoutes>

        <SentryRoutes>
          <Route path="monitoring" element={<FeatureLayerTypeControl types={Object.values(FeatureLayerType)} />} />
          <Route
            path="fcd-verkehrsanalyse"
            element={<FeatureLayerTypeControl types={[FeatureLayerType.TRAFFIC_LIGHT]} />}
          />
          <Route path="hotspots" element={<FeatureLayerTypeControl types={[FeatureLayerType.TRAFFIC_LIGHT]} />} />
          <Route
            path="detektoranalyse"
            element={<FeatureLayerTypeControl types={[FeatureLayerType.TRAFFIC_LIGHT]} />}
          />
        </SentryRoutes>
      </StyledPaper>
    </Root>
  );
}
