import { IconButton, css, styled } from '@mui/material';

import { transientOptions } from '@/utils/transient-options';

export const StyledIconButton = styled(
  IconButton,
  transientOptions,
)<{ $color: 'primary' | 'success' | 'disabled' }>(
  ({ theme, $color }) => css`
    background-color: ${$color === 'disabled' ? '#ffdc80' : theme.palette[$color].main};
    padding: ${theme.spacing(1)} ${theme.spacing(3)};
  `,
);
