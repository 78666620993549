import { createStore } from 'zustand';

import { BackgroundLayerType } from '@/types/BackgroundLayerType';
import { FeatureLayerType } from '@/types/FeatureLayerType';
import { FcdLayerType } from '@/types/FcdLayerType';
import { MapContextState } from '@/types/MapContextState';
import { MapSide } from '@/types/MapSide';

export const createMapStore = ({ side }: { side: MapSide }) =>
  createStore<MapContextState>()((set) => ({
    styleLoaded: false,

    side,

    backgroundLayer: BackgroundLayerType.LIGHT_MAP,

    layers: {
      fcd: FcdLayerType.LEVEL_OF_SERVICE,
      features: {
        [FeatureLayerType.STRATEGY]: false,
        [FeatureLayerType.OBSTRUCTION]: false,
        [FeatureLayerType.MUNICH_CONSTRUCTIONS]: false,
        [FeatureLayerType.TRAFFIC_LIGHT]: false,
      },
    },

    featureSelectMenu: undefined,

    fcdDetailCardFeature: undefined,
    detectorDetailCardFeature: undefined,

    trafficLightPopupFeature: undefined,

    routeLengthPopupFeature: undefined,

    routePopupPoint: undefined,

    detectorPopupFeature: undefined,
    detectorClusterPopupFeature: undefined,

    actions: {
      setStyleLoaded: (styleLoaded) => set({ styleLoaded }),

      setBackgroundLayer: (backgroundLayer) =>
        set((state) => ({
          backgroundLayer,
          styleLoaded: backgroundLayer === state.backgroundLayer,
        })),

      setLayers: (newMapLayers) =>
        set(({ layers }) => ({
          layers: {
            ...layers,
            ...newMapLayers,
            features: {
              ...layers.features,
              ...newMapLayers.features,
            },
          },
        })),

      setFeatureSelectMenu: (featureSelectMenu) => set({ featureSelectMenu }),

      setFcdDetailCardFeature: (fcdDetailCardFeature) => set({ fcdDetailCardFeature }),

      setDetectorDetailCardFeature: (detectorDetailCardFeature) => set({ detectorDetailCardFeature }),

      setTrafficLightPopupFeature: (trafficLightPopupFeature) => set({ trafficLightPopupFeature }),

      setRouteLengthPopupFeature: (routeLengthPopupFeature) => set({ routeLengthPopupFeature }),

      setRoutePopupPoint: (routePopupPoint) => set({ routePopupPoint }),
      setDetectorPopupFeature: (detectorPopupFeature) => set({ detectorPopupFeature }),
      setDetectorClusterPopup: (detectorClusterPopupFeature) => set({ detectorClusterPopupFeature }),
    },
  }));
